import React, { useContext, useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import Loader from './Loader';
import './Collection.css';
import BaseComponent from '../../core/BaseComponent';
import { Navigate } from "react-router-dom";
import { withNavigation, withI18n, withAppGlobal, withBordes, withWeb3React, withUseParams, withUseLocationChanged } from '../../core/BaseComponent';
import { FiChevronRight } from 'react-icons/fi';
import PageService from '../../core/PageService';
import AppGlobal from '../../context/AppGlobal';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import AppSettings from '../../AppSettings';
import { useNavigate } from 'react-router-dom';

import qrInter from '../../assets/imgs/qr-inter.png';
import eth from '../../assets/imgs/eth.png';
import avatar from '../../assets/imgs/avatar.png';
import person from '../../assets/imgs/person.jpeg';
import desma from '../../assets/imgs/desma.png';
import qrBorder from '../../assets/imgs/qr-border.png';
import NftItem from '../nft-item/NftItem';
import intervention from '../../assets/imgs/intervention.png';
import { HashLink as Link } from 'react-router-hash-link';

import { IoMdAdd } from 'react-icons/io';

 
const Collection = () => {
	const { global, addGlobal } = useContext(AppGlobal);
    const pageService = new PageService({
        appGlobal:{
            global: global,
            addGlobal: addGlobal
        }
    });
    const { t } = useTranslation();
    const { code } = useParams();
    const [ collection, setCollection ] = useState();
    const [ loading, setLoading ] = useState(false);
    const { settings } = AppSettings();
    const navigate = useNavigate();



    useEffect(() => {
        pageService.showLoading();
        pageService.httpGet({
            endPoint: settings.api.endPoints.collectionsByCode,
            queryParams: { code } 
        }).then( result => {
            setCollection(result.data);
            console.log(result.data)
        })
        .catch( error => {
            pageService.showError(error);
        })
        .finally(() => pageService.hideLoading());


    }, [code]);


    // constructor(props) {
    //     super(props);
    //     this.goToNft = this.goToNft.bind(this);

    // }

    const goToNftShop = (nft) => {
        navigate(`/nft-shop/${nft.id}` );
    }

    const goToWantToKnow = () => {
        navigate(`#intervention` );
    }

    const goToNft = (nft) => {
        let id = 'new';
        if(nft) id = nft.id;

        let nftType = 'nft-double';
        if(collection.type == settings.collectionTypes.doubleIntervention) nftType = 'nft-double-intervention';

        navigate(`/${nftType}/${collection.id}/${id}` );
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    const componentDidMount = () => {


        // let { code } = this.props.params;
        // this.loadItem(
        //     this.settings.api.endPoints.collectionsByCode,
        //     {
        //         code: code
        //     }            
        // );
    }

    const componentWillUnmount = () => {
        //this.unlisten();
    }

    const loadItem = (endPoint, queryParams = {}) => {
        this.pageService.showLoading();
        this.pageService.httpGet({
            endPoint: endPoint,
            queryParams: queryParams
        }).then( result => {
            this.setState({
                collection: result.data
            });
        })
        .catch( error => {
            this.pageService.showError(error);
        })
        .finally(() => this.pageService.hideLoading());
    }


        return (
            <>

            {/* LOADER */}
            {loading &&
                <Loader />
            }
            {/* LOADER */}
            
            {!loading && 
                <div>
                    
                    {collection?.code != settings.collections.codes.interventions && 
                        <Container>
                            <Row className='mt-5'>
                                <h1 className='text-danger title'>{collection?.name}</h1>
                                <p className='text-danger text'>{collection?.['description_es']}</p>
                            </Row>
                        </Container>

                    }

                    {collection?.code == settings.collections.codes.interventions && 
                        <>
                            <Container>
                                <Row className='mt-5'>
                                    <h1 className='title'>{collection?.name}</h1>
                                </Row>
                            </Container>
                            
                            <div class='toolbar-inter mt-4'>
                                <Container className='p-4'>
                                    <Row className='align-items-center'>
                                        <Col xs={12} md={8}>
                                            <h3 class='title text-light'>{t('collection.labels.interventions.proposal')}</h3>
                                            <p className='text text-light'>{t('collection.labels.interventions.proposalExplained')}</p>

                                            <p className='subtitle link mt-5 text-light' onClick={() => goToNft()}>{t('collection.buttons.interventions.intervene')} <FiChevronRight /></p>
                                            <Link to='#intervention' className='nav-link-no-decoration' smooth><p className='subtitle link mt-2 text-light'>{t('collection.buttons.interventions.wantToKnow')} <FiChevronRight /></p></Link>
                                            
                                        </Col>
                                        <Col xs={12} md={4}>
                                            <img src={intervention} width='100%' height='300px' alt="" />
                                        </Col>
                                    </Row>
                                </Container>
                            </div>

                        </>
                    }

                    <Container className='pb-5'>

                        {collection?.code == settings.collections.codes.interventions && 
                            <Row className='justify-content-center mt-4'>
                                <Col xs={12} md={2}></Col>
                                <Col xs={12} md={8}>
                                    <p className='text text-center'>{collection?.['description_es']}</p>
                                </Col>
                                <Col xs={12} md={2}></Col>
                            </Row>
                        }
                        <Row className="justify-content-center align-items-center mt-5">

                            {collection?.nfts.map((nft,idx,nfts) => (
                                    <Col xs={12} md={4}>
                                        <NftItem nft={nft} onPress={() => goToNftShop(nft)}></NftItem>
                                    </Col>
                            ))}      
                            {collection?.nfts.length % 3 > 1 &&
                                <Col xs={12} md={4}>
                                </Col>
                            }
                            {collection?.nfts.length % 3 > 0 &&
                                <>
                                <Col xs={12} md={4}>
                                </Col>
                                <Col xs={12} md={4}>
                                </Col>
                                </>
                            }

                        </Row>



                        {collection?.code == settings.collections.codes.interventions && 
                        <Row className='mt-4 align-items-center'>
                            <Col xs={12} md={4}>
                            </Col>
                            <Col xs={12} md={4}>
                                <Button variant='outline-danger' className='w-100 mt-2' type='submit' onClick={() => goToNft()}>
                                    {t('collection.buttons.interventions.createIntervention')}
                                    
                                </Button>
                            </Col>
                            <Col xs={12} md={4}>
                            </Col>
                        </Row>
                        }

                    </Container>
                </div>
            }

            </>

        );




}

export default Collection;
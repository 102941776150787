import { NavLink } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './TopMenu.css';
import { Navbar, Nav, Badge, Form, Container, Row } from 'react-bootstrap';
import { IoWalletSharp } from 'react-icons/io5';
import { HashLink as Link } from 'react-router-hash-link';
import { useTranslation, Trans } from 'react-i18next';
import { useCallback } from 'react';
import { connector } from "../../config/web3";

import useBordes from '../../hooks/useBordes';
// import useWallet from '../../hooks/useWallet';
import { useWeb3React } from '@web3-react/core';
import { useEffect, useContext, useState } from 'react';
import { UnsupportedChainIdError } from '@web3-react/core';
import AppSettings from '../../AppSettings';
import AppGlobal from '../../context/AppGlobal';
import useServices from '../../hooks/useServices';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ethers } from "ethers";
import { useSelector, useDispatch } from 'react-redux'
import { setUser, removeUser } from '../../features/user/userSlice';
import { setLanguage } from '../../features/language/languageSlice';


//  <Badge bg="danger" text='danger' className='notif'>.</Badge>
// import { useCallback } from "react";


// import { useWeb3React } from '@web3-react/core';
// import { connector } from "../../config/web3";


// + IMAGES +
import logo from '../../assets/imgs/logo.svg';
// - IMAGES -

const lngs = {
    en: { nativeName: 'English' },
    es: { nativeName: 'Español' },
};


const TopMenu = () => {
    const [navOpen, setNavOpen] = useState(false);

    // const user = useSelector((state) => state.user.value)
    const dispatch = useDispatch()

    function toggleNav() {
        setNavOpen((state) => !state);
    }

    const { i18n } = useTranslation();
    const { t } = useTranslation();
    const { settings } = AppSettings();
    const { httpPost } = useServices();

    const { global, addGlobal, removeGlobal, addGlobals } = useContext(AppGlobal);

    const bordes = useBordes();
    // const { connected, connect, disconnect } = useWallet();

    const changeLng = (event) => {
        i18n.changeLanguage(event.target.value);
        addGlobal(settings.storage.language, event.target.value);
        dispatch(setLanguage(event.target.value));
    };

    const toggleConnection = useCallback(() => {
        if (localStorage.getItem("previouslyConnected") === "true") disconnect();
        else connect();
    }, []);



    ////////////////////////////

    const { active, activate, deactivate, account, error, library } = useWeb3React();

    const isUnsupportedChain = error instanceof UnsupportedChainIdError;

    const connect = useCallback(() => {
        activate(connector);
        localStorage.setItem("previouslyConnected", "true");
    }, [activate]);

    const disconnect = () => {
        deactivate();
        localStorage.removeItem("previouslyConnected");
    };

    useEffect(() => {
        if (localStorage.getItem("previouslyConnected") === "true") connect();
    }, [connect]);

    // useEffect(() => {
    //     addGlobal(settings.storage.language,i18n.language.split('-')[0]);
    // }, [i18n]);

    useEffect(() => {
        console.log(account, active);

        if (account) {
            httpPost(
                { url: settings.api.endPoints.usersLoginOrCreate },
                {
                    address: account,
                    username: account,
                    password: account,
                }
            )
                .then(result => {
                    result.address = account;
                    // addGlobal(settings.storage.user,{address:`${account?.substr(0, 6)}...${account?.substr(-4)}`});
                    //addGlobal(settings.storage.user,result);
                    let params = {};
                    params[settings.storage.user] = result;
                    params[settings.storage.language] = result.language;
                    addGlobals(params);
                    console.log(params);
                    // changeLng({target:{value:result.language}})

                    dispatch(setUser(result));

                })
                .catch(error => {
                    removeGlobal(settings.storage.user);
                    dispatch(removeUser());
                    toast(error.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                })

        } else {
            removeGlobal(settings.storage.user);
            dispatch(removeUser());
        }

    }, [account, active]);


    return (
        <Navbar collapseOnSelect expand='lg' className='navbar'>
            <ToastContainer />
            <Container fluid>
                <Nav>
                    <Nav.Link eventKey="0">
                        <NavLink className='nav-link logo-cont' to='/'>
                            <div className='d-flex align-items-center'>
                                <img src={logo} className='logo' alt="" />
                                BORDES
                                <Badge bg="danger" className='badge-test'>En Test</Badge>
                            </div>
                        </NavLink>
                    </Nav.Link>
                </Nav>
                <Navbar.Toggle aria-controls='responsive-navbar-nav' />
                <Navbar.Collapse className="justify-content-end">
                    <Nav>
                        {/* <Nav.Link eventKey="1">
                        <NavLink className='nav-link' to='/test'>TEST</NavLink>
                    </Nav.Link> */}
                        {/* <Nav.Link eventKey="2">
                        <NavLink className='nav-link' to='/project'>PROYECTO</NavLink>
                    </Nav.Link> */}
                        <Nav.Link eventKey="2">
                            <Link className='nav-link text-uppercase' to='form-contact' smooth>{t('topmenu.labels.join')}</Link>
                        </Nav.Link>
                        <Nav.Link eventKey="3">
                            <Link className='nav-link' to='#intervention' smooth>{t('topmenu.labels.intervene')}</Link>
                        </Nav.Link>
                        <Nav.Link eventKey="4">
                            <NavLink className='nav-link' to='/collection/dematerialization'>{t('topmenu.labels.dematerialization')}</NavLink>
                        </Nav.Link>
                        <Nav.Link eventKey="5">
                            <NavLink className='nav-link' to='/collection/interventions'>{t('topmenu.labels.intervention')}</NavLink>
                        </Nav.Link>
                        {global.user ? (
                            <>
                                <Nav.Link eventKey="6">
                                    <NavLink className='nav-link' to='/user'>{t('topmenu.labels.profile')}</NavLink>
                                </Nav.Link>
                            </>
                        ) : (
                            <>
                            </>
                        )}
                        <Nav.Link eventKey="7">
                            <div className='custom-ico-wallet'>
                                <p className='nav-link text-uppercase mb-0 custom-txt'></p>
                                <IoWalletSharp className='icon-size' onClick={toggleConnection} />
                            </div>
                        </Nav.Link>
                        <Nav.Link className='mt-1'>
                            <Form.Select size="sm" className='lang-select' onChange={changeLng} value={i18n.language.split('-')[0]} >
                                {Object.keys(lngs).map((lng) => (
                                    <>
                                        <option value={lng}>
                                            {lngs[lng].nativeName}
                                        </option>
                                    </>
                                ))}
                            </Form.Select>
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>

    );
}

export default TopMenu;
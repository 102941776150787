import { useEffect, useState, useContext } from "react";
import axios from 'axios';
import AppSettings from "../../AppSettings";
import AppGlobal from '../../context/AppGlobal';

const useServices = () => {
  const { settings } = AppSettings();
  const [items, setItems] = useState([]);
  const [item, setItem] = useState({});
	const { global } = useContext(AppGlobal);

  const useHttpGet = (params = {}) => {

    useEffect(() => {
      const fetchData = async () => {
        const result = await axios(settings.api.url + params.url);
        setItems(result.data);
      }
      fetchData();
    },[]);

    return items;
  }
  
  // const httpPost = async(params = {}, values = {}) => {
  //   const result = await axios.post(settings.api.url + params.url, values);
  //   console.log(result);
  //   // if(result != error)
  //   return result;
  // }

  const getHeaders = () => {
    let headers = {
      'Content-Type': 'application/json'
    };

    console.log(global.user);

    if(global.user) headers['x-access-token'] = global.user.token;

    return headers;
  }

  const httpPost = (params = {}, values = {}) => {
    console.log(getHeaders());
    return new Promise((resolve, reject) => {
      axios.post(
        settings.api.url + params.url, 
        values,
        getHeaders()
        )
      .then( result => {
        resolve( result.data.data );
      })
      .catch( error => {
        console.log(error);
        let message = error.message;
        if(error.response && error.response.data && error.response.data.message)
          message = error.response.data.message;
        reject( { message , error } );
      });
    });
  }

  return {
    useHttpGet,
    httpPost
  };

};

export default useServices;

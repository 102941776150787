import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col } from 'react-bootstrap';
import './Interventions.css';
import Loader from './Loader';
import { FiChevronRight } from 'react-icons/fi';

// + IMAGES +
import qrInter from '../../assets/imgs/qr-inter.png';
import eth from '../../assets/imgs/eth.png';
import avatar from '../../assets/imgs/avatar.png';
import person from '../../assets/imgs/person.jpeg';
import intervention from '../../assets/imgs/intervention.png';
// - IMAGES -
import AppGlobal from '../../context/AppGlobal';
import { useContext } from 'react';

const Interventions = () => {
    const [item, setItem] = useState(false);
	const { global } = useContext(AppGlobal);

    setTimeout(() => {
        setItem(true);
    }, 2500);

    return (
        <>
            {/* LOADER */}
            {!item &&
                <Loader />
            }
            {/* LOADER */}

            {item &&
                <div>
                    <Container>
                        <Row className='mt-5'>
                            <h1 className='title'>INTERVENCION</h1>
                        </Row>
                    </Container>

                    <div class='toolbar-inter mt-4'>
                        <Container>
                            <Row className='align-items-center'>
                                <Col xs={12} md={8}>
                                    <h3 class='title text-light'>PROPONEMOS UNA EXPERIENCIA</h3>
                                    <p className='text text-light'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Pariatur hic id officiis suscipit beatae magni consequatur
                                        exercitationem nostrum natus. Atque error illum suscipit commodi. Ipsam earum laudantium sapiente nisi et.</p>

                                    <p className='subtitle link mt-5 text-light'>INTERVEN EL TUYO <FiChevronRight /></p>
                                    <p className='subtitle link mt-2 text-light'>QUIERO SABER SOBRE EL PROCESO DE CREACION <FiChevronRight /></p>
                                </Col>
                                <Col xs={12} md={4}>
                                    <img src={intervention} widt='100%' alt="" />
                                </Col>
                            </Row>
                        </Container>
                    </div>

                    <Container className='pb-5'>
                        <Row className='justify-content-center mt-4'>
                            <Col xs={12} md={2}></Col>
                            <Col xs={12} md={8}>
                                <p className='text text-center'>La imagen “por momentos” nos introduce en el medio indeterminado de la fascinación, “por momentos nos brinda el poder de disponer de las cosas en su ausencia y gracias a la ficción,
                                    “por momentos” nos empuja a ese lugar donde las cosas están tal vez presentes.</p>
                            </Col>
                            <Col xs={12} md={2}></Col>
                        </Row>

                        <Row className="justify-content-center align-items-center mt-5">
                            <Col xs={12} md={4}>
                                <img src={qrInter} className='d-block w-100 shop-img' alt="" />
                                <Row className='mt-3'>
                                    <Col xs={6} md={6}>
                                        <p className='text-left m-1 subtitle'>PROPIETARIO</p>
                                        <p className='m-1 bordes'>BORDES</p>
                                        <p className='m-1 price-shop'>Owner</p>
                                        <p className='m-1 price-shop'>Precio</p>
                                    </Col>
                                    <Col xs={6} md={6} className='align-right'>
                                        <p className='text-right'>
                                            <img src={person} className='avatar' alt="" />
                                            <span className='name'>CECILIA</span>
                                        </p>

                                        <p className='text-right mt-4 mb-1'>
                                            <img src={person} className='avatar avatar-inter' alt="" />
                                            <img src={avatar} className='avatar avatar-inter' alt="" />
                                        </p>

                                        <p className='text-right mt-0 mb-1'>
                                            <img src={eth} width='10px' alt="" />
                                            <span className='price-eth'>0.009</span>
                                        </p>

                                        <p className='m-0 price-shop'>$2.35</p>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={12} md={2}></Col>
                            <Col xs={12} md={4}>
                                <img src={qrInter} className='d-block w-100 shop-img' alt="" />
                                <Row className='mt-3'>
                                    <Col xs={6} md={6}>
                                        <p className='text-left m-1 subtitle'>PROPIETARIO</p>
                                        <p className='m-1 bordes'>BORDES</p>
                                        <p className='m-1 price-shop'>Owner</p>
                                        <p className='m-1 price-shop'>Precio</p>
                                    </Col>
                                    <Col xs={6} md={6} className='align-right'>
                                        <p className='text-right'>
                                            <img src={person} className='avatar' alt="" />
                                            <span className='name'>CECILIA</span>
                                        </p>

                                        <p className='text-right mt-4 mb-1'>
                                            <img src={person} className='avatar avatar-inter' alt="" />
                                            <img src={avatar} className='avatar avatar-inter' alt="" />
                                        </p>

                                        <p className='text-right mt-0 mb-1'>
                                            <img src={eth} width='10px' alt="" />
                                            <span className='price-eth'>0.009</span>
                                        </p>

                                        <p className='m-0 price-shop'>$2.35</p>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                        <Row className="justify-content-center align-items-center mt-5">
                            <Col xs={12} md={4}>
                                <img src={qrInter} className='d-block w-100 shop-img' alt="" />
                                <Row className='mt-3'>
                                    <Col xs={6} md={6}>
                                        <p className='text-left m-1 subtitle'>PROPIETARIO</p>
                                        <p className='m-1 bordes'>BORDES</p>
                                        <p className='m-1 price-shop'>Owner</p>
                                        <p className='m-1 price-shop'>Precio</p>
                                    </Col>
                                    <Col xs={6} md={6} className='align-right'>
                                        <p className='text-right'>
                                            <img src={person} className='avatar' alt="" />
                                            <span className='name'>CECILIA</span>
                                        </p>

                                        <p className='text-right mt-4 mb-1'>
                                            <img src={person} className='avatar avatar-inter' alt="" />
                                            <img src={avatar} className='avatar avatar-inter' alt="" />
                                        </p>

                                        <p className='text-right mt-0 mb-1'>
                                            <img src={eth} width='10px' alt="" />
                                            <span className='price-eth'>0.009</span>
                                        </p>

                                        <p className='m-0 price-shop'>$2.35</p>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={12} md={2}></Col>
                            <Col xs={12} md={4}>
                                <img src={qrInter} className='d-block w-100 shop-img' alt="" />
                                <Row className='mt-3'>
                                    <Col xs={6} md={6}>
                                        <p className='text-left m-1 subtitle'>PROPIETARIO</p>
                                        <p className='m-1 bordes'>BORDES</p>
                                        <p className='m-1 price-shop'>Owner</p>
                                        <p className='m-1 price-shop'>Precio</p>
                                    </Col>
                                    <Col xs={6} md={6} className='align-right'>
                                        <p className='text-right'>
                                            <img src={person} className='avatar' alt="" />
                                            <span className='name'>CECILIA</span>
                                        </p>

                                        <p className='text-right mt-4 mb-1'>
                                            <img src={person} className='avatar avatar-inter' alt="" />
                                            <img src={avatar} className='avatar avatar-inter' alt="" />
                                        </p>

                                        <p className='text-right mt-0 mb-1'>
                                            <img src={eth} width='10px' alt="" />
                                            <span className='price-eth'>0.009</span>
                                        </p>

                                        <p className='m-0 price-shop'>$2.35</p>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </div>
            }
        </>

    );
}

export default Interventions;
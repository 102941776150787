import React, { useEffect } from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { useTranslation, Trans } from 'react-i18next';
// import './About.css';

// + IMAGES +
import eth from '../../assets/imgs/eth.png';
import person from '../../assets/imgs/person.jpeg';
import desma from '../../assets/imgs/desma.png';
// - IMAGES -

// - ICONS -
import { BsDot } from "react-icons/bs"

import { HashLink as Link } from 'react-router-hash-link';

// import { IoLocationOutline } from "react-icons/io5";
const AboutBordes = () => {

    const { t } = useTranslation();

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <Container className='pb-5 ps-4 pe-4'>

            <div className='w-75 m-auto'>
                <Row className='mt-5'>
                    <h1 className='title text-uppercase'>{t('about_bordes.labels.title')}</h1>
                </Row>

                <Row className="justify-content-center align-items-center mt-2">
                    <p className='simple-page-text simple-page-text'>{t('about_bordes.labels.description1')}</p>
                </Row>

                <Row className="justify-content-center align-items-center mt-2">
                    <p className='simple-page-text simple-page-text'>{t('about_bordes.labels.description2')}</p>
                </Row>

                <Row className="justify-content-center align-items-center mt-2">
                    <Col xs={12} md={6} className='mt-4'>
                        <Link to="/files/bordes-dossier.pdf" className='nav-link-no-decoration' smooth download target="_blank">
                            <Button variant='danger'>{t('about_bordes.buttons.download')}</Button>
                        </Link>
                    </Col>
                    <Col xs={12} md={6} className='mt-4'>
                        
                    </Col>                    
                </Row>

            </div>
        </Container>
    );
}

export default AboutBordes;
import 'bootstrap/dist/css/bootstrap.min.css';
import './Footer.css';
import { Col, Container, Row, Button } from 'react-bootstrap';
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper";
import { ImLinkedin, ImFacebook2, ImTwitter, ImYoutube, ImWhatsapp } from 'react-icons/im';
import { FaInstagram, FaTiktok } from 'react-icons/fa';
import {FaDiscord} from 'react-icons/fa'

import PageService from '../../core/PageService';
import AppGlobal from '../../context/AppGlobal';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import AppSettings from '../../AppSettings';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// + IMAGES +
import placeholder from '../../assets/imgs/placeholder.png';
import cloud_upload from '../../assets/imgs/cloud_upload.svg';
import complete from '../../assets/imgs/complete.svg';
import format_color from '../../assets/imgs/format_color.svg';
import stars from '../../assets/imgs/stars.svg';
import support_ayende from '../../assets/imgs/support_ayende.png';
import support_nacinalArtes from '../../assets/imgs/support_nacinalArtes.png';
import support_rioceballos from '../../assets/imgs/support_rioceballos.png';
import support_unquillo from '../../assets/imgs/support_unquillo.png';
// + IMAGES +

const Footer = () => {

    const { global, addGlobal } = useContext(AppGlobal);
    const pageService = new PageService({
        appGlobal: {
            global: global,
            addGlobal: addGlobal
        }
    });
    const { t } = useTranslation();
    const { settings } = AppSettings();
    const navigate = useNavigate();



    const goToAbout = () => {
        navigate('/about');
    }

    const goToAboutBordes = () => {
        navigate('/about-bordes');
    }

    const goToFormContact = () => {
        navigate('/form-contact');
    }

    return (
        <div className='bg-footer p-4' id='intervention'>
            <Container>
                <Swiper
                    direction={"vertical"}
                    pagination={{
                        clickable: true,
                    }}
                    modules={[Pagination, Autoplay]}
                    navigation={true}
                    className="mySwiper mt-5"
                    autoplay={true}
                >
                    <SwiperSlide>
                        <Row>
                            <h4 className='text-light'>{t('footer.labels.stepsTitle1')}</h4>
                            <p className='text-light w-95 w-sm'>{t('footer.labels.stepsTitle1Explained')}</p>
                        </Row>
                    </SwiperSlide>
                    <SwiperSlide>
                        <Row>
                            <h4 className='text-light'>{t('footer.labels.stepsTitle2')}</h4>
                            <p className='text-light w-95 w-sm'>{t('footer.labels.stepsTitle2Explained')}</p>
                        </Row>
                    </SwiperSlide>
                </Swiper>

                <Row className='justify-content-center align-items-center mt-5'>
                    <Col xs={12} md={3} className='text-center'>
                        <img src={format_color} className='mb-3 steps' alt="" />
                        <p className='text-center text-light'>{t('footer.labels.step1')}</p>
                        <p className='text-center text-light'>{t('footer.labels.step1Explained')}</p>
                    </Col>
                    <Col xs={12} md={3} className='text-center'>
                        <img src={cloud_upload} className='mb-3 steps' alt="" />
                        <p className='text-center text-light'>{t('footer.labels.step2')}</p>
                        <p className='text-center text-light'>{t('footer.labels.step2Explained')}</p>
                    </Col>
                    <Col xs={12} md={3} className='text-center'>
                        <img src={complete} className='mb-3 steps' alt="" />
                        <p className='text-center text-light'>{t('footer.labels.step3')}</p>
                        <p className='text-center text-light'>{t('footer.labels.step3Explained')}</p>
                    </Col>
                    <Col xs={12} md={3} className='text-center'>
                        <img src={stars} className='mb-3 steps' alt="" />
                        <p className='text-center text-light'>{t('footer.labels.step4')}</p>
                        <p className='text-center text-light'>{t('footer.labels.step4Explained')}</p>
                    </Col>
                </Row>
                <hr />
                <Row className='mt-5'>
                    <Col xs={12} md={7}>
                        <h1 className='text-light'>{t('footer.labels.about')} BORDES.IO</h1>
                        <p className='text-light'>{t('footer.labels.aboutText')}</p>
                    </Col>
                    <Col xs={12} md={2}></Col>
                    <Col xs={12} md={3} className='mt-5'>
                        <Button variant='outline-light w-100' onClick={goToAbout}>{t('footer.buttons.readMore')}</Button>
                    </Col>
                </Row>
                <hr />
                <Row className='mt-5'>
                    <Col xs={12} md={7}>
                        <h1 className='text-light'>{t('footer.labels.born')}</h1>
                        <p className='text-light'>{t('footer.labels.bornText')}</p>
                    </Col>
                    <Col xs={12} md={2}></Col>
                    <Col xs={12} md={3} className='mt-5'>
                        <Button variant='outline-light w-100' onClick={goToAboutBordes}>{t('footer.buttons.readMore')}</Button>
                    </Col>
                </Row>
                <hr />
                <Row className='mt-5'>
                    <Col xs={12} md={7}>
                        <h1 className='text-light text-uppercase'>{t('footer.labels.info')}</h1>
                        <p className='text-light'>{t('footer.labels.infoText')}</p>
                    </Col>
                    <Col xs={12} md={2}></Col>
                    <Col xs={12} md={3} className='mt-5'>
                        <Button variant='outline-light w-100 text-uppercase' onClick={goToFormContact}>{t('footer.buttons.join')}</Button>
                    </Col>
                </Row>
                <hr />
                <hr />
                <Row className='mt-5'>
                    <Col xs={12} md={12}>
                        <h2 className='text-light text-center text-uppercase custom-title-support'>{t('footer.labels.support')}</h2>
                        <Swiper
                            // slidesPerView={2.5}
                            direction={"horizontal"}

                            breakpoints={{
                                "360": {
                                    slidesPerView: 1,
                                    spaceBetween: 10,
                                },
                                "@0.75": {
                                    slidesPerView: 2,
                                    spaceBetween: 20,
                                },
                                "@1.00": {
                                    slidesPerView: 3,
                                    spaceBetween: 40,
                                },
                                "@1.50": {
                                    slidesPerView: 4,
                                    spaceBetween: 50,
                                },
                            }}
                            modules={[Pagination, Autoplay]}
                            className="SwiperSupport mt-5"
                            autoplay={true}
                        >
                            <SwiperSlide>
                                <Row>
                                    <img className='custom-img' src={support_ayende} alt="" />
                                </Row>
                            </SwiperSlide>
                            <SwiperSlide>
                                <Row>
                                    <img className='custom-img' src={support_nacinalArtes} alt="" />
                                </Row>
                            </SwiperSlide>
                            <SwiperSlide>
                                <Row>
                                    <img className='custom-img' src={support_rioceballos} alt="" />
                                </Row>
                            </SwiperSlide>
                            <SwiperSlide>
                                <Row>
                                    <img className='custom-img' src={support_unquillo} alt="" />
                                </Row>
                            </SwiperSlide>
                        </Swiper></Col>
                </Row>
                <hr />
                <hr />
                <Row className='mt-5 justify-content-center'>
                    <Col xs={4} md={1} className='text-center mt-3'>
                        <a href="https://www.linkedin.com/feed/" target="_blank">
                            <ImLinkedin className='text-light logo-social' />
                        </a>
                    </Col>
                    <Col xs={4} md={1} className='text-center mt-3'>
                        <a href="https://www.facebook.com/" target="_blank">
                            <ImFacebook2 className='text-light logo-social' />
                        </a>
                    </Col>
                    <Col xs={4} md={1} className='text-center mt-3'>
                        <a href="https://www.instagram.com/" target="_blank">
                            <FaInstagram className='text-light logo-social' />
                        </a>
                    </Col>
                    <Col xs={4} md={1} className='text-center mt-3'>
                        <a href="https://www.twitter.com/" target="_blank">
                            <ImTwitter className='text-light logo-social' />
                        </a>
                    </Col>
                    <Col xs={4} md={1} className='text-center mt-3'>
                        <a href="https://www.youtube.com/" target="_blank">
                            <ImYoutube className='text-light logo-social' />
                        </a>
                    </Col>
                    <Col xs={4} md={1} className='text-center mt-3'>
                        <a href="https://www.tiktok.com/" target="_blank">
                            <FaTiktok className='text-light logo-social' />
                        </a>
                    </Col>
                    <Col xs={4} md={1} className='text-center mt-3'>
                        <ImWhatsapp className='text-light logo-social' />
                    </Col>
                    <Col xs={4} md={1} className='text-center mt-3'>
                        <FaDiscord className='text-light logo-social' />
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Footer;
import React, { useState, useCallback } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './NftShop.css';
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Button, Accordion, Table, Dropdown, Modal, Form } from 'react-bootstrap';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import Loader from './Loader';
import { RiShareBoxLine } from 'react-icons/ri';
import { FaRegCopy } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import PageService from '../../core/PageService';
import AppGlobal from '../../context/AppGlobal';
import { useEffect } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCards } from "swiper";
import useBordes from '../../hooks/useBordes';
import { useWeb3React } from '@web3-react/core';
import { ethers } from "ethers";
import InputGroup from 'react-bootstrap/InputGroup';
import Viewer from 'react-viewer';
import { useSelector } from 'react-redux'

// + IMAGES +
import person from '../../assets/imgs/person.jpeg';
import avatar from '../../assets/imgs/avatar.png';
import eth from '../../assets/imgs/eth.png';
import qr from '../../assets/imgs/qr-inter.png';
// - IMAGES -

function OfferModal(props) {
    const { t } = useTranslation();

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {t('nftShop.labels.offer')}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Row className='align-items-center'>
                        <Col xs={2} md={1}>
                            <img src={eth} width='20px' alt="" />
                        </Col>
                        <Col xs={10} md={11}>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>{t('nftShop.labels.price')}</Form.Label>
                                <Form.Control type="number" placeholder="0.00" />
                            </Form.Group>
                        </Col>
                    </Row>
                    <p className='mt-3'>{t('nftShop.labels.expiration')}</p>
                    <Row className='align-items-center mb-3'>
                        <Col xs={12} md={4}>
                            <Form.Select className='mt-2'>
                                <option>{t('nftShop.labels.day')}</option>
                                <option value="1">One</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option>
                            </Form.Select>
                        </Col>
                        <Col xs={12} md={4}>
                            <Form.Select className='mt-2'>
                                <option>{t('nftShop.labels.months')}</option>
                                <option value="1">One</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option>
                            </Form.Select>
                        </Col>
                        <Col xs={12} md={4}>
                            <Form.Select className='mt-2'>
                                <option>{t('nftShop.labels.hour')}</option>
                                <option value="1">One</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option>
                            </Form.Select>
                        </Col>
                    </Row>

                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onHide} className='btn-home create' variant='outline'>{t('nftShop.buttons.offer')}</Button>
            </Modal.Footer>
        </Modal>
    );
}

function MarketModal(props) {
    const { t } = useTranslation();

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {t('nftShop.labels.market')}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Row className='align-items-center'>
                        {/* nombres, apellidos, pais, ciudad, archivo, texto libre, link con su trabajo, telefono */}
                        <h5>{t('nftShop.labels.addWork')}</h5>
                        <Col xs={1} md={12}>
                            {['radio'].map((type) => (
                                <div key={type} className="mb-3">
                                    <Form.Check type={type} id={`check-api-${type}`}>
                                        <Form.Check
                                            reverse
                                            label="Si quiero"
                                            name="group1"
                                            type={type}
                                            id={`reverse-${type}-1`}
                                            className="mt-3 "
                                        />
                                        <Form.Check
                                            reverse
                                            label="No en este momento"
                                            name="group1"
                                            type={type}
                                            id={`reverse-${type}-2`}
                                            className="mt-3"
                                        />
                                    </Form.Check>
                                </div>
                            ))}
                        </Col>

                        <Col xs={2} md={1}>
                            <img src={eth} width='20px' alt="" />
                        </Col>
                        <Col xs={10} md={11}>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>{t('nftShop.labels.price')}</Form.Label>
                                <Form.Control type="number" placeholder="0.00" />
                            </Form.Group>
                        </Col>

                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Row className='align-items-center'>
                    <Col xs={12} md={6}>
                        <Button className='text-uppercase create' variant='outline'>{t('buttons.cancel')}</Button>
                    </Col>
                    <Col xs={12} md={6}>
                        <Button className='text-uppercase btn-global text-white' variant='outline'>{t('buttons.buy')}</Button>
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    );
}


const NftShop = () => {

    const { global, addGlobal } = useContext(AppGlobal);
    const pageService = new PageService({
        appGlobal: {
            global: global,
            addGlobal: addGlobal
        }
    });

    const { t } = useTranslation();
    const { id: nftId } = useParams();
    const [nft, setNft] = useState();
    const [nftOwnerPicture, setNftOwnerPicture] = useState(avatar);
    const [nftCreatorPicture, setNftCreatorPicture] = useState(avatar);
    const [nftOwnerName, setNftOwnerName] = useState();
    const [nftCreatorName, setNftCreatorName] = useState();
    const [contractAddress, setContractAddress] = useState();
    const bordes = useBordes();
    const { account, chainId } = useWeb3React();

    const language = useSelector((state) => state.language.value);

    const data = [{ name: '5/10', uv: 0.2 }, { name: '5/11', uv: 0.5 }];
    const [modalShow, setModalShow] = React.useState(false);

    const navigate = useNavigate();

    const openNft = url => {
        window.open(url, '_blank');
    }


    const getUserPicture = (userKey) => {
        if (nft[userKey].picture)
            return pageService.settings.images.url + '/' + nft[userKey].picture;
        else
            return avatar;
    }

    const getUserName = (userKey) => {
        if (nft[userKey].firstName) {
            return nft[userKey].firstName + ' ' + nft[userKey].lastName;
        } else {
            if (nft[userKey].address) {
                const address = nft[userKey].address;
                return address.substr(0, 6) + '...' + address.substr(-4);
            } else {
                return "0x4A4C3b4...TEST";
            }
        }
    }

    // const getPrice = () => {
    //     return nft.price / 1000;
    // }

    const handleBuy = () => {
        // if (nft.status == pageService.settings.nfts.status.lazy)
        //     handleMint();
        // else
        //     buyNft();
        pageService.showSuccess("Pronto podrás realizar la compra del NFT");
    }

    const buyNftTest = () => {
        alert(ethers.utils.formatEther("125"));
        console.log(ethers.utils.formatEther("125"));
    }

    const buyNft = () => {

        // alert(ethers.utils.formatEther(`${nft.price}`));
        // alert(ethers.utils.parseEther(`${nft.price/1000}`).toString());
        console.log(ethers.utils.parseEther(`${nft.price / 1000}`).toString());

        bordes.bordesMarketplace.methods.buyItem(
            pageService.settings.contracts.bordes.address[chainId],
            nft.number
        ).send({
            from: account,
            value: ethers.utils.parseEther(`${nft.price / 1000}`)
        })
            .on("transactionHash", (txHash) => {
                pageService.showSuccess(t('nftShop.alerts.buy'));
            })
            .on("receipt", () => {
                pageService.showSuccess(t('nftShop.alerts.purchased'));

                pageService.showLoading();
                pageService.httpPut({
                    endPoint: pageService.settings.api.endPoints.nfts + '/' + nft.id,
                    values: {
                        status: pageService.settings.nfts.status.bought
                    }
                }).then(result => {
                    setNft(result.data);
                    pageService.showSuccess(t('nftShop.alerts.updateProd'));
                })
                    .catch(error => {
                        console.log(error);
                        pageService.showError(error);
                    })
                    .finally(() => pageService.hideLoading());
            })
            .on("error", (error) => {
                console.log(error);
                pageService.showError(error);
            });
    }


    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const handleMint = () => {
        const attrDesc = 'description_' + global.user.language;

        const nftToMint = {
            name: nft.name,
            description: nft[attrDesc],
            image: "ipfs://" + nft.ipfs
        };

        let base64data = btoa(JSON.stringify(nftToMint));
        bordes.bordes.methods.safeMint(account, `data:application/json;base64,${base64data}`).send({
            from: account
        })
            .on("transactionHash", (txHash) => {
                pageService.showSuccess(t('nftShop.alerts.process'));
            })
            .on("receipt", () => {
                pageService.showSuccess(t('nftShop.alerts.transactionConfirm'));

                pageService.showLoading();
                pageService.httpPut({
                    endPoint: pageService.settings.api.endPoints.nfts + '/' + nft.id,
                    values: {
                        status: pageService.settings.nfts.status.minted
                    }
                }).then(result => {
                    console.log(result);
                    setNft(result.data);
                    pageService.showSuccess(t('nftShop.alerts.purchased'));
                })
                    .catch(error => {
                        pageService.showError(error);
                    })
                    .finally(() => pageService.hideLoading());
            })
            .on("error", (error) => {
                pageService.showError(error);
            });
    }

    const handleTransfer = () => {
        alert('transfer');
    }

    const goBack = () => {
        navigate(-1);
    }

    const loadNft = (id) => {
        pageService.showLoading();
        pageService.httpGet({
            endPoint: pageService.settings.api.endPoints.nftsShop,
            queryParams: { id }
        }).then(result => {
            setNft(result.data);
            console.log(result.data);
        })
            .catch(error => {
                pageService.showError(error);
            })
            .finally(() => pageService.hideLoading());
    }

    useEffect(() => {
        if (bordes && bordes.bordes) {
            const address = bordes.bordes._address;
            setContractAddress(address.substr(0, 6) + '...' + address.substr(-4));
        } else {
            const address = pageService.settings.contracts.bordes.address[pageService.settings.contracts.bordes.chain];
            setContractAddress(address.substr(0, 6) + '...' + address.substr(-4));
        }
    }, [bordes]);

    useEffect(() => {
        loadNft(nftId);
    }, [nftId]);

    useEffect(() => {
        if (!getUserPicture || !getUserName || !nft) return;
        setNftOwnerPicture(getUserPicture('owner'));
        setNftCreatorPicture(getUserPicture('creator'));
        setNftOwnerName(getUserName('owner'));
        setNftCreatorName(getUserName('creator'));
    }, [nft, getUserName, getUserPicture]);

    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <a
            href=""
            ref={ref}
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}
        >
            {children}
            &#x25bc;
        </a>
    ));

    const [item, setItem] = useState(false);

    setTimeout(() => {
        setItem(true);
    }, 100);

    const goToUser = () => {
        navigate('/user');
    }

    const [visibleImage1, setVisibleImage1] = React.useState(false);

    const [visibleImage2, setVisibleImage2] = React.useState(false);

    return (
        <Container className='pb-5'>
            {/* LOADER */}
            {!nft &&
                <Loader />
            }
            {/* LOADER */}

            {/* INFO */}
            {nft &&
                <>
                    <Row className='align-items-center justify-content-center mt-5'>
                        <Col xs={12} md={6}>
                            <img onClick={ () => openNft(pageService.settings.images.url + '/' + nft?.file) } src={pageService.settings.images.url + '/lg/' + nft?.file} className='w-100 shop-img mt-3' />
                        </Col>
                        {nft.file2 &&
                        <Col xs={12} md={6}>
                            <img onClick={ () => openNft(pageService.settings.images.url + '/' + nft?.file2) } src={pageService.settings.images.url + '/lg/' + nft?.file2} className='w-100 shop-img mt-3' />
                        </Col>
                        }

                        <Viewer
                            visible={visibleImage1}
                            onClose={() => { setVisibleImage1(false); }}
                            images={[{ src: pageService.settings.images.url + '/' + nft?.file }]}
                        />

                        <Viewer
                            visible={visibleImage2}
                            onClose={() => { setVisibleImage2(false); }}
                            images={[{ src: pageService.settings.images.url + '/' + nft?.file2 }]}
                        />
                    </Row>

                    <Row className='mt-5'>
                        <Col xs={12} md={6}>
                            <Row className='align-items-center mt-3'>
                                <Col>
                                    <p className='text-left subtitle'>{t('nftShop.labels.owner')}</p>
                                </Col>
                                <Col xs={"auto"} className='align-right'>
                                    <p className='text-right'>
                                        <img src={nftCreatorPicture} className='avatar' alt="" />
                                        <span className='name'>{nftOwnerName}</span>
                                    </p>
                                </Col>


                                {/*         <h3 className='nft-name'>{nft?.name}</h3> */}
                                {/* <h1 className='title'>{nft?.nftCollection.name}</h1> */}
                                <Row>
                                    <Col xs={{ span: 12, order: 1 }} md={{ span: 12, order: 1 }}>
                                        <h1 className='title'>{nft?.name}</h1>
                                    </Col>
                                    <Col xs={{ span: 12, order: 3 }} md={{ span: 12, order: 2 }}>
                                        {/* <p>{nft?.nftCollection['description_' + language]}</p> */}
                                        <p>{nft?.['description_' + language]}</p>
                                    </Col>
                                    <Col xs={{ span: 12, order: 2 }} md={{ span: 12, order: 3 }}>
                                        <p className='subtitle r-mt-5 mb-1'>{t('nftShop.labels.actual')}</p>
                                        <div className="d-flex align-items-center">
                                            <img src={eth} alt="" />
                                            <span className='price'>{nft?.price / 1000}</span>
                                        </div>

                                        <Row>
                                            {/* <Col xs={12} md={6}>
                                            <Button className='offer mt-2' variant='outline' onClick={goBack}>{t('buttons.back')}</Button>
                                            </Col> */}
                                            <Col xs={12}>
                                                <Button className='btn-global mt-2 r-mb' onClick={handleBuy} >{t('buttons.buy')}</Button>
                                                {/* onClick={() => setModalShow(true)} */}
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>

                            </Row>
                            <MarketModal
                                show={modalShow}
                                onHide={() => setModalShow(false)}
                            />

                            {/* <OfferModal
                                show={modalShow}
                                onHide={() => setModalShow(false)}
                            /> */}
                        </Col>

                        <Col xs={12} md={6}>
                            <Row className='align-items-center r-mobile-pt '>
                                <Col>
                                    <p className='text-left mt-3 subtitle-owner'>{t('nftShop.labels.creator')}</p>
                                </Col>
                                <Col xs={"auto"} className='text-end'>
                                    <p className='text-right m-0'>
                                        <img src={nftOwnerPicture} className='avatar' alt="" />
                                        <span className='name-owner'>{nftCreatorName}</span>
                                    </p>
                                </Col>
                            </Row>
                            {/* <Row className='align-items-center mt-3'>
                                <Col>
                                    <h3 className='nft-name'>{nft?.name}</h3>
                                </Col>
                            </Row> */}

                            {/* <Accordion defaultActiveKey='0' className='accordion' flush>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>{t('nftShop.labels.description')}</Accordion.Header>
                                    <Accordion.Body>
                                        {nft?.['description_' + language]}
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion> */}

                            <Accordion defaultActiveKey='0' className='accordion mt-3' flush>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>{t('nftShop.labels.detail')}</Accordion.Header>
                                    <Accordion.Body>
                                        <Row>
                                            <Col>
                                                <p className='subtitle-owner'>Contract Address</p>
                                            </Col>
                                            <Col>
                                                <p className='text-danger contract'><FaRegCopy className='text-danger' /> {contractAddress}</p>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <p className='subtitle-owner'>Token ID</p>
                                            </Col>
                                            <Col>
                                                <p className='contract'>{nft?.number}</p>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <p className='subtitle-owner'>Metadata</p>
                                            </Col>
                                            <Col>
                                                <p className='contract'>Centralized</p>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <p className='subtitle-owner'>Creator Fees</p>
                                            </Col>
                                            <Col>
                                                <p className='contract'>10%</p>
                                            </Col>
                                        </Row>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Col>
                    </Row>
                </>
            }
        </Container>
    );
}

export default NftShop;
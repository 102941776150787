import { Routes, Route } from 'react-router-dom';
import Home from './modules/home/Home';
import CreatorsInformation from './modules/creators-information/CreatorsInformation';
import Test from './modules/test/index';
import TopMenu from './components/topmenu/TopMenu';
import Footer from './components/footer/Footer';
import FormContact from './modules/form-contact/FormContact'
import NftShop from './modules/nft-shop/NftShop';
import Dematerializations from './modules/dematerializations/Dematerializations';
import Interventions from './modules/interventions/Interventions';
import NftClassic from './modules/nft-classic/NftClassic';
import NftDouble from './modules/nft-double/NftDouble';
import NftDoubleIntervention from './modules/nft-double-intervention/NftDoubleIntervention';
import NftIntervention from './modules/nft-intervention/NftIntervention';
import NftDematerializationTemplate from './modules/nft-dematerialization-template/NftDematerializationTemplate';
import NftInterventionTemplate from './modules/nft-intervention-template/NftInterventionTemplate';
import CollectionItem from './modules/collection-item/CollectionItem';
import Collection from './modules/collection/Collection';
import About from './modules/about/About';
import AboutBordes from './modules/about-bordes/AboutBordes';
import User from './modules/user/User';
import 'bootstrap/dist/css/bootstrap.min.css';
import './global.css';
import useGlobal from './hooks/useGlobal';
import AppGlobal from './context/AppGlobal';

import SpinnerLoader from './components/spinner/Spinner';

const App = () => {
	const global = useGlobal();
  return (
    <div className='bg-color'>
      <AppGlobal.Provider value={global}>
        <TopMenu />
        <Routes>
          <Route path='/' element={<Home />}></Route>
          <Route path='/about' element={<About />}></Route>
          <Route path='/about-bordes' element={<AboutBordes />}></Route>
          <Route path='/collection/:code/' element={<Collection />}></Route>
          <Route path='/collection-item' element={<CollectionItem />}></Route>
          <Route path='/creators-information' element={<CreatorsInformation />}></Route>
          <Route path='/dematerializations' element={<Dematerializations />}></Route>
          <Route path='/form-contact' element={<FormContact />}></Route>
          <Route path='/interventions' element={<Interventions />}></Route>
          <Route path='/nft-dematerialization-template' element={<NftDematerializationTemplate />}></Route>
          <Route path='/nft-classic/:collectionId/:id' element={<NftClassic />}></Route>
          <Route path='/nft-double/:collectionId/:id' element={<NftDouble />}></Route>
          <Route path='/nft-double-intervention/:collectionId/:id' element={<NftDoubleIntervention />}></Route>
          <Route path='/nft-intervention' element={<NftIntervention />}></Route>
          <Route path='/nft-intervention-template' element={<NftInterventionTemplate />}></Route>
          <Route path='/nft-shop/:id' element={<NftShop />}></Route>
          <Route path='/user' element={<User />}></Route>
          <Route path="/test" element={<Test />}/>
        </Routes>
        <Footer />
        <SpinnerLoader/>
      </AppGlobal.Provider>
    </div>
  );
}

export default App;

import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import { MdUpload } from 'react-icons/md';
import './NftDematerializationTemplate.css';
import Loader from './Loader';
import { useTranslation, Trans } from 'react-i18next';

const NftDematerializationTemplate = () => {
    const [item, setItem] = useState(false);
    const { t } = useTranslation();

    setTimeout(() => {
        setItem(true);
    }, 2500);

    return (
        <Container className='pb-5'>
            {/* LOADER */}
            {!item &&
                <Loader />
            }
            {/* LOADER */}

            {item &&
                <>
                    <Row className='mt-5'>
                        <h1 className='title'>{t('nftTemplate.labels.title')}</h1>
                        <h4 className='subtitle'>{t('labels.dematerialization')}</h4>
                    </Row>

                    <Row>
                        <Col xs={12} md={6}>
                            <p class='text mt-5'>{t('nftTemplate.labels.subtitle')}</p>
                            <Row>
                                <Col xs={12} md={6}>
                                    <div className='upload mt-3'>
                                        <Row className='justify-content-center'>
                                            <p className='text-center'>QR</p>
                                        </Row>
                                        <Row className='justify-content-center'>
                                            <MdUpload className='upload-icon' />
                                        </Row>
                                    </div>
                                </Col>
                                <Col xs={12} md={6}>
                                    <div className='upload mt-3'>
                                        <Row className='justify-content-center'>
                                            <p className='text-center'>{t('nftTemplate.labels.image')}</p>
                                        </Row>
                                        <Row className='justify-content-center'>
                                            <MdUpload className='upload-icon' />
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={12} md={6} className='mt-3'>
                            <h4 className='subtitle align-right'>{t('nftTemplate.labels.preview')}</h4>
                            <Row className='justify-content-end'>
                                <Col xs={12} md={6}></Col>
                                <Col xs={12} md={6}>
                                    <div className='preview align-right'></div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Form className='mt-5'>
                        <Row>
                            <Col xs={12} md={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label className='text-danger'>{t('nftTemplate.form.name')}</Form.Label>
                                    <Form.Control className='control' type="text" placeholder={t('nftTemplate.form.name')} />
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label className='text-danger'>{t('nftTemplate.form.description')}</Form.Label>
                                    <Form.Control className='control' as="textarea" rows={3} placeholder={t('nftTemplate.form.writeHere')} />
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label className='text-danger'>Tags</Form.Label>
                                    <Form.Control className='control' type="text" placeholder="Tags" />
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label className='text-danger'>{t('nftTemplate.form.price')}</Form.Label>
                                    <Form.Control className='control' type="text" placeholder="000000" />
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row className='mt-3'>
                            <Col xs={12} md={6}></Col>
                            <Col xs={12} md={6}>
                                <p className='mint'>{t('nftTemplate.form.estimated')}</p>

                                <p className='align-right price'>999.999</p>

                                <Row className='justify-content-end'>
                                    <Col xs={12} md={4}></Col>
                                    <Col xs={12} md={4}>
                                        <Button variant='outline' className='d-block w-100 create mt-2' type='button'>{t('buttons.cancel')}</Button>
                                    </Col>
                                    <Col xs={12} md={4}>
                                        <Button variant='danger' className='d-block w-100 mt-2' type='submit'>{t('buttons.accept')}</Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Form>
                </>
            }
        </Container>
    );
}

export default NftDematerializationTemplate;
import AppGlobal from "../context/AppGlobal";
import PageService from "./PageService";
import AppSettings from "../AppSettings";
import React, { Component } from 'react';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useContext } from "react";
import useBordes from "../hooks/useBordes";
import { useWeb3React } from "@web3-react/core";
import { useParams } from "react-router-dom";
import useLocationChanged from "../hooks/useLocationChanged";


export const withAppGlobal = (Component : Component) => {
    return props => <Component {...props} appGlobal={useContext(AppGlobal)} />;
} 

export const withNavigation = (Component : Component) => {
    return props => <Component {...props} navigate={useNavigate()} />;
} 

export const withI18n = (Component : Component) => {
    return props => <Component {...props} i18n={useTranslation()} />;
} 

export const withBordes = (Component : Component) => {
    return props => <Component {...props} bordes={useBordes()} />;
} 

export const withWeb3React = (Component : Component) => {
    return props => <Component {...props} web3React={useWeb3React()} />;
} 

export const withUseParams = (Component : Component) => {
    return props => <Component {...props} params={useParams()} />;
} 

export const withUseLocationChanged = (Component : Component) => {
    return props => <Component {...props} locationChanged={useLocationChanged()} />;
} 

class BaseComponent extends React.Component {

    static contextType = AppGlobal;

    constructor(props) {
        super(props);
        this.pageService = new PageService(this.props);
        this.appSettings = AppSettings();
        this.settings = this.appSettings.settings;
        //this.hideLoading();
    }

    t(value) {
        return this.props.i18n.t(value);
    }

    goBack() {
        return this.props.navigate(-1);
    }

    navigate(path, params = {}) {
        return this.props.navigate(path, params);
    }

    render() {
        return false;
    }

    // showLoading() {
    //     this.setState({
    //         loading: true
    //     });
    // }

    // hideLoading() {
    //     this.setState({
    //         loading: false
    //     });
    // }

}

export default BaseComponent;

import { useMemo } from "react";
import { useWeb3React } from "@web3-react/core";
import BordesArtifact from "../../config/web3/artifacts/Bordes";
import BordesLazyNFTArtifact from "../../config/web3/artifacts/BordesLazyNFT";
import BordesMarketplaceArtifact from "../../config/web3/artifacts/BordesMarketplace";

const bordesArtifact = BordesArtifact;
const bordesLazyNFTArtifact = BordesLazyNFTArtifact;
const bordesMarketplaceArtifact = BordesMarketplaceArtifact;

const useBordes = () => {
  const { active, library, chainId } = useWeb3React();

  const bordes = useMemo(() => {
    if (active && chainId && bordesArtifact.address) {
      return new library.eth.Contract(bordesArtifact.abi, bordesArtifact.address[chainId]);
    }
  }, [active, chainId, library?.eth?.Contract]);

  const bordesLazyNFT = useMemo(() => {
    if (active && chainId && bordesArtifact.address) {
      return new library.eth.Contract(bordesLazyNFTArtifact.abi, bordesLazyNFTArtifact.address[chainId]);
    }
  }, [active, chainId, library?.eth?.Contract]);

  const bordesMarketplace = useMemo(() => {
    if (active && chainId && bordesMarketplaceArtifact.address) {
      return new library.eth.Contract(bordesMarketplaceArtifact.abi, bordesMarketplaceArtifact.address[chainId]);
    }
  }, [active, chainId, library?.eth?.Contract]);

  return {bordes,bordesLazyNFT,bordesMarketplace};
};

export default useBordes;
import 'bootstrap/dist/css/bootstrap.min.css';
import './NftIntervention.css';
import { Row, Col, Placeholder } from 'react-bootstrap';

const Loader = () => {
    return (
        <>
            <Row className='mt-5'>
                <Placeholder as="p" animation="glow">
                    <Placeholder xs={6} size='lg' />
                </Placeholder>
            </Row>

            <Row>
                <Col xs={12} md={6}>
                    <Placeholder as="p" animation="glow">
                        <Placeholder xs={6} size='lg' />
                    </Placeholder>

                    <Row>
                        <Col xs={12} md={6}>
                            <Placeholder animation="glow">
                                <Placeholder className='img-toolbar' xs={8} size="lg" />
                            </Placeholder>
                        </Col>
                        <Col xs={12} md={6}>
                            <Placeholder animation="glow">
                                <Placeholder className='img-toolbar' xs={8} size="lg" />
                            </Placeholder>
                        </Col>
                    </Row>

                    <Row className='mt-4'>
                        <Placeholder animation="glow">
                            <Placeholder className='img-toolbar' xs={12} size="lg" />
                        </Placeholder>
                    </Row>
                </Col>
                <Col xs={12} md={6}>
                    <Placeholder as="p" animation="glow" className='align-right'>
                        <Placeholder xs={6} size='lg' />
                    </Placeholder>

                    <Placeholder animation="glow">
                        <Placeholder className='img-placeholder' xs={12} size="lg" />
                    </Placeholder>
                </Col>
            </Row>

            <Row className='mt-5'>
                <Col xs={12} md={6}>
                    <Placeholder as="p" animation="glow">
                        <Placeholder xs={12} size='lg' />
                    </Placeholder>

                    <Placeholder as="p" animation="glow">
                        <Placeholder xs={12} size='lg' />
                    </Placeholder>
                </Col>
                <Col xs={12} md={6}>
                    <Placeholder as="p" animation="glow">
                        <Placeholder xs={12} size='lg' />
                    </Placeholder>
                    <Placeholder as="p" animation="glow">
                        <Placeholder xs={12} size='lg' />
                    </Placeholder>
                </Col>
            </Row>

            <Row className='mt-4'>
                <Col xs={12} md={6}></Col>
                <Col xs={12} md={6}>
                    <Placeholder as="p" animation="glow" className='align-right'>
                        <Placeholder xs={6} size='lg' />
                    </Placeholder>

                    <Placeholder as="p" animation="glow" className='header-placeholder'>
                        <Placeholder.Button variant='medium' className='mt-2' xs={5} />
                        <Placeholder.Button variant='medium' className='mt-2' xs={5} />
                    </Placeholder>
                </Col>
            </Row>
        </>
    );
}

export default Loader;
import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import './CreatorsInformation.css';
import Loader from './Loader';
import { useTranslation } from 'react-i18next';

// + IMAGES +
import eth from '../../assets/imgs/eth.png';
import person from '../../assets/imgs/person.jpeg';
import desma from '../../assets/imgs/desma.png';
// - IMAGES -

// - ICONS -
import { BsDot } from "react-icons/bs"
// import { IoLocationOutline } from "react-icons/io5";
const CreatorsInformation = () => {
    const [item, setItem] = useState(false);

    const { t } = useTranslation();

    setTimeout(() => {
        setItem(true);
    }, 2500);

    return (
        <Container className='pb-5'>

            {/* LOADER */}
            {!item &&
                <Loader />
            }
            {/* LOADER */}

            {item &&
                <>
                    <div className='w-75 m-auto'>
                        <Row className='mt-5'>
                            <h1 className='title text-uppercase'>{t('creators.labels.title')}</h1>
                        </Row>

                        <Row className="justify-content-center align-items-center mt-5">
                            <h5 className='bordes text-ashes'>  <BsDot />Title</h5>
                            <p className='text-ashes custom-pl'>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quisquam ullam vero quasi fugiat harum similique cumque obcaecati sed, molestiae, omnis in ratione eveniet exercitationem dolor sapiente consequatur illo pariatur dolorum.</p>
                        </Row>
                        <Row className="justify-content-center align-items-center mt-2">
                            <h5 className='bordes text-ashes'>  <BsDot />Title</h5>
                            <p className='text-ashes custom-pl'>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quisquam ullam vero quasi fugiat harum similique cumque obcaecati sed, molestiae, omnis in ratione eveniet exercitationem dolor sapiente consequatur illo pariatur dolorum.</p>
                        </Row>
                        <Row className="justify-content-center align-items-center mt-2">
                            <h5 className='bordes text-ashes'>  <BsDot />Title</h5>
                            <p className='text-ashes custom-pl'>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quisquam ullam vero quasi fugiat harum similique cumque obcaecati sed, molestiae, omnis in ratione eveniet exercitationem dolor sapiente consequatur illo pariatur dolorum.</p>
                        </Row>
                        <Row className="justify-content-center align-items-center mt-2">
                            <h5 className='bordes text-ashes'>  <BsDot />Title</h5>
                            <p className='text-ashes custom-pl'>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quisquam ullam vero quasi fugiat harum similique cumque obcaecati sed, molestiae, omnis in ratione eveniet exercitationem dolor sapiente consequatur illo pariatur dolorum.</p>
                        </Row>
                        <Row className="justify-content-center align-items-center mt-2">
                            <h5 className='bordes text-ashes'>  <BsDot />Title</h5>
                            <p className='text-ashes custom-pl'>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quisquam ullam vero quasi fugiat harum similique cumque obcaecati sed, molestiae, omnis in ratione eveniet exercitationem dolor sapiente consequatur illo pariatur dolorum.</p>
                        </Row>
                    </div>
                </>
            }
        </Container>
    );
}

export default CreatorsInformation;
import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col } from 'react-bootstrap';
import './Dematerializations.css';
import Loader from './Loader';
import { useTranslation } from 'react-i18next';

// + IMAGES +
import eth from '../../assets/imgs/eth.png';
import person from '../../assets/imgs/person.jpeg';
import desma from '../../assets/imgs/desma.png';
// - IMAGES -

const Dematerializations = () => {
    const [item, setItem] = useState(false);

    const { t } = useTranslation();

    setTimeout(() => {
        setItem(true);
    }, 2500);

    return (
        <Container className='pb-5'>
            {/* LOADER */}
            {!item &&
                <Loader />
            }
            {/* LOADER */}
            
            {item &&
                <>
                    <Row className='mt-5'>
                        <h1 className='text-danger title'>{t('labels.dematerialization')}</h1>
                        <p className='text-danger text'>{t('dematerialization.labels.description')}</p>
                    </Row>

                    <Row className="justify-content-center align-items-center mt-5">
                        <Col xs={12} md={4}>
                            <img src={desma} className='d-block w-100 shop-img' alt="" />
                            <Row className='mt-3'>
                                <Col xs={6} md={6}>
                                    <p className='text-left m-1 subtitle'>PROPIETARIO</p>
                                    <p className='m-1 bordes'>BORDES</p>
                                    <p className='m-1 price-shop'>Precio</p>
                                </Col>
                                <Col xs={6} md={6} className='align-right'>
                                    <p className='text-right'>
                                        <img src={person} className='avatar' alt="" />
                                        <span className='name'>CECILIA</span>
                                    </p>

                                    <p className='text-right mt-4 mb-1'>
                                        <img src={eth} width='10px' alt="" />
                                        <span className='price-eth'>0.009</span>
                                    </p>

                                    <p className='m-0 price-shop'>$2.35</p>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={12} md={2}></Col>
                        <Col xs={12} md={4}>
                            <img src={desma} className='d-block w-100 shop-img' alt="" />
                            <Row className='mt-3'>
                                <Col xs={6} md={6}>
                                    <p className='text-left m-1 subtitle'>PROPIETARIO</p>
                                    <p className='m-1 bordes'>BORDES</p>
                                    <p className='m-1 price-shop'>Precio</p>
                                </Col>
                                <Col xs={6} md={6} className='align-right'>
                                    <p className='text-right'>
                                        <img src={person} className='avatar' alt="" />
                                        <span className='name'>CECILIA</span>
                                    </p>

                                    <p className='text-right mt-4 mb-1'>
                                        <img src={eth} width='10px' alt="" />
                                        <span className='price-eth'>0.009</span>
                                    </p>

                                    <p className='m-0 price-shop'>$2.35</p>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row className="justify-content-center align-items-center mt-5">
                        <Col xs={12} md={4}>
                            <img src={desma} className='d-block w-100 shop-img' alt="" />
                            <Row className='mt-3'>
                                <Col xs={6} md={6}>
                                    <p className='text-left m-1 subtitle'>PROPIETARIO</p>
                                    <p className='m-1 bordes'>BORDES</p>
                                    <p className='m-1 price-shop'>Precio</p>
                                </Col>
                                <Col xs={6} md={6} className='align-right'>
                                    <p className='text-right'>
                                        <img src={person} className='avatar' alt="" />
                                        <span className='name'>CECILIA</span>
                                    </p>

                                    <p className='text-right mt-4 mb-1'>
                                        <img src={eth} width='10px' alt="" />
                                        <span className='price-eth'>0.009</span>
                                    </p>

                                    <p className='m-0 price-shop'>$2.35</p>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={12} md={2}></Col>
                        <Col xs={12} md={4}>
                            <img src={desma} className='d-block w-100 shop-img' alt="" />
                            <Row className='mt-3'>
                                <Col xs={6} md={6}>
                                    <p className='text-left m-1 subtitle'>PROPIETARIO</p>
                                    <p className='m-1 bordes'>BORDES</p>
                                    <p className='m-1 price-shop'>Precio</p>
                                </Col>
                                <Col xs={6} md={6} className='align-right'>
                                    <p className='text-right'>
                                        <img src={person} className='avatar' alt="" />
                                        <span className='name'>CECILIA</span>
                                    </p>

                                    <p className='text-right mt-4 mb-1'>
                                        <img src={eth} width='10px' alt="" />
                                        <span className='price-eth'>0.009</span>
                                    </p>

                                    <p className='m-0 price-shop'>$2.35</p>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </>
            }
        </Container>
    );
}

export default Dematerializations;
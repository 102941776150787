import React, { useEffect } from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import { useTranslation, Trans } from 'react-i18next';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';

// - ICONS -
import { BsDot } from "react-icons/bs"

const About = () => {

    const { t } = useTranslation();

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <Container className='pb-5 ps-4 pe-4'>

            <div className='w-75 m-auto'>
                <Row className='mt-5'>
                    <h1 className='title text-uppercase'>{t('about.labels.about')} bordes.io</h1>
                </Row>

                <Row className="justify-content-center align-items-center mt-5">
                    <h2 className='bordes simple-page-text'>{t('about.labels.aboutSubtitle')}</h2>
                </Row>
                <br></br>
                <br></br>
                <Row className="justify-content-center align-items-center mt-2">
                    <h5 className='bordes simple-page-text'>  <BsDot />{t('about.labels.question1')}</h5>
                    <p className='simple-page-text simple-page-text-justified'>{t('about.labels.answer1')}</p>
                </Row>
                <Row className="justify-content-center align-items-center mt-2">
                    <h5 className='bordes simple-page-text'>  <BsDot />{t('about.labels.question2')}</h5>
                    <p className='simple-page-text simple-page-text-justified'>{t('about.labels.answer2')}</p>
                </Row>
                <Row className="justify-content-center align-items-center mt-2">
                    <h5 className='bordes simple-page-text'>  <BsDot />{t('about.labels.question3')}</h5>
                    <p className='simple-page-text simple-page-text-justified'>{t('about.labels.answer3')}</p>
                </Row>
                <Row className="justify-content-center align-items-center mt-2">
                    <h5 className='bordes simple-page-text'>  <BsDot />{t('about.labels.question4')}</h5>
                    <p className='simple-page-text simple-page-text-justified'>{t('about.labels.answer4')}</p>
                </Row>

                <Row className="justify-content-center align-items-center mt-5">
                    <h5 className='bordes simple-page-text'>{t('about.labels.description')}</h5>
                </Row>

            </div>
        </Container>
    );
}

export default About;
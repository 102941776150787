import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: false,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          buttons: {
            back: 'BACK',
            save: 'SAVE',
            cancel: 'cancel',
            accept: 'ACCEPT',
            create: 'CREATE',
            buy: 'BUY'
          },
          labels: {
            hype1: 'Did you know that Bordes is a project that was born in 2012 with the idea of diverting the gaze towards the periphery, towards the limit of space?',
            hype2: 'Materiality overflows imperiously to become an edge...that look that deviates to be rethought again...',
            preview: 'preview',
            dematerialization: 'DEMATERIALIZATION'
          },

          dematerialization: {
            buttons: {

            },
            labels: {
              description: 'If a transformation from the absence of matter is possible, Bordes (2015-2022 project) after rethinking the materialization-rematerialization process, accentuates dematerialization, conceptualizing it as the movement of disappearance of the work as an object. In that sense he activates the process of the image in itself to rethink the "at times", the BETWEEN the physical-material, the possible, the imaginary and the virtual.'
            }
          },

          collection: {
            buttons: {
              interventions: {
                intervene: 'INTERVENE YOUR NFT',
                wantToKnow: 'I WANT TO KNOW ABOUT THE CREATION PROCESS',
                createIntervention: 'CREATE INTERVENTION'
              }
            },
            labels: {
              interventions: {
                proposal: 'ENCOURAGE TO CREATE',
                proposalExplained: 'Design from the artist\'s concept and by selecting an image that you want to treasure create your own NFT',
              }
            }
          },

          collection_item: {
            buttons: {

            },
            labels: {
              title: 'CREATE COLLECTION',
              optionTitle: 'Choose your type of collection',
              nameCollection: 'Collection Name',
              code: 'Code',
              descriptionEs: 'Collection Description - Spanish',
              descriptionEn: 'Collection Description - English',
              writeHere: 'Type here'
            },
            options: {
              one: 'Classic NFT',
              two: 'Double NFT',
              three: 'Classic NFT intervened',
              four: 'Double NFT intervened'
            }
          },

          footer: {
            buttons: {
              readMore: 'READ MORE',
              join: 'JOIN'
            },
            labels: {
              stepsTitle1: 'Create your own NFT',
              stepsTitle1Explained: 'Now you can create your NFT from the concept of the artist you choose in a simple way, using that image that you want to treasure forever and that will be available in borders.io so that the world can visualize it.',
              stepsTitle2: 'Intervene the concept of the artist',
              stepsTitle2Explained: 'edges.io gives you the unique possibility to take the concept of an artist\'s work and intervene it. You can use an image and a design of yours to create your own work in NFT.',
              step1: 'Step1',
              step1Explained: 'Choose the design, shape and color of the canvas and the QR points',
              step2: 'Step2',
              step2Explained: 'Upload the image you want to treasure and keep forever',
              step3: 'Step3',
              step3Explained: 'Add the name, description, features and price',
              step4: 'Step 4',
              step4Explained: 'Create (mint) your NFT and it will be available to everyone',
              about: 'ABOUT',
              aboutText: 'In bordes.io lies a rebellion, the intention to rethink the object market.',
              born: 'HOW WAS THIS PROJECT BORN?',
              bornText: 'If creating is altering the perception of the senses and said alteration inaugurates potential realities, how many perceptual supports can we think, feel, perceive? What is the flow or relationship between the physical-real, the technological-virtual, the real-virtual, the physical-technological?',
              info: 'INFORMATION FOR CREATORS',
              infoText: 'I discovered the advantages of being part of Bordes.',
              support: 'Support us'
            }
          },

          topmenu: {
            buttons: {

            },
            labels: {
              join: 'JOIN',
              intervene: 'WHAT IS INTERVENE?',
              dematerialization: 'DEMATERIALIZATION',
              intervention: 'INTERVENTIONS',
              profile: 'PROFILE'
            }
          },

          about: {
            buttons: {

            },
            labels: {
              about: 'About',
              aboutSubtitle: 'In bordes.io lies a rebellion, the intention to rethink the object market.',
              question1: 'What can you do inside bordes.io?',
              answer1: 'Bordes.io is the first NFTs Marketplace where you can create your own piece, you will also find the option to intervene the concept of an artist and you can market it in a few steps.',
              question2: 'What will you find?',
              answer2: 'The platform will integrate artists from all disciplines, designers, photographers, architects, with an innovative idea or project that can become a digital asset.',
              question3: 'How does the technological part work?',
              answer3: 'This works based on smart contracts that allow the creation, management and commercialization of the pieces, as well as allowing the artist to participate in the secondary market.',
              question4: 'About us?',
              answer4: 'We are a small technology company together with a visual artist who dream of uniting art and technology creating a new position that allows us to produce time.',
              description: 'We have the support of the National Fund for the Arts, the cultural areas of the Municipalities of Río Ceballos, Unquillo and Villa Allende.'
            }
          },

          about_bordes: {
            buttons: {
              download: 'DOWNLOAD DOSSIER'
            },
            labels: {
              title: 'About bordes (Project 2015-2022)',
              description1: 'The Borders 2015-2022 project proposes to divert the gaze from the center to accentuate the periphery. This metacognitive and material exploration process started in 2015 rethinks Alain Badiou when he associates Malevich with Mallarmé´s poetry and talks about the staging of the minimal but absolute difference, the difference between the place and what takes place in it.',
              description2: 'This development introduces in 2020 the reasoning about the process of materialization, rematerialization, if it is in the limits where it presents the ability to transform into volatile power, of becoming; it is BETWEEN the relationship with the elemental background where one reflects again on appearing or existing in a different place until materializing again. In 2022 and as a result of the above, bordes.io is born'
            }
          },

          home: {
            buttons: {
              intervene: 'INTERVENE',
              iIntervened: 'I INTERVENED YOURS',
              viewCollections: 'VIEW COLLECTIONS',
              seeAll: 'SEE ALL',
              seeCollection: 'SEE THE WHOLE COLLECTION'
            },
            labels: {
              bordesShort: "Bordes is the first marketplace for NFTs created on the periphery. The only one where you can intervene an artist's concept to create your own NFT.",
              dematerialization: {
                title: 'COLLETION DESMATERIALIZACION',
                titleExplained: 'If a transformation from the absence of matter is possible, Bordes (project 2015-2022) after rethinking the materialization-rematerialization process, accentuates dematerialization, conceptualizing it as the movement of disappearance of the work as an object. In this sense, he activates the process of the image itself to rethink the “at times”, the BETWEEN the physical-material, the possible, the imaginary and the virtual.',
                subtitle: 'MATERIALIZE - RE MATERIALIZE',
                subtitleExplained: 'How to do this? With your phone\'s camera, scan the first image and view the second image.'
              },
              intervention: {
                title: 'Based on the concept of the Dematerialization collection, be encouraged to create your NFT...'
              },
              findOut: 'Find out more about Bordes'
            }

          },

          creators: {
            buttons: {

            },
            labels: {
              title: 'INFORMATION FOR CREATORS'
            }
          },

          formContact: {
            buttons: {
              send: 'Send information'
            },
            labels: {
              title: 'I discovered the advantages of being part of bordes.io',
              titleOne: 'You will participate in the secondary market with your works',
              textOne: 'In that sense you will receive a percentage of money every time it is placed in the market. For example: if your piece triples its value in 2 years, the percentage you will receive for the sale will be related to this new price.',
              titleTwo: 'Commercialization',
              textTwo: 'You will market your works in an agile, transparent way, with clear rules and at a low cost.',
              titleThree: 'You will increase visibility and opportunities',
              textThree: 'With bordes.io the geographical limits are transcended, people from all over the world will be able to see your proposal.',
              titleFour: 'I grew up in community',
              textFour: 'You will have the opportunity to be part of a community that brings together art, NFTs and cryptocurrencies.',
              titleFive: 'A look into the future',
              textFive: 'You will lay the foundations to enter the future market of the metaverses, which will have NFTs as the main players.'
            },
            form: {
              title: 'Contact us',
              description: 'Complete the form with your data and send us a PDF file of no more than 3 MB that contains up to 10 files with your proposal, within it you can include reference links, images, texts, etc.',
              name: 'Name',
              nameError: 'You must enter the name',
              writeHere: 'Type here',
              surnames: 'Surnames',
              surnamesError: 'You must enter the last name',
              country: 'Country',
              countryError: 'You must enter the country',
              city: 'City',
              cityError: 'You must enter the city',
              file: 'Upload file (pdf format)',
              synthesis: 'Synthesis of training and trajectory',
              synthesisError: 'You must enter the synthesis of training and trajectory',
              link: 'Link of your work or social network',
              linkError: 'You must enter the link of your work or social network',
              phone: 'Phone',
              phoneError: 'You must enter the phone'
            },
            alerts: {
              doc: 'You must upload a file',
              success: 'Thank you for submitting your information. We will contact you soon.'
            }
          },

          nftTemplate: {
            buttons: {

            },
            labels: {
              title: 'CREATE PRODUCTION',
              subtitle: 'Upload the following images to create your production',
              image: 'Image/Photo',
              preview: 'PREVIEW',
            },
            form: {
              name: 'Production name',
              description: 'Production Description',
              writeHere: 'Type here',
              price: 'Sale price',
              estimated: 'ESTIMATED GAS'
            }
          },

          nftClassic: {
            buttons: {
              sign: 'sign',
              mint: 'mint',
              close: 'Close'
            },
            labels: {
              title: 'CREATE PRODUCTION',
              file: 'Upload the source file',
              image: 'Upload Image',
              target: 'Upload the target file',
              transaction: 'Transaction'
            },
            alerts: {
              signed: 'Signed production',
              source: 'You must enter the source file',
              destination: 'You must enter the destination file',
              name: 'You must enter the name',
              descriptionEs: 'You must enter the description in Spanish',
              descriptionEn: 'You must enter the description in English',
              tags: 'You must enter the tags',
              price: 'You must enter the price',
              update: 'Updated production',
              create: 'Production created',
              validation: 'Starting signature validation...',
              validateSign: 'Validating signature...',
              validated: 'Validated signature',
              mint: 'Starting mining...',
              minting: 'Mining...',
              mintProduction: 'Minted production',
              updateMint: 'Updating minted production',
              updateMintFinish: 'Updated minted production',
              market: 'Preparing production to add to marketplace...',
              addMarket: 'Adding production to marketplace...',
              addedMarket: 'Production added to marketplace',
              updateMarket: 'Updating production added to marketplace...',
              addedUpdateMarket: 'Production added to updated marketplace',
              transactionSend: 'Transaction sent',
              transactionConfirm: 'Confirmed transaction',
              collectionCreated: 'Collection created successfully'
            },
            form: {
              name: 'Production name',
              code: 'Source image url code',
              code2: 'Target image url code',
              descriptionEs: 'Production Description - Spanish',
              fee: 'Fee (percentage, decimals separated by dot)',
              writeHere: 'Type here',
              descriptionEn: 'Production Description - English',
              price: 'Sale price'
            }
          },

          nftDouble: {
            buttons: {
              sign: 'sign',
              mint: 'mint',
              close: 'Close'
            },
            labels: {
              title: 'CREATE PRODUCTION',
              file: 'Upload the source file',
              image: 'Upload Image',
              target: 'Upload the target file',
              transaction: 'Transaction'
            },
            alerts: {
              signed: 'Signed production',
              source: 'You must enter the source file',
              destination: 'You must enter the destination file',
              name: 'You must enter the name',
              descriptionEs: 'You must enter the description in Spanish',
              descriptionEn: 'You must enter the description in English',
              tags: 'You must enter the tags',
              price: 'You must enter the price',
              update: 'Updated production',
              create: 'Production created',
              validation: 'Starting signature validation...',
              validateSign: 'Validating signature...',
              validated: 'Validated signature',
              mint: 'Starting mining...',
              minting: 'Mining...',
              mintProduction: 'Minted production',
              updateMint: 'Updating minted production',
              updateMintFinish: 'Updated minted production',
              market: 'Preparing production to add to marketplace...',
              addMarket: 'Adding production to marketplace...',
              addedMarket: 'Production added to marketplace',
              updateMarket: 'Updating production added to marketplace...',
              addedUpdateMarket: 'Production added to updated marketplace',
              transactionSend: 'Transaction sent',
              transactionConfirm: 'Confirmed transaction',
              collectionCreated: 'Collection created successfully'
            },
            form: {
              name: 'Production name',
              code: 'Source image url code',
              code2: 'Target image url code',
              descriptionEs: 'Production Description - Spanish',
              fee: 'Fee (percentage, decimals separated by dot)',
              writeHere: 'Type here',
              descriptionEn: 'Production Description - English',
              price: 'Sale price'
            }
          },

          nftIntervention: {
            buttons: {

            },
            labels: {
              title: 'PART INTERVENTION',
              customize: 'Customize Canvas and QR',
              canvas: 'Canvas prints',
              available: 'Available',
              shapes: 'Shapes',
              photo: 'Photo customization',
              zoom: 'Zoom, Opacity and Brightness',
              upload: 'Upload Image',
              opacity: 'Opacity',
              brightness: 'Brightness'
            },
            alerts: {
              login: 'You must log in with your wallet',
              image: 'You must upload the image',
              mintProduction: 'Minted production',
              transactionConfirm: 'Confirmed transaction',
            },
            form: {
              descriptionEs: 'Production Description - Spanish',
              descriptionEn: 'Production Description - English',
              fee: 'Fee (percentage, decimals separated by dot)',
              name: 'Production name',
              price: 'Sale price',
              writeHere: 'Type here',
            }
          },

          nftItem: {
            buttons: {
              sell: 'Sell'
            },
            labels: {
              creator: 'CREATOR',
              owner: 'OWNER',
              price: 'Price'
            }
          },

          nftShop: {
            buttons: {
              offer: 'BID'
            },
            labels: {
              offer: 'Make your offer!',
              price: 'Price',
              expiration: 'Offer Expiration',
              day: 'Day',
              months: 'Months',
              hour: 'Hour',
              market: 'Add your work to the Marketplace!',
              addWork: 'Do you want to add your work?',
              owner: 'Owner',
              actual: 'ACTUAL PRICE',
              creator: 'Creator',
              description: 'Description',
              detail: 'Detail'
            },
            alerts: {
              buy: 'Buying production...',
              purchased: 'Purchased production',
              updateProd: 'Updated production',
              process: 'Production in process of purchase',
              transactionConfirm: 'Confirmed transaction'
            }
          },

          user: {
            buttons: {
              reject: 'REJECT',
              close: 'CLOSE'
            },
            labels: {
              accept: 'Accept the offer',
              provider: 'Provider',
              rate: 'Rate',
              earnings: 'Total earnings',
              dataUpdate: 'Updated data',
              name: 'Enter the first and last name',
              names: 'Names',
              namesPlace: 'Enter your names',
              lastNames: 'Surnames',
              lastNamesPlace: 'Enter your last names',
              updateImage: 'Updated image',
              edited: 'This NFT cannot be edited',
              withdrawals: 'Withdrawals',
              createCollection: '+ Create a new collection',
              myNft: 'My NFTs',
              sales: 'Sales',
              collections: 'My collections'
            }
          }
        }
      }
      ,
      es: {
        translation: {
          buttons: {
            back: 'VOLVER',
            save: 'GUARDAR',
            cancel: 'cancelar',
            accept: 'ACEPTAR',
            create: 'CREAR',
            buy: 'COMPRAR'
          },
          labels: {
            hype1: '¿Sabías que Bordes es un proyecto que nace en 2012 con la idea de desviar la mirada hacia la periferia, hacia el límite del espacio?',
            hype2: 'La materialidad desborda imperiosamente para convertirse en borde...esa mirada que se desvía para ser nuevamente repensada...',
            preview: 'previsualización',
            dematerialization: 'DESMATERIALIZACIÓN'
          },

          dematerialization: {
            buttons: {

            },
            labels: {
              description: 'Si es posible una transformación desde la ausencia de la materia, Bordes (proyecto 2015-2022) después de repensar el proceso de materialización-re materialización, acentúa la desmaterialización, conceptualizándola como el movimiento de desaparición de la obra como objeto. En ese sentido activa el proceso de la imagen en sí misma para volver a repensar el “por momentos”, el ENTRE lo físico-materico, lo posible, lo imaginario y lo virtual.'
            }
          },

          collection: {
            buttons: {
              interventions: {
                intervene: 'INTERVENI TU NFT',
                wantToKnow: 'QUIERO SABER SOBRE EL PROCESO DE CREACION',
                createIntervention: 'CREAR INTERVENCIÓN'
              }
            },
            labels: {
              interventions: {
                proposal: 'ANIMATE A CREAR',
                proposalExplained: 'Diseñá a partir del concepto del artista y seleccionándo una imágen que desees atesorar crea tu propio NFT',
              }
            }
          },

          collection_item: {
            buttons: {

            },
            labels: {
              title: 'CREAR COLECCIÓN',
              optionTitle: 'Escoja su tipo de colección',
              nameCollection: 'Nombre de la colección',
              code: 'Código',
              descriptionEs: 'Descripción de la colección - Español',
              descriptionEn: 'Descripción de la colección - Inglés',
              writeHere: 'Escriba aquí'
            },
            options: {
              one: 'NFT clasico',
              two: 'NFT doble',
              three: 'NFT clasico intervenido',
              four: 'NFT doble intervenido'
            }
          },

          footer: {
            buttons: {
              readMore: 'LEER MAS',
              join: 'SUMATE'
            },
            labels: {
              stepsTitle1: 'Crea tu propio NFT',
              stepsTitle1Explained: 'Ahora puedes crear tu NFT a partir del concepto del artista que elijas de manera simple, utilizando esa imágen que deseas atesorar para siempre y que quedará disponible en bordes.io para que el mundo pueda visualizarlo.',
              stepsTitle2: 'Interviene el concepto del artista',
              stepsTitle2Explained: 'bordes.io te da la posibilidad única de tomar el concepto de la obra de un artista e intervenirlo. Podrás utilizar una imagen y un diseño tuyo para crear tu propia obra en NFT.',
              step1: 'Paso 1',
              step1Explained: 'Elige el diseño, forma y color del lienzo y de los puntos del QR',
              step2: 'Paso 2',
              step2Explained: 'Sube la imagen que deseas atesorar y guardar para siempre',
              step3: 'Paso 3',
              step3Explained: 'Agrega el nombre, descripción, características y precio',
              step4: 'Paso 4',
              step4Explained: 'Crea (mintea) tu NFT y quedara disponible para todo el mundo',
              about: 'SOBRE',
              aboutText: 'En bordes.io subyace una rebelión, la intención de volver a repensar el mercado de los objetos.',
              born: '¿CÓMO NACE ESTE PROYECTO?',
              bornText: 'Si crear es alterar la percepción de los sentidos y dicha alteración inaugura realidades en potencia ¿Cuantos soportes perceptivos podemos pensar, sentir, percibir? ¿Cuál es el flujo o la relación entre lo físico-real, lo tecnológico-virtual, lo real-virtual, lo físico-tecnológico?',
              info: 'INFORMACIÓN PARA CREADORES',
              infoText: 'Descubrí las ventajas de ser parte de Bordes.',
              support: 'Nos apoyan'
            }
          },

          topmenu: {
            buttons: {

            },
            labels: {
              join: 'SUMATE',
              intervene: '¿QUÉ ES INTERVENIR?',
              dematerialization: 'DESMATERIALIZACIÓN',
              intervention: 'INTERVENCIONES',
              profile: 'PERFIL'
            }
          },

          about: {
            buttons: {

            },
            labels: {
              about: 'Sobre',
              aboutSubtitle: 'En bordes.io subyace una rebelión, la intención de volver a repensar el mercado de los objetos.',
              question1: '¿Qué puedes hacer dentro de bordes.io?',
              answer1: 'Bordes.io es el primer Marketplace de NFTs donde puedes crear tu propia pieza, también encontrarás la opción de intervenir el concepto de un artista y podrás comercializarla en pocos pasos.',
              question2: '¿Qué vas a encontrar?',
              answer2: 'La plataforma integrará a artistas de todas las disciplinas, diseñadores, fotógrafos, arquitectos, con una idea o proyecto innovador que pueda convertirse en un activo digital.',
              question3: '¿Cómo funciona la parte tecnológica?',
              answer3: 'Esto funciona a partir de contratos inteligentes que permiten la creación, gestión y comercialización de las piezas, además posibilitará al artista la participación en el mercado secundario.',
              question4: '¿Quiénes somos?',
              answer4: 'Somos una pequeña empresa de tecnología junto a una artista visual que soñamos con unir el arte y la tecnología haciendo existir una nueva posición que nos posibilite producir el tiempo.',
              description: 'Contamos con el apoyo del Fondo Nacional de las Artes, las áreas de cultura de las Municipalidades de Río Ceballos, Unquillo y Villa Allende.'
            }
          },

          about_bordes: {
            buttons: {
              download: 'DESCARGAR DOSSIER'
            },
            labels: {
              title: 'Sobre bordes (Proyecto 2015-2022)',
              description1: 'El proyecto Bordes 2015-2022 propone desviar la mirada del centro para acentuar la periferia. Este proceso de exploración metacognitivo y matérico iniciado en 2015 repiensa a Alain Badiou cuando asocia a Malevich con la poesía de Mallarmé y habla sobre la puesta en escena de la diferencia mínima, pero absoluta, la diferencia entre el lugar y lo que tiene lugar en él.',
              description2: 'Este desarrollo introduce en 2020 el razonamiento sobre el proceso de materialización, rematerialización, si es en los límites en donde presenta la capacidad de transformarse en potencia volátil, de devenir; es ENTRE la relación con el fondo elemental en donde se vuelve a reflexionar sobre el aparecer o existir en un lugar diferente hasta el volver a materializar. En 2022 y como resultado de lo anterior nace bordes.io'
            }
          },

          home: {
            buttons: {
              intervene: 'INTERVENI',
              iIntervened: 'INTERVENÍ EL TUYO',
              viewCollections: 'VER COLECCIONES',
              seeAll: 'VER TODOS',
              seeCollection: 'VER TODA LA COLECCIÓN'
            },
            labels: {
              bordesShort: 'Bordes es el primer marketplace de NFTs creados en la periferia. El único donde podes intervenir el concepto de un artista para crear tu propio NFT.',
              dematerialization: {
                title: 'COLECCIÓN DESMATERIALIZACIÓN',
                titleExplained: 'Si es posible una transformación desde la ausencia de la materia, Bordes (proyecto 2015-2022) después de repensar el proceso de materialización-re materialización, acentúa la desmaterialización, conceptualizándola como el movimiento de desaparición de la obra como objeto. En ese sentido activa el proceso de la imagen en sí misma para volver a repensar el “por momentos”, el ENTRE lo físico-materico, lo posible, lo imaginario y lo virtual.',
                subtitle: 'MATERIALIZA - RE MATERIALIZA',
                subtitleExplained: '¿Cómo hacer esto? Con la cámara de tu teléfono escaneá la primera imágen y visualizá la segunda imagen.'
              },
              intervention: {
                title: 'Basado en el concepto de la coleccion Desmaterialización, animate a crear tu NFT...'
              },
              findOut: 'Descubre más sobre Bordes'
            }
          },

          creators: {
            buttons: {

            },
            labels: {
              title: 'INFORMACIÓN PARA CREADORES'
            }
          },

          formContact: {
            buttons: {
              send: 'Enviar información'
            },
            labels: {
              title: 'Descubrí las ventajas de ser parte de bordes.io',
              titleOne: 'Participarás  en el mercado secundario con tus obras',
              textOne: 'En ese sentido recibirás un porcentaje de dinero todas las veces que sea colocada en el mercado. Por ejemplo: si tu pieza en 2 años triplica su valor, el porcentaje que recibirás por la venta estará relacionado con este nuevo precio.',
              titleTwo: 'Comercialización',
              textTwo: 'Comercializarás tus obras de una manera ágil, transparente, con reglas claras y a bajo costo.',
              titleThree: 'Incrementarás la visibilidad y oportunidades',
              textThree: 'Con bordes.io los límites geográficos se trascienden, personas de todo el mundo podrán mirar tu propuesta.',
              titleFour: 'Crecé en comunidad',
              textFour: 'Tendrás la posibilidad de ser parte de una comunidad que nuclea el arte, los NFTs y las criptomonedas.',
              titleFive: 'Una mirada al futuro',
              textFive: 'Sentarás las bases para ingresar al mercado futuro de los metaversos, que tendrán como principales actores a los NFTs.'
            },
            form: {
              title: 'Contáctanos',
              description: 'Completa el formulario con tus datos y envíanos un archivo PDF de no más de 3 MB que contenga hasta 10 archivos con tu propuesta, dentro del mismo podés incorporar links de referencia, imágenes, textos, etc.',
              name: 'Nombre',
              nameError: 'Debe ingresar el nombre',
              writeHere: 'Escriba aquí',
              surnames: 'Apellidos',
              surnamesError: 'Debe ingresar el apellido',
              country: 'País',
              countryError: 'Debe ingresar el país',
              city: 'Ciudad',
              cityError: 'Debe ingresar la ciudad',
              file: 'Subir archivo (formato pdf)',
              synthesis: 'Síntesis de formación y trayectoria',
              synthesisError: 'Debe ingresar la síntesis de formación y trayectoria',
              link: 'Link de su trabajo o red social',
              linkError: 'Debe ingresar el link de su trabajo o red social',
              phone: 'Teléfono',
              phoneError: 'Debe ingresar el teléfono'
            },
            alerts: {
              doc: 'Debe subir un archivo',
              success: 'Gracias por enviar su información. Nos contactaremos pronto con Ud.'
            }
          },

          nftTemplate: {
            buttons: {

            },
            labels: {
              title: 'CREAR PRODUCCIÓN',
              subtitle: 'Suba las siguientes imágenes para crear su producción',
              image: 'Imágen/Foto',
              preview: 'PREVISUALIZACIÓN'
            },
            form: {
              name: 'Nombre de la producción',
              description: 'Descripción de la producción',
              writeHere: 'Escriba aquí',
              price: 'Precio de venta',
              estimated: 'GAS ESTIMADO'
            }
          },

          nftDouble: {
            buttons: {
              sign: 'firmar',
              mint: 'mintear',
              close: 'Cerrar'
            },
            labels: {
              title: 'CREAR PRODUCCIÓN',
              file: 'Suba el archivo origen',
              image: 'Subir Imágen',
              taget: 'Suba el archivo destino',
              transaction: 'Transacción'
            },
            alerts: {
              signed: 'Producción firmada',
              source: 'Debe ingresar el archivo origen',
              destination: 'Debe ingresar el archivo destino',
              name: 'Debe ingresar el nombre',
              descriptionEs: 'Debe ingresar la descripción en español',
              descriptionEn: 'Debe ingresar la descripcion en inglés',
              tags: 'Debe ingresar los tags',
              price: 'Debe ingresar el precio',
              update: 'Producción actualizada',
              create: 'Producción creada',
              validation: 'Iniciando validación de firma...',
              validateSign: 'Validando firma...',
              validated: 'Firma validada',
              mint: 'Iniciando minteo...',
              minting: 'Minteando...',
              mintProduction: 'Producción minteada',
              updateMint: 'Actualizando producción minteada',
              updateMintFinish: 'Producción minteada actualizada',
              market: 'Preparando producción para agregar a marketplace...',
              addMarket: 'Agregando producción a marketplace...',
              addedMarket: 'Producción agregada a marketplace',
              updateMarket: 'Actualizando producción añadida a marketplace...',
              addedUpdateMarket: 'Producción agregada a marketplace actualizada',
              transactionSend: 'Transacción enviada',
              transactionConfirm: 'Transacción confirmada',
              collectionCreated: 'Colección creada exitosamente'
            },
            form: {
              name: 'Nombre de la producción',
              code: 'Código url de imágen origen',
              code2: 'Código url de imágen destino',
              descriptionEs: 'Descripción de la producción - Español',
              fee: 'Fee (porcentaje, decimales separados por punto)',
              writeHere: 'Escriba aquí',
              descriptionEn: 'Descripción de la producción - Inglés',
              price: 'Precio de venta'
            }
          },

          nftIntervention: {
            buttons: {

            },
            labels: {
              title: 'INTERVENCIÓN DE PIEZA',
              customize: 'Customizar Lienzo y QR',
              canvas: 'Lienzos',
              available: 'Disponibles',
              shapes: 'Formas',
              photo: 'Customización de foto',
              zoom: 'Zoom, Opacidad y Brillo',
              upload: 'Subir Imágen',
              opacity: 'Opacidad',
              brightness: 'Brillo'
            },
            alerts: {
              login: 'Debe loguearse con su billetera',
              image: 'Debe subir la imágen',
              mintProduction: 'Producción minteada',
              transactionConfirm: 'Transacción confirmada',
            },
            form: {
              descriptionEn: 'Descripción de la producción - Inglés',
              descriptionEs: 'Descripción de la producción - Español',
              fee: 'Fee (porcentaje, decimales separados por punto)',
              name: 'Nombre de la producción',
              price: 'Precio de venta',
              writeHere: 'Escriba aquí',
            }
          },

          nftItem: {
            buttons: {
              sell: 'Vender'
            },
            labels: {
              creator: 'Creador',
              owner: 'Propietario',
              price: 'Precio'
            }
          },

          nftShop: {
            buttons: {
              offer: 'OFERTAR'
            },
            labels: {
              offer: 'Haga su oferta!',
              price: 'Precio',
              expiration: 'Expiración de la oferta',
              day: 'Día',
              months: 'Meses',
              hour: 'Hora',
              market: 'Agrega tu obra al Marketplace!',
              addWork: '¿Desea agregar su obra?',
              owner: 'Propietario',
              actual: 'PRECIO ACTUAL',
              creator: 'Creador',
              description: 'Descripción',
              detail: 'Detalle'
            },
            alerts: {
              buy: 'Comprando producción...',
              purchased: 'Producción comprada',
              updateProd: 'Producción actualizada',
              process: 'Producción en proceso de compra',
              transactionConfirm: 'Transacción confirmada'
            }
          },

          user: {
            buttons: {
              reject: 'RECHAZAR',
              close: 'CERRAR'
            },
            labels: {
              accept: 'Aceptar la oferta',
              provider: 'Ofrecedor',
              rate: 'Tarifa',
              earnings: 'Ganancias totales',
              dataUpdate: 'Datos actualizados',
              name: 'Ingrese el nombre y apellido',
              names: 'Nombres',
              namesPlace: 'Ingrese sus nombres',
              lastNames: 'Apellidos',
              lastNamesPlace: 'Ingrese sus apellidos',
              updateImage: 'Imágen actualizada',
              edited: 'No se puede editar este NFT',
              withdrawals: 'Retirar dinero',
              createCollection: '+ Crear una nueva colección',
              myNft: 'Mis NFTs',
              sales: 'Ventas',
              collections: 'Mis colecciones'
            }
          }
        }
      }

    }
  });

export default i18n;
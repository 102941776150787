import React, { useContext, useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Form, Button, Spinner } from 'react-bootstrap';
import './FormContact.css';
import Loader from './Loader';
import { useForm } from "react-hook-form";
import PageService from '../../core/PageService';
import AppGlobal from '../../context/AppGlobal';
import AppSettings from '../../AppSettings';
import { useTranslation } from 'react-i18next';
// - ICONS -
import { BsDot } from "react-icons/bs"


const FormContact = () => {

    const { t } = useTranslation();
    const { global, addGlobal } = useContext(AppGlobal);
    const pageService = new PageService({
        appGlobal:{
            global: global,
            addGlobal: addGlobal
        }
    });


    // (+) Form
    const [loading, setLoading] = useState(false);

    const { register, handleSubmit, reset, formState, formState: { errors, isSubmitSuccessful } } = useForm();

    const onSubmit = data => {
        if(!file || !file.file) {
            pageService.showError(t('formContact.alerts.doc'));
            return;
        }
        
        const contact = {
            ...data,
            file: file.file
        }
        console.log(contact);

        pageService.httpPost({
            endPoint: pageService.settings.api.endPoints.contacts,
            values: contact
        }).then( result => {
            pageService.showSuccess(t('formContact.alerts.success'));
            reset();
        })
        .catch( error => {
            pageService.showError(error);
        })
        .finally(() => pageService.hideLoading());


        // setLoading(true);
    };

    // useEffect(() => {
    //     if (formState.isSubmitSuccessful && file && file.file) reset();
    // }, [formState, reset]);

    // (-) Form

    const [file, setFile] = useState();
    const handleFile = (e) => {

        const file = e.target.files[0];
        if (!file) return;

        pageService.showLoading();
        pageService.httpPostFormData({
            endPoint: pageService.settings.api.endPoints.filesUpload,
            formData: {
                file: file
            }
        }).then( result => {
            if (result.data.file) {
                setFile(result.data);
            }
        })
        .catch( error => {
            pageService.showError(error);
        })
        .finally(() => pageService.hideLoading());
    
    }


    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <Container className='pb-5 ps-4 pe-4'>

            <div className='w-75 m-auto'>
                <Row className='mt-5'>
                    <h1 className='title text-uppercase'>{t('formContact.labels.title')}</h1>
                </Row>

                <Row className="justify-content-center align-items-center mt-5">
                    <h5 className='bordes text-ashes'>  <BsDot />{t('formContact.labels.titleOne')}</h5>
                    <p className='text-ashes custom-pl'>{t('formContact.labels.textOne')}</p>
                </Row>
                <Row className="justify-content-center align-items-center mt-2">
                    <h5 className='bordes text-ashes'>  <BsDot />{t('formContact.labels.titleTwo')}</h5>
                    <p className='text-ashes custom-pl'>{t('formContact.labels.textTwo')}</p>
                </Row>
                <Row className="justify-content-center align-items-center mt-2">
                    <h5 className='bordes text-ashes'>  <BsDot />{t('formContact.labels.titleThree')}</h5>
                    <p className='text-ashes custom-pl'>{t('formContact.labels.textThree')}</p>
                </Row>
                <Row className="justify-content-center align-items-center mt-2">
                    <h5 className='bordes text-ashes'>  <BsDot />{t('formContact.labels.titleFour')}</h5>
                    <p className='text-ashes custom-pl'>{t('formContact.labels.textFour')}</p>
                </Row>
                <Row className="justify-content-center align-items-center mt-2">
                    <h5 className='bordes text-ashes'>  <BsDot />{t('formContact.labels.titleFive')}</h5>
                    <p className='text-ashes custom-pl'>{t('formContact.labels.textFive')}</p>
                </Row>

                <Row className="justify-content-center align-items-center mt-5">
                    <Form>
                        <Row>
                            <h2 className='subtitle'>{t('formContact.form.title')}</h2>
                            <p className='text-ashes'>{t('formContact.form.description')}</p>
                        </Row>
                        {/* nombres, apellidos, pais, ciudad, archivo, texto libre, link con su trabajo, telefono */}
                        <Row className="justify-content-center align-items-center mt-5 ">
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>{t('formContact.form.name')}</Form.Label>
                                <Form.Control type="text" placeholder={t('formContact.form.writeHere')} {...register("firstName", { required: true })} />
                                {errors.firstName &&
                                    <Form.Text className="text-danger">
                                        {t('formContact.form.nameError')}
                                    </Form.Text>
                                }

                            </Form.Group>

                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>{t('formContact.form.surnames')}</Form.Label>
                                <Form.Control type="text" placeholder={t('formContact.form.writeHere')} {...register("lastName", { required: true })} />
                                {errors.lastName &&
                                    <Form.Text className="text-danger">
                                       {t('formContact.form.surnamesError')}
                                    </Form.Text>
                                }
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>{t('formContact.form.country')}</Form.Label>
                                <Form.Control type="text" placeholder={t('formContact.form.writeHere')} {...register("country", { required: true })} />
                                {errors.country &&
                                    <Form.Text className="text-danger">
                                        {t('formContact.form.countryError')}
                                    </Form.Text>
                                }
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>{t('formContact.form.city')}</Form.Label>
                                <Form.Control type="text" placeholder={t('formContact.form.writeHere')}{...register("city", { required: true })} />
                                {errors.city &&
                                    <Form.Text className="text-danger">
                                        {t('formContact.form.cityError')}
                                    </Form.Text>
                                }
                            </Form.Group>

                            <Form.Group controlId="formFile" className="mb-3">
                                <Form.Label>{t('formContact.form.file')}</Form.Label>
                                <Form.Control type="file" onChange={handleFile}/>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                <Form.Label>{t('formContact.form.synthesis')}</Form.Label>
                                <Form.Control as="textarea" rows={3} placeholder={t('formContact.form.writeHere')} {...register("history", { required: true })} />
                                {errors.history &&
                                    <Form.Text className="text-danger">
                                        {t('formContact.form.synthesisError')}
                                    </Form.Text>
                                }
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>{t('formContact.form.link')}</Form.Label>
                                <Form.Control type="text" placeholder="Ejemplo: https://ceciliapicca.com.ar"  {...register("link", { required: true })}/>
                                {errors.link &&
                                    <Form.Text className="text-danger">
                                        {t('formContact.form.linkError')}
                                    </Form.Text>
                                }
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>{t('formContact.form.phone')}</Form.Label>
                                <Form.Control type="number" placeholder={t('formContact.form.writeHere')}  {...register("phone", { required: true })} />
                                {errors.phone &&
                                    <Form.Text className="text-danger">
                                        {t('formContact.form.phoneError')}
                                    </Form.Text>
                                }
                            </Form.Group>
                        </Row>
                        <Row>
                            <Button className='w-100 collection mt-2 custom-m'  disabled={loading} onClick={handleSubmit(onSubmit)}>
                                
                                {loading &&
                                    <Spinner animation="border" size="sm" variant="light" role="status"></Spinner>
                                }
                                {!loading &&
                                    <>{t('formContact.buttons.send')}</>
                                }

                            </Button>
                        </Row>

                    </Form>
                </Row>
            </div>
        </Container>
    );
}

export default FormContact;
import { createSlice } from '@reduxjs/toolkit'

export const languageSlice = createSlice({
  name: 'language',
  initialState: {
    value: 'es',
  },
  reducers: {
    setLanguage: (state, action) => {
      state.value = action.payload;
      console.log(`setLanguage: ${state.value}`);
    },
  },
})

// Action creators are generated for each case reducer function
export const { setLanguage } = languageSlice.actions

export default languageSlice.reducer
import 'bootstrap/dist/css/bootstrap.min.css';
import './Home.css';
import { Row, Col, Placeholder, Container } from 'react-bootstrap';

const Loader = () => {
    return (
        <Container>
            <Row className='mt-5'>
                <Col xs={12} md={6}>
                    <Placeholder as="p" animation="glow">
                        <Placeholder xs={6} size='lg' />
                    </Placeholder>

                    <Placeholder as="p" animation="glow">
                        <Placeholder xs={8} size='md' />
                    </Placeholder>

                    <Placeholder as="p" animation="glow" className='header-placeholder'>
                        <Placeholder.Button variant='medium' className='mt-2' xs={5} />
                        <Placeholder.Button variant='medium' className='mt-2' xs={5} />
                    </Placeholder>

                    <Placeholder as="p" animation="glow">
                        <Placeholder xs={6} size='lg' />
                    </Placeholder>
                </Col>
                <Col xs={12} md={6}>
                    <Placeholder animation="glow">
                        <Placeholder className='img-placeholder' xs={12} size="lg" />
                    </Placeholder>
                </Col>
            </Row>

            <Row className='mt-5'>
                <Col xs={12} md={6}>
                    <Placeholder as="p" animation="glow">
                        <Placeholder xs={8} size='lg' />
                    </Placeholder>

                    <Placeholder as="p" animation="glow">
                        <Placeholder xs={12} size='md' />
                    </Placeholder>

                    <Placeholder as="p" animation="glow" className='header-placeholder'>
                        <Placeholder.Button variant='medium' className='mt-2' xs={4} />
                    </Placeholder>
                </Col>
                <Col xs={12} md={6}>
                    <Placeholder as="p" animation="glow">
                        <Placeholder xs={8} size='lg' />
                    </Placeholder>

                    <Placeholder as="p" animation="glow">
                        <Placeholder xs={12} size='md' />
                    </Placeholder>

                    <Placeholder as="p" animation="glow" className='header-placeholder'>
                        <Placeholder.Button variant='medium' className='mt-2' xs={5} />
                        <Placeholder.Button variant='medium' className='mt-2' xs={5} />
                    </Placeholder>
                </Col>
            </Row>

            <Row className='mt-5'>
                <Col xs={12} md={6}>
                    <Placeholder animation="glow">
                        <Placeholder className='img-placeholder' xs={8} size="lg" />
                    </Placeholder>

                    <Placeholder as="p" animation="glow" className='header-placeholder info-shop mt-2'>
                        <Placeholder xs={3} size='md' />
                        <Placeholder xs={3} size='md' />
                    </Placeholder>

                    <Placeholder as="p" animation="glow" className='header-placeholder info-shop'>
                        <Placeholder xs={3} size='md' />
                        <Placeholder xs={3} size='md' />
                    </Placeholder>

                    <Placeholder as="p" animation="glow">
                        <Placeholder xs={2} size="xs" />
                    </Placeholder>
                </Col>
                <Col xs={12} md={6}>
                    <Placeholder animation="glow">
                        <Placeholder className='img-placeholder' xs={8} size="lg" />
                    </Placeholder>

                    <Placeholder as="p" animation="glow" className='header-placeholder info-shop mt-2'>
                        <Placeholder xs={3} size='md' />
                        <Placeholder xs={3} size='md' />
                    </Placeholder>

                    <Placeholder as="p" animation="glow" className='header-placeholder info-shop'>
                        <Placeholder xs={3} size='md' />
                        <Placeholder xs={3} size='md' />
                    </Placeholder>

                    <Placeholder as="p" animation="glow">
                        <Placeholder xs={2} size="xs" />
                    </Placeholder>
                </Col>
            </Row>

            <Row className='mt-5'>
                <Col xs={12} md={6}>
                    <Placeholder animation="glow">
                        <Placeholder className='img-placeholder' xs={8} size="lg" />
                    </Placeholder>

                    <Placeholder as="p" animation="glow" className='header-placeholder info-shop mt-2'>
                        <Placeholder xs={3} size='md' />
                        <Placeholder xs={3} size='md' />
                    </Placeholder>

                    <Placeholder as="p" animation="glow" className='header-placeholder info-shop'>
                        <Placeholder xs={3} size='md' />
                        <Placeholder xs={3} size='md' />
                    </Placeholder>

                    <Placeholder as="p" animation="glow">
                        <Placeholder xs={2} size="xs" />
                    </Placeholder>
                </Col>
                <Col xs={12} md={6}>
                    <Placeholder animation="glow">
                        <Placeholder className='img-placeholder' xs={8} size="lg" />
                    </Placeholder>

                    <Placeholder as="p" animation="glow" className='header-placeholder info-shop mt-2'>
                        <Placeholder xs={3} size='md' />
                        <Placeholder xs={3} size='md' />
                    </Placeholder>

                    <Placeholder as="p" animation="glow" className='header-placeholder info-shop'>
                        <Placeholder xs={3} size='md' />
                        <Placeholder xs={3} size='md' />
                    </Placeholder>

                    <Placeholder as="p" animation="glow">
                        <Placeholder xs={2} size="xs" />
                    </Placeholder>
                </Col>
            </Row>

            <Row className='mt-5'>
                <Col xs={12} md={6}>
                    <Placeholder animation="glow">
                        <Placeholder className='img-placeholder' xs={8} size="lg" />
                    </Placeholder>

                    <Placeholder as="p" animation="glow" className='header-placeholder info-shop mt-2'>
                        <Placeholder xs={3} size='md' />
                        <Placeholder xs={3} size='md' />
                    </Placeholder>

                    <Placeholder as="p" animation="glow" className='header-placeholder info-shop'>
                        <Placeholder xs={3} size='md' />
                        <Placeholder xs={3} size='md' />
                    </Placeholder>

                    <Placeholder as="p" animation="glow">
                        <Placeholder xs={2} size="xs" />
                    </Placeholder>
                </Col>
                <Col xs={12} md={6}>
                    <Placeholder animation="glow">
                        <Placeholder className='img-placeholder' xs={8} size="lg" />
                    </Placeholder>

                    <Placeholder as="p" animation="glow" className='header-placeholder info-shop mt-2'>
                        <Placeholder xs={3} size='md' />
                        <Placeholder xs={3} size='md' />
                    </Placeholder>

                    <Placeholder as="p" animation="glow" className='header-placeholder info-shop'>
                        <Placeholder xs={3} size='md' />
                        <Placeholder xs={3} size='md' />
                    </Placeholder>

                    <Placeholder as="p" animation="glow">
                        <Placeholder xs={2} size="xs" />
                    </Placeholder>
                </Col>
            </Row>
        </Container>
    );
}

export default Loader;
import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Form, Button, Placeholder, Modal } from 'react-bootstrap';
import Loader from './Loader';
import './NftDouble.css';
import BaseComponent from '../../core/BaseComponent';
import { Navigate } from "react-router-dom";
import { withNavigation, withI18n, withAppGlobal, withBordes, withWeb3React, withUseParams } from '../../core/BaseComponent';
import { MdUpload } from 'react-icons/md';
import { ethers } from "ethers";


// + IMAGES +
import success from '../../assets/gifs/check.gif';
import pending from '../../assets/gifs/pending.gif';
import error from '../../assets/imgs/close.png';
// - IMAGES -



class NftDouble extends BaseComponent {

    constructor(props) {
        super(props);
        this.form = React.createRef();
        this.wrapperOriginRef = React.createRef();
        this.wrapperTargetRef = React.createRef();

        // this.TransactionModal = this.TransactionModal.bind(this);
        // this.handleMintSubmit = this.handleMintSubmit.bind(this);
        this.handleSaveSubmit = this.handleSaveSubmit.bind(this);
        // this.handleSignSubmit = this.handleSignSubmit.bind(this);
        this.handleBack = this.handleBack.bind(this);
        this.handleFileOriginDrop = this.handleFileOriginDrop.bind(this);
        this.handleFileTargetDrop = this.handleFileTargetDrop.bind(this);
        this.handleFileDrop = this.handleFileDrop.bind(this);

        this.onDragOriginEnter = this.onDragOriginEnter.bind(this);
        this.onDragOriginLeave = this.onDragOriginLeave.bind(this);
        this.onDropOrigin = this.onDropOrigin.bind(this);
        this.onDragTargetEnter = this.onDragTargetEnter.bind(this);
        this.onDragTargetLeave = this.onDragTargetLeave.bind(this);
        this.onDropTarget = this.onDropTarget.bind(this);
        this.createLaziNFT = this.createLaziNFT.bind(this);

        // this.handlePriceChange = this.handlePriceChange.bind(this);
        this.handleValueChange = this.handleValueChange.bind(this);
        // this.canSign = this.canSign.bind(this);
        // this.loadItem = this.loadItem.bind(this);
        this.setState({ transactionModalShow: false });
        this.setState({ transactionModalButtonCloseShow: false });
        this.setState({ transactionModalText: '' });

    }

    componentDidMount() {
        let { collectionId, id } = this.props.params;
        console.log(id);
        if (id != 'new') this.loadItem(id);
        else this.setState({ nft: {} });
        this.setState({ transactionModalStatusImg: pending });

        window.scrollTo(0, 0)
    
    }

    handleValueChange(event) {
        let nft = this.state.nft;
        nft[event.target.name] = event.target.value;
        this.setState({ nft });
    }

    loadItem(id) {
        this.pageService.showLoading();
        this.pageService.httpGet({
            endPoint: this.settings.api.endPoints.nfts + '/' + id
        }).then(result => {
            this.setState({
                nft: result
            });
            this.loadItemSuccess(result);
        })
            .catch(error => {
                this.pageService.showError(error);
            })
            .finally(() => this.pageService.hideLoading());
    }

    loadItemSuccess(item) {
        console.log("--------------");
        console.log(item);
        console.log("--------------");

        let params = {};
        params.fileOrigin = {
            file: item.file,
            ipfs: item.ipfs,
            src: this.settings.images.url + '/' + item.file
        };
        // params.fileOrigin.src = this.settings.images.url + '/' + item.file;
        params.fileTarget = {
            file: item.file2,
            ipfs: item.ipfs2,
            src: this.settings.images.url + '/' + item.file2
        };
        // params.fileTarget.src = this.settings.images.url + '/' + item.file2;
        this.setState(params);

    }

    onDragOriginEnter() {
        this.wrapperOriginRef.current.classList.add('dragover');
    }

    onDragOriginLeave() {
        this.wrapperOriginRef.current.classList.remove('dragover');
    }

    onDropOrigin() {
        this.wrapperOriginRef.current.classList.remove('dragover');
    }
 
    onDragTargetEnter() {
        this.wrapperTargetRef.current.classList.add('dragover');
    }

    onDragTargetLeave() {
        this.wrapperTargetRef.current.classList.remove('dragover');
    }

    onDropTarget() {
        this.wrapperTargetRef.current.classList.remove('dragover');
    }

    handleFileOriginDrop(e) {
        const file = e.target.files[0];
        if (!file) return;
        this.handleFileDrop('fileOrigin', file);
    }

    handleFileTargetDrop(e) {
        const file = e.target.files[0];
        if (!file) return;
        this.handleFileDrop('fileTarget', file);
    }

    handleFileDrop(key, file) {

        this.pageService.showLoading();
        this.pageService.httpPostFormData({
            endPoint: this.settings.api.endPoints.filesUpload,
            formData: {
                file: file
            }
        }).then(result => {
            console.log(result);
            if (result.data.file) {
                let params = {};
                params[key] = result.data;
                params[key].src = process.env.REACT_APP_FILES_URL + '/' + result.data.file;
                this.setState(params);
            }
        })
            .catch(error => {
                this.pageService.showError(error);
            })
            .finally(() => this.pageService.hideLoading());

    }

    handleBack() {
        this.goBack();
    }

    createLaziNFT(nft) {

        console.log(`nft: ${nft} `);


        const provider = new ethers.providers.Web3Provider(window.ethereum)

        // MetaMask requires requesting permission to connect users accounts
        provider.send("eth_requestAccounts", [])
            .then(async (accounts) => {
                console.log(accounts[0]);

                const signer = provider.getSigner();
                console.log(signer);


                const types = {
                    NFTVoucher: [
                        { name: "tokenId", type: "uint256" },
                        { name: "minPrice", type: "uint256" },
                        { name: "uri", type: "string" },
                        { name: "owner", type: "address" },
                        { name: "commission", type: "uint256" },
                    ]
                };

                const voucher = {
                    tokenId: nft.number,
                    uri: nft.ipfs,
                    owner: accounts[0],
                    commission: this.settings.contracts.bordesLazyNft.commission,
                    minPrice: this.settings.contracts.bordesLazyNft.minPrice
                };

                const chainId = await signer.getChainId();

                const domain = {
                    name: this.settings.contracts.bordesLazyNft.domain,
                    version: this.settings.contracts.bordesLazyNft.version,
                    verifyingContract: this.settings.contracts.bordesLazyNft.address[chainId],
                    chainId: chainId,
                };

                console.log(voucher);

                signer._signTypedData(domain, types, voucher)
                    .then((data) => {
                        // console.log("_signTypedData");
                        // console.log(data);
                        this.pageService.showSuccess(this.t('nftDouble.alerts.signed'));
                    })
                    .catch((e) => console.log(e))
            })
            .catch((e) => console.log(e))
    }


    /////////////////////////////////////////////////////////////////////
    /////// (+) (+) (+) (+) (+) (+) (+) (+) (+) (+) (+) (+) (+)   ///////
    /////////////////////////////////////////////////////////////////////

    checkSaveSubmit() {
        if (!this.state.fileOrigin) return this.t('nftDouble.alerts.source');
        if (!this.state.fileTarget) return this.t('nftDouble.alerts.destination');
        if (!this.state.nft.name) return this.t('nftDouble.alerts.name');
        if (!this.state.nft.code) return this.t('nftDouble.alerts.name');
        if (!this.state.nft.code2) return this.t('nftDouble.alerts.name');
        if (!this.state.nft.description_en) return this.t('nftDouble.alerts.descriptionEs');
        if (!this.state.nft.description_es) return this.t('nftDouble.alerts.descriptionEn');
        if (!this.state.nft.tags) return this.t('nftDouble.alerts.tags');
        if (!this.state.nft.price) return this.t('nftDouble.alerts.price');

        return "";
    }

    handleSaveSubmit(event) {

        event.preventDefault();

        const error = this.checkSaveSubmit();
        if (error != "") {
            this.pageService.showError(error);
            return;
        }

        let { collectionId } = this.props.params;
        let nft = this.state.nft;
        nft.file = this.state.fileOrigin.file;
        nft.file2 = this.state.fileTarget.file;
        // nft.ipfs = this.state.fileOrigin.ipfs;
        // nft.ipfs2 = this.state.fileTarget.ipfs;
        nft.nftCollection = collectionId;
        nft.type = this.settings.collectionTypes.double;
        nft.owner = this.context.global.user.id;
        nft.creator = this.context.global.user.id;

        console.log(nft);

        if (nft.id) {
            this.pageService.showLoading();
            this.pageService.httpPut({
                endPoint: this.settings.api.endPoints.nfts + '/' + nft.id,
                values: nft
            }).then(result => {
                console.log(result.data);
                this.setState({ nft: result.data });
                this.pageService.showSuccess(this.t('nftDouble.alerts.update'));
            })
                .catch(error => {
                    this.pageService.showError(error);
                })
                .finally(() => this.pageService.hideLoading());
        } else {
            this.pageService.showLoading();
            this.pageService.httpPost({
                endPoint: this.settings.api.endPoints.nfts,
                values: nft
            }).then(result => {
                console.log(result.data);
                this.setState({ nft: result.data });
                this.pageService.showSuccess(this.t('nftDouble.alerts.create'));
            })
                .catch(error => {
                    this.pageService.showError(error);
                })
                .finally(() => this.pageService.hideLoading());
        }

    }

    handleSignSubmit = (event) => {
        event.preventDefault();

        const nft = this.state.nft;

        const provider = new ethers.providers.Web3Provider(window.ethereum)

        // MetaMask requires requesting permission to connect users accounts
        provider.send("eth_requestAccounts", [])
            .then(async (accounts) => {

                const signer = provider.getSigner();
                const types = {
                    NFTVoucher: [
                        { name: "tokenId", type: "uint256" },
                        { name: "minPrice", type: "uint256" },
                        { name: "uri", type: "string" },
                        { name: "owner", type: "address" },
                        { name: "fee", type: "uint96" },
                    ]
                };

                const voucher = {
                    tokenId: nft.number,
                    uri: nft.ipfs,
                    owner: accounts[0],
                    fee: Math.round(nft.fee * 100),
                    minPrice: this.settings.contracts.bordesLazyNft.minPrice
                };

                const chainId = await signer.getChainId();

                const domain = {
                    name: this.settings.contracts.bordesLazyNft.domain,
                    version: this.settings.contracts.bordesLazyNft.version,
                    verifyingContract: this.settings.contracts.bordesLazyNft.address[chainId],
                    chainId: chainId,
                };

                signer._signTypedData(domain, types, voucher)
                    .then((signature) => {

                        voucher.signature = signature;

                        this.pageService.showLoading();
                        this.pageService.httpPut({
                            endPoint: this.settings.api.endPoints.nfts + '/' + nft.id,
                            values: {
                                status: this.settings.nfts.status.lazy,
                                voucher: voucher
                            }
                        }).then(result => {
                            this.setState({ nft: result.data });
                            this.pageService.showSuccess(this.t('nftDouble.alerts.signed'));
                        })
                            .catch(error => {
                                this.pageService.showError(error);
                            })
                            .finally(() => this.pageService.hideLoading());


                    })
                    .catch((e) => this.pageService.showError(e))
            })
            .catch((e) => this.pageService.showError(e))

    }

    handleSaveSubmitTest = (event) => {
        alert(1);
        this.setState({ transactionModalShow: true });

        setTimeout(() => {
            this.setState({ transactionModalButtonCloseShow: true });
        }, 2000);

        setTimeout(() => {
            this.setState({ transactionModalText: 'Guardando' });
        }, 4000);

        setTimeout(() => {
            this.setState({ transactionModalStatusImg: pending });
        }, 4000);

    }

    modalProcessing = (msg) => {
        this.setState({ transactionModalStatusImg: pending });
        this.setState({ transactionModalText: msg });
    }

    modalError = (msg, buttonCloseShow = true) => {
        this.setState({ transactionModalStatusImg: error });
        this.setState({ transactionModalText: msg });
        this.setState({ transactionModalButtonCloseShow: buttonCloseShow });
    }

    modalSuccess = (msg, buttonCloseShow = false) => {
        this.setState({ transactionModalStatusImg: success });
        this.setState({ transactionModalText: msg });
        this.setState({ transactionModalButtonCloseShow: buttonCloseShow });
    }

    modalShow = (show) => {
        this.setState({ transactionModalShow: show });
    }


    handleMintSubmit = (event) => {

        // this.setState({ transactionModalShow: true });
        // this.setState({ transactionModalButtonCloseShow: true });
        // this.setState({ transactionModalText: 'Guardando' });
        // this.setState({ transactionModalStatusImg: pending });

        event.preventDefault();

        const nft = this.state.nft;

        this.modalProcessing(this.t('nftDouble.alerts.validation'));
        this.modalShow(true);
        

        try {

            console.log(this.settings.contracts.chain);
            console.log(this.settings.contracts.bordes.address[this.settings.contracts.chain]);
            this.props.bordes.bordesLazyNFT.methods.redeem(
                this.settings.contracts.bordes.address[this.settings.contracts.chain],
                this.props.web3React.account, 
                nft.voucher
            ).send({
                from: this.props.web3React.account
            })
            .on("transactionHash", (txHash) => {
                this.modalProcessing(this.t('nftDouble.alerts.validateSign'));
                // this.pageService.showSuccess("Validando...");
            })
            .on("receipt", () => {
                //this.pageService.showSuccess("Validación confirmada");
                // this.setState({ transactionModalStatusImg: success });
                // this.setState({ transactionModalText: 'Firma validada' });
                this.modalProcessing(this.t('nftDouble.alerts.validated'));
                        
                this.nftMint(nft);
    
            })
            .on("error", (error) => {
                //this.pageService.showError(error);
                console.log(error);
                this.modalError(error.message || error);
            });
        } catch(error) {
            console.log(error);
            this.modalError(error.message || error);
        }
    }

    nftMint = (nft) => {

        this.modalProcessing(this.t('nftDouble.alerts.mint'));

        const attrDesc = 'description_' + this.context.global.user.language;

        const nftToMint = {
            name: nft.name,
            description: nft[attrDesc],
            image: "ipfs://" + nft.ipfs
        };

        let base64data = btoa(JSON.stringify(nftToMint));
        this.props.bordes.bordes.methods.safeMint(this.props.web3React.account, `data:application/json;base64,${base64data}`, nft.number).send({
            from: this.props.web3React.account
        })
            .on("transactionHash", (txHash) => {
                // this.pageService.showSuccess("Minteando...");
                this.modalProcessing(this.t('nftDouble.alerts.minting'));
            })
            .on("receipt", () => {
                // this.pageService.showSuccess("Produccion minteada");

                // this.pageService.showLoading();
                this.modalSuccess(this.t('nftDouble.alerts.mintProduction'));
                this.modalProcessing(this.t('nftDouble.alerts.updateMint'));

                this.pageService.httpPut({
                    endPoint: this.settings.api.endPoints.nfts + '/' + nft.id,
                    values: {
                        status: this.settings.nfts.status.minted
                    }
                }).then(result => {
                    this.setState({ nft: result.data });
                    //this.pageService.showSuccess("Produccion transferida");
                    this.modalSuccess(this.t('nftDouble.alerts.updateMintFinish'));
                    console.log(result.data);
                    this.addNftToMarket(result.data);
                })
                .catch(error => {
                  //  this.pageService.showError(error);
                  this.modalError(error.message || error);
                })
                //.finally(() => this.pageService.hideLoading());
            })
            .on("error", (error) => {
                // this.pageService.showError(error);
                this.modalError(error.message || error);
            });
    }

    addNftToMarket = (nft) => {
        
        // alert(ethers.utils.parseUnits(`${nft.price}`,'gwei').toString());
        this.modalProcessing(this.t('nftDouble.alerts.market'));

        this.props.bordes.bordesMarketplace.methods.listItem(
            this.settings.contracts.bordes.address[this.props.web3React.chainId],
            nft.number,
            ethers.utils.parseEther(`${nft.price/1000}`)
            // ethers.utils.parseUnits(`${nft.price}`,'gwei')
            // nft.price 
        ).send({
            from: this.props.web3React.account
        })
        .on("transactionHash", (txHash) => {
            // this.pageService.showSuccess("Agregando marketplace...");
            this.modalProcessing(this.t('nftDouble.alerts.addMarket'));
        })
        .on("receipt", () => {
            // this.pageService.showSuccess("Produccion agregada a marketplace");
            this.modalSuccess(this.t('nftDouble.alerts.addedMarket'));
            this.modalProcessing(this.t('nftDouble.alerts.updateMarket'));

            //this.pageService.showLoading();
            this.pageService.httpPut({
                endPoint: this.settings.api.endPoints.nfts + '/' + nft.id,
                values: {
                    status: this.settings.nfts.status.marketplace
                }
            }).then(result => {
                this.setState({ nft: result.data });
            //    this.pageService.showSuccess("Produccion actualizada");
                this.modalSuccess(this.t('nftDouble.alerts.addedUpdateMarket'), true);
            })
            .catch(error => {
                // this.pageService.showError(error);
                this.modalError(error.message || error);
            })
            //.finally(() => this.pageService.hideLoading());
        })
        .on("error", (error) => {
            //this.pageService.showError(error);
            this.modalError(error.message || error);
        });
    }

    canSign = () => {
        if (this.state && this.state.nft && this.state.nft.id) return true;
        return false;
    }

    canMint = () => {
        if (
            this.state &&
            this.state.nft &&
            this.state.nft.status == this.settings.nfts.status.lazy
        ) return true;
        return false;
    }


    handleCloseTransactionModel = () => {
        this.setState({ transactionModalShow: false });
    }

    /////////////////////////////////////////////////////////////////////
    /////// (-) (-) (-) (-) (-) (-) (-) (-) (-) (-) (-) (-) (-)   ///////
    /////////////////////////////////////////////////////////////////////


    handleSubmitNft(event) {

        event.preventDefault();

        const nftToMint = {
            name: "juan",
            description: "prueba",
            // image: "ipfs://" + this.state.fileOrigin.ipfs
            image: "ipfs://QmPbzsUBhmogxHGed13Hga3PazuAYrax5vhkENtNtTfxuf"
        };

        console.log(JSON.stringify(nftToMint));
        alert(JSON.stringify(nftToMint));

        let base64data = btoa(JSON.stringify(nftToMint));

        // bordes.methods.safeMint(account,'data:application/json;base64,eyAibmFtZSI6ICJCb3JkZXMgIzEiLCAiZGVzY3JpcHRpb24iOiAiQm9yZGVzIiwgImltYWdlIjogImh0dHBzOi8vZGlwcm9hY2guY29tL2Fzc2V0cy9pbWdzL2xvZ29fd2hpdGVfc21hbGwucG5nIn0=').send({
        this.props.bordes.bordes.methods.safeMint(this.props.web3React.account, `data:application/json;base64,${base64data}`).send({
            from: this.props.web3React.account
        })
            .on("transactionHash", (txHash) => {
                this.pageService.showSuccess(this.t('nftDouble.alerts.transactionSend'));
            })
            .on("receipt", () => {
                this.pageService.showSuccess(this.t('nftDouble.alerts.transactionConfirm'));
            })
            .on("error", (error) => {
                this.pageService.showError(error);
            });
    }

    handleSubmitOri(event) {
        event.preventDefault();
        const formData = new FormData(this.form.current);
        const data = {
            code: formData.get('code'),
            description_en: formData.get('description_en'),
            description_es: formData.get('description_es'),
            name: formData.get('name'),
            price: formData.get('price'),
            tags: formData.get('type'),
            type: formData.get('type'),
            user: this.context.global.user.id
        }

        this.pageService.showLoading();
        this.pageService.httpPost({
            endPoint: this.settings.api.endPoints.collections,
            values: data
        }).then(result => {
            // TODO: descomentar
            //this.form.current.reset();
            this.pageService.showSuccess(this.t('nftDouble.alerts.collectionCreated'));
        })
            .catch(error => {
                this.pageService.showError(error);
            })
            .finally(() => this.pageService.hideLoading());
    }



    render() {


        return (
            <>

                {!this.context.global.user && (
                    <Navigate to="/" replace={true} />
                )}
                <Container className='pb-5'>
                    {/* LOADER */}
                    {/* {this.state?.loading && */}
                    {/* <Loader />} */}
                    {/* LOADER */}

                    {/* {!this.state?.loading && */}
                    {
                        <>

                            <Row className='mt-5'>
                                <h1 className='title'>{this.t('nftDouble.labels.title')}</h1>
                                <h4 className='subtitle'>{this.t('labels.dematerialization')}</h4>
                            </Row>
                            <Row>
                                <Col xs={12} md={6}>
                                    <p class='text mt-5'>{this.t('nftDouble.labels.file')}</p>

                                    <Row>
                                        <Col xs={6} md={6}>
                                            <div className='upload'
                                                ref={this.wrapperOriginRef}
                                                onDragEnter={this.onDragOriginEnter}
                                                onDragLeave={this.onDragOriginLeave}
                                                onDrop={this.onDropOrigin}>
                                                <Row className='justify-content-center'>
                                                    <p className='text-center'>{this.t('nftDouble.labels.image')}</p>
                                                </Row>
                                                <Row className='justify-content-center'>
                                                    <MdUpload className='upload-icon' />
                                                </Row>
                                                <input type="file" className='fileInput' onChange={this.handleFileOriginDrop} />
                                            </div>
                                        </Col>

                                        <Col xs={6} md={6}>
                                            <div className='position-relative d-flex justify-content-center'>

                                                {
                                                    !this.state?.fileOrigin && <Col md={12}>
                                                        <Placeholder animation="glow">
                                                            <Placeholder id='image-placeholder' md={12} size="lg" />
                                                        </Placeholder>
                                                    </Col>
                                                }

                                                {
                                                    this.state?.fileOrigin && <>
                                                        <img src={this.state?.fileOrigin.src} height='200px' style={{ objectFit: 'contain' }} alt="" />
                                                    </>
                                                }

                                            </div>
                                        </Col>

                                    </Row>

                                </Col>

                                <Col xs={12} md={6}>
                                    <p class='text mt-5'>{this.t('nftDouble.labels.target')}</p>

                                    <Row>
                                        <Col xs={6} md={6}>
                                            <div className='upload'
                                                ref={this.wrapperTargetRef}
                                                onDragEnter={this.onDragTargetEnter}
                                                onDragLeave={this.onDragTargetLeave}
                                                onDrop={this.onTargetDrop}>
                                                <Row className='justify-content-center'>
                                                    <p className='text-center'>{this.t('nftDouble.labels.image')}</p>
                                                </Row>
                                                <Row className='justify-content-center'>
                                                    <MdUpload className='upload-icon' />
                                                </Row>
                                                <input type="file" className='fileInput' onChange={this.handleFileTargetDrop} />
                                            </div>
                                        </Col>

                                        <Col xs={6} md={6}>
                                            <div className='position-relative d-flex justify-content-center'>

                                                {
                                                    !this.state?.fileTarget && <Col md={12}>
                                                        <Placeholder animation="glow">
                                                            <Placeholder id='image-placeholder' md={12} size="lg" />
                                                        </Placeholder>
                                                    </Col>
                                                }

                                                {
                                                    this.state?.fileTarget && <>
                                                        <img src={this.state?.fileTarget.src} height='200px' style={{ objectFit: 'contain' }} alt="" />
                                                    </>
                                                }

                                            </div>
                                        </Col>

                                    </Row>

                                </Col>

                            </Row>

                            <Form className='mt-5' ref={this.form}>
                                <Row>
                                    <Col xs={12} md={6}>
                                        <Form.Group className="mb-3">
                                            <Form.Label className='text-danger'>{this.t('nftDouble.form.name')}</Form.Label>
                                            <Form.Control className='control' type="text" placeholder={this.t('nftDouble.form.name')} name="name" value={this.state?.nft?.name} onChange={this.handleValueChange} />
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label className='text-danger'>{this.t('nftDouble.form.code')}</Form.Label>
                                            <Form.Control className='control' type="text" placeholder={this.t('nftDouble.form.code')} name="code" value={this.state?.nft?.code} onChange={this.handleValueChange} />
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label className='text-danger'>{this.t('nftDouble.form.code2')}</Form.Label>
                                            <Form.Control className='control' type="text" placeholder={this.t('nftDouble.form.code2')} name="code2" value={this.state?.nft?.code2} onChange={this.handleValueChange} />
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label className='text-danger'>{this.t('nftDouble.form.descriptionEs')}</Form.Label>
                                            <Form.Control className='control' as="textarea" rows={3} placeholder={this.t('nftDouble.form.writeHere')} name="description_es" value={this.state?.nft?.description_es} onChange={this.handleValueChange} />
                                        </Form.Group>

                                    </Col>
                                    <Col xs={12} md={6}>

                                        <Form.Group className="mb-3">
                                            <Form.Label className='text-danger'>{this.t('nftDouble.form.descriptionEn')}</Form.Label>
                                            <Form.Control className='control' as="textarea" rows={3} placeholder={this.t('nftDouble.form.writeHere')} name="description_en" value={this.state?.nft?.description_en} onChange={this.handleValueChange} />
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label className='text-danger'>Tags</Form.Label>
                                            <Form.Control className='control' type="text" placeholder="arte abstracto visuales" name="tags" value={this.state?.nft?.tags} onChange={this.handleValueChange} />
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label className='text-danger'>{this.t('nftDouble.form.price')}</Form.Label>
                                            <Form.Control className='control' type="number" placeholder="000000" name="price" value={this.state?.nft?.price} onChange={this.handleValueChange} />
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label className='text-danger'>{this.t('nftDouble.form.fee')}</Form.Label>
                                            <Form.Control className='control' type="number" placeholder="1.5" name="fee" value={this.state?.nft?.fee} onChange={this.handleValueChange} />
                                        </Form.Group>

                                    </Col>
                                </Row>

                                <Row className='mt-3'>
                                    <Col xs={12} md={6}></Col>
                                    <Col xs={12} md={6}>
                                        <Row className='justify-content-end'>
                                            <Col xs={12} md={3}>
                                                <Button variant='outline' className='d-block w-100 create mt-2' type='button' onClick={this.handleBack}>{this.t('buttons.back')}</Button>
                                            </Col>
                                            <Col xs={12} md={3}>
                                                <Button variant='danger' className='d-block w-100 mt-2' type='button' onClick={this.handleSaveSubmit}>{this.t('buttons.save')}</Button>
                                            </Col>
                                            <Col xs={12} md={3}>
                                                <Button variant='danger' disabled={!this.canSign()} className='d-block w-100 mt-2' type='button' onClick={this.handleSignSubmit}>{this.t('nftDouble.buttons.sign')}</Button>
                                            </Col>
                                            <Col xs={12} md={3}>
                                                <Button variant='danger' disabled={!this.canMint()} className='d-block w-100 mt-2' type='button' onClick={this.handleMintSubmit}>{this.t('nftDouble.buttons.mint')}</Button>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>

                            </Form>


                            <Modal
                                show={this.state?.transactionModalShow}
                                size="lg"
                                aria-labelledby="contained-modal-title-vcenter"
                                centered
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title id="contained-modal-title-vcenter">
                                        {this.t('nftDouble.labels.transaction')}
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Row className="justify-content-center align-items-center">
                                        <Col xs={12} md={2} className='text-center'>
                                            {this.state &&
                                                <img src={ this.state.transactionModalStatusImg} className='icons-modal' alt="" />
                                            }
                                        </Col>
                                        <Col xs={12} md={10} className='mt-2'>
                                            <p>
                                                {this.state && this.state.transactionModalText != '' &&
                                                    <>
                                                        {this.state.transactionModalText}
                                                    </>
                                                }
                                            </p>
                                        </Col>
                                    </Row>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Row className='w-100'>
                                        <Col xs={12} md={3}></Col>
                                        <Col xs={12} md={3}></Col>
                                        <Col xs={12} md={6} className='text-end'>
                                            {this.state && this.state.transactionModalButtonCloseShow &&
                                                <Button className='btn-home create' variant='outline' onClick={this.handleCloseTransactionModel}>
                                                    {this.t('nftDouble.buttons.close')}
                                                </Button>
                                            }
                                        </Col>
                                    </Row>
                                </Modal.Footer>
                            </Modal>

                        </>} 
                </Container>
            </>
        );


    }


}

export default withUseParams(withWeb3React(withBordes(withAppGlobal(withI18n(withNavigation(NftDouble))))));
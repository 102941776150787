import React, { useEffect, useRef, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Button, Accordion, Form, Placeholder, Dropdown, Carousel } from 'react-bootstrap';
import { ChromePicker } from 'react-color';
import './NftIntervention.css';
import { MdUpload } from 'react-icons/md';
import Loader from './Loader';
import HttpService from '../../services/HttpService';
import InputRange from 'react-input-range';
import { QRCode } from 'react-qrcode-logo';
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import { useTranslation, Trans } from 'react-i18next';
// import { useDrop } from 'react-dnd';
// import { NativeTypes } from 'react-dnd-html5-backend';

// + IMAGES +
import placeholder from '../../assets/imgs/nft_placeholder.png';
import btnColor from '../../assets/imgs/btn-color.png';
// - IMAGES -

import domtoimage from 'dom-to-image';
import AppSettings from '../../AppSettings';
import { BsZoomIn, BsZoomOut } from 'react-icons/bs'
import Draggable from 'react-draggable';
import * as svgScale from 'scale-svg-path';
import * as svgParse from 'parse-svg-path';
import * as svgSerialize from 'serialize-svg-path';

const NftIntervention = () => {

  const canvasSize = window.innerWidth > 1200 ? {
    code: 'm',
    pixels: 500
  } : {
    code: 's',
    pixels: 250
  };

  const { settings } = AppSettings();
  const { t } = useTranslation();

  const [item, setItem] = useState(false);
  setTimeout(() => setItem(true), 500);

  const [state, setState] = useState(false);
  const [stateForm, setStateForm] = useState(false);

  const wrapperRef = useRef(null);
  const imageRef = useRef(null);
  const imageRefNft = useRef(null);
  const divImageRef = useRef(null);
  const [draggablePosition, setDraggablePosition] = useState({ x: 0, y: 0 });
  const [image, setImage] = useState(placeholder);
  const [qrStyle, setQrStyle] = useState(settings.qrStyle.dots);
  const [zoom, setZoom] = useState(1);
  const [opacity, setOpacity] = useState(100);
  const [brightness, setBrightness] = useState(100);
  const [backgroundCanvas, setBackgroundCanvas] = useState(settings.backgroundCanvas.default.code);
  const [backgroundCanvasColor, setBackgroundCanvasColor] = useState('#772727');
  const [qrColor, setQrColor] = useState('#772727');
  const[nftClipPath, setNftClipPath] = useState(settings.backgroundCanvas[backgroundCanvas].path);
  const[ClipPath, setClipPath] = useState(settings.backgroundCanvas[backgroundCanvas].path);
  const[nftSize, setNftSize] = useState(canvasSize.pixels);
  const[sizeImgPreviewCont, setSizeImgPreviewCont] = useState(canvasSize.pixels);
  const[showNft, setShowNft] = useState(false);

  const handleFileDrop = async e => {

    const file = e.target.files[0];
    if (!file) return;

    const response = await HttpService.postFormDataFile(file);

    if (response.data.file) setImage(process.env.REACT_APP_FILES_URL + '/' + response.data.file);

  }

  function getScaledSvg(imgSize) {
    const path = svgParse(settings.backgroundCanvas[backgroundCanvas].path);
    const scale = imgSize / settings.backgroundCanvas[backgroundCanvas].pathSize;
    const xy = svgScale(path, scale, scale);
    const serialize = svgSerialize(xy);
    return serialize;
  }

  const applyCanvas = (imgSize) => {
    const svg = getScaledSvg(imgSize);
    setClipPath(svg);
  }

  const applyCanvasNft = (imgSize) => {
    const svg = getScaledSvg(imgSize);
    setNftClipPath(svg);
  }

  const handleImage = () => {
    if (imageRef.current) {
      const imgCont = document.querySelector('#img-preview-cont');
      const width = imgCont.offsetWidth;
      const height = imgCont.offsetHeight;
      const minSize = Math.min(width, height);
      setSizeImgPreviewCont(minSize);
      applyCanvas(minSize);
    }
  }

  const handleImageNft = () => {
    if (imageRef.current) {
      const imgNaturalWidth = imageRef.current.naturalWidth;
      const imgNaturalHeight = imageRef.current.naturalHeight;
      const minSize = Math.min(imgNaturalWidth, imgNaturalHeight);
      setNftSize(minSize);
      applyCanvasNft(minSize);
    }
  }

  const onDragEnter = () => wrapperRef.current.classList.add('dragover');
  const onDragLeave = () => wrapperRef.current.classList.remove('dragover');
  const onDrop = () => wrapperRef.current.classList.remove('dragover');

  const handleNFT = async (params = {}) => {

    if (!params.elId) params.elId = 'nft';
    const element = document.querySelector('#' + params.elId);
    if (!element) return;

    const path = svgParse(settings.backgroundCanvas[backgroundCanvas].path);
    const scale = nftSize / settings.backgroundCanvas[backgroundCanvas].pathSize;
    const xy = svgScale(path, scale, scale);
    const serialize = svgSerialize(xy);
    setNftClipPath(serialize);

    if (!params.scale) params.scale = 1;
    const elWidth = element.offsetWidth, elHeight = element.offsetHeight;
    const style =  params.scale > 1 ? {
      'transform': 'scale('+ params.scale +')',
      'transform-origin': 'top left'
    } : {};
    const options = {
      style,
      width: elWidth * params.scale,
      height: elHeight * params.scale
    };

    const dataURL = await domtoimage.toPng(element, options);
    if (!dataURL) return;

    const A = document.createElement('a');
    A.href = dataURL;
    A.download = 'NFT.png';
    A.click();
  };

  useEffect(() => {
    applyCanvas(sizeImgPreviewCont);
    applyCanvasNft(nftSize);
  }, [backgroundCanvas])
  
  const handleFormClick = () => {
    setStateForm({ stateForm: !stateForm })
  };

  const handleFormClose = () => {
    setStateForm(false)
  };

  const handleClick = () => {
    setState({ state: !state })
  };

  const handleClose = () => {
    setState(false)
  };

  const handleDrag = (event, { x, y }) => setDraggablePosition({ x, y });

  const popover = {
    position: 'absolute',
    zIndex: '2',
  }
  const cover = {
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
  }

  function getQrScale() {
    if (backgroundCanvas == 'default') return 0.8;
    else if (backgroundCanvas == 'round') return 0.75;
    else if (backgroundCanvas == 'rectangular') return 0.68;
    else return 0.8;
  }

  return (
    <Container className='pb-5'>
      {/* LOADER */}
      {!item &&
        <Loader />
      }
      {/* LOADER */}

      {item &&
        <>
          <Row className='mt-5'>
            <h1 className='title'>INTERVENCION DE PIEZA</h1>
          </Row>

          <Row className='overflow-hidden'>
            <Col xs={12} md={6}>
              <Accordion defaultActiveKey='0' className='accordion mt-3' flush>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Customizar Lienzo y QR</Accordion.Header>
                  <Accordion.Body>
                    <p>Lienzos</p>
                    <Row className='align-items-center'>
                      <Col>
                        <span>Disponibles</span>
                      </Col>
                      <Col className='align-right'>
                        <Button variant='clear' onClick={handleClick}>
                          <img src={btnColor} alt="" />
                        </Button>
                        {state ? <div style={popover}>
                          <div style={cover} onClick={handleClose} />
                          <ChromePicker color={backgroundCanvasColor} onChange={(color) => setBackgroundCanvasColor(color.hex)} />
                        </div> : null}
                      </Col>
                    </Row>
                    <Row className='align-items-center justify-content-center'>
                      <Swiper
                        slidesPerView={3.7}
                        spaceBetween={30}
                        pagination={{
                          clickable: true,
                        }}
                        modules={[Navigation]}>
                        {
                          Object.values(settings.backgroundCanvas).map(canvas =>
                            <SwiperSlide key={canvas.code}>
                              <svg className='select-svg' onClick={() => setBackgroundCanvas(canvas.code)} id="Capa_1" width='200' height="200" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 586 586"><defs><style>.cls-1{'fill:#ae1917;'}</style></defs><path fill={backgroundCanvasColor} className="cls-1" d={canvas.path} /></svg>
                            </SwiperSlide>
                          )
                        }
                      </Swiper>
                    </Row>

                    <hr className='separetor' />

                    <Row className='align-items-center'>
                      <Col>
                        <span>QR</span>
                      </Col>
                      <Col className='align-right'>
                        <Button variant='clear' onClick={handleFormClick}>
                          <img src={btnColor} alt="" />
                        </Button>
                        {stateForm ? <div style={popover}>
                          <div style={cover} onClick={handleFormClose} />
                          <ChromePicker color={qrColor} onChange={(color) => setQrColor(color.hex)} />
                        </div> : null}
                      </Col>
                    </Row>

                    <Form>
                      <Row className='align-items-center mt-4'>
                        <Col xs={3} md={2}>
                          <span>Formas</span>
                        </Col>
                        <Col xs={2} md={1}>
                          <Form.Check
                            type='radio' id='circle' name='qrStyle'
                            checked={qrStyle.code === settings.qrStyle.dots.code}
                            aria-label='circle' onChange={() => setQrStyle(settings.qrStyle.dots)} />
                        </Col>
                        <Col xs={2} md={2} className='align-left p-0'>
                          <Form.Label htmlFor='circle'>
                            <svg width="50" height="50">
                              <circle cx="20" cy="20" r="20" stroke-width="6" fill={qrColor} />
                            </svg>
                          </Form.Label>
                        </Col>

                        <Col xs={2} md={1}>
                          <Form.Check
                            type='radio' id='square' name='qrStyle'
                            checked={qrStyle.code === settings.qrStyle.square.code}
                            aria-label='square' onChange={() => setQrStyle(settings.qrStyle.square)} />
                        </Col>
                        <Col xs={2} md={2} className='align-left p-0'>
                          <Form.Label htmlFor='square'>
                            <svg width="50" height="50">
                              <rect width="40" height="40" stroke-width="6" fill={qrColor} />
                            </svg>
                          </Form.Label>
                        </Col>
                      </Row>
                    </Form>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>

              <Accordion defaultActiveKey='0' className='accordion mt-3' flush>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Customización de foto</Accordion.Header>
                  <Accordion.Body>
                    <Row>
                      <Col xs={6} md={6}>
                        <div className='upload' ref={wrapperRef} onDragEnter={onDragEnter} onDragLeave={onDragLeave} onDrop={onDrop}>
                          <Row className='justify-content-center'>
                            <p className='text-center'>Subir Imágen</p>
                          </Row>
                          <Row className='justify-content-center'>
                            <MdUpload className='upload-icon' />
                          </Row>
                          <input type="file" className='fileInput' onChange={handleFileDrop} />
                        </div>
                      </Col>
                      <Col xs={6} md={6}>
                        <div className='position-relative d-flex justify-content-center'>

                          {
                            !image && <Col md={12}>
                              <Placeholder animation="glow">
                                <Placeholder id='image-placeholder' md={12} size="lg" />
                              </Placeholder>
                            </Col>
                          }

                          {
                            image && <>
                              <img src={image} className="img-fluid" style={{ objectFit: 'contain' }} alt="" />
                            </>
                          }

                        </div>
                      </Col>
                    </Row>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>

            </Col>
            <Col xs={12} md={6}>
              <h3 className='align-right prev mt-3 labels'>{t('labels.preview')}</h3>

              <div id="img-preview-cont" className='position-relative ms-auto justify-content-center d-flex image-nft' style={{ width: canvasSize.pixels + 'px', height: canvasSize.pixels + 'px', overflow: 'hidden', alignItems: 'center', clipPath: `path("${ClipPath}")`, backgroundColor: backgroundCanvasColor }}>
                {
                  !image && <Col md={12}>
                    <Placeholder animation="glow">
                      <Placeholder id='nft-placeholder' xs={12} size="lg" />
                    </Placeholder>
                  </Col>
                }

                {
                  image && <>
                    {/* <div style={{ transform: `scale(${zoom})`, height: '100%' }}> */}
                    <div style={{ transform: `scale(${zoom})` }} ref={divImageRef}>
                      <Draggable scale={zoom} onDrag={handleDrag}>
                        <img id="img-preview" ref={imageRef} onLoad={handleImage} src={image} draggable="false" style={{ opacity: `${opacity}%`, filter: `brightness(${brightness}%)` }} className='pe-auto drag' alt="" />
                      </Draggable>
                    </div>

                    <div className='position-absolute d-flex justify-content-center align-items-center w-100 h-100 top-0 start-0 pe-none'>
                      <QRCode value={image} size={canvasSize.pixels * getQrScale()} fgColor={qrColor} bgColor='transparent' qrStyle={qrStyle.code} eyeRadius={qrStyle.radius} ecLevel='H'></QRCode>
                    </div>
                  </>
                }
              </div>

              {/* <Row className='justify-content-end mt-4'>
                            <Col xs={12} md={10}>
                            </Col>
                            </Row> */}

              <Row>
                <Col></Col>
                <Col xs={12} md={10}>
                  <p>Zoom</p>
                  <InputRange maxValue={3.00} step={0.05} minValue={0.30} value={zoom} onChange={value => setZoom(parseFloat(value.toFixed(2)))} formatLabel={value => `${value.toFixed(2)}`} draggableTrack={false} />
                </Col>
              </Row>

              <Row className='mt-5'>
                <Col xs={0} md={2}></Col>
                <Col xs={6} md={5}>
                  <p>Opacidad</p>
                  <InputRange maxValue={100} minValue={0} value={opacity} onChange={value => setOpacity(value)} formatLabel={value => `${value}%`} draggableTrack={false} />
                </Col>
                <Col xs={6} md={5}>
                  <p>Brillo</p>
                  <InputRange maxValue={100} minValue={0} value={brightness} onChange={value => setBrightness(value)} formatLabel={value => `${value}%`} draggableTrack={false} />
                </Col>
              </Row>
            </Col>
          </Row>

          <Form className='mt-5'>
            <Row>
              <Col xs={12} md={6}>
                <Form.Group className="mb-3">
                  <Form.Label className='text-danger'>Nombre</Form.Label>
                  <Form.Control className='control' type="text" placeholder="Nombre" />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label className='text-danger'>Descripción</Form.Label>
                  <Form.Control className='control' as="textarea" rows={3} placeholder="Escriba aquí" />
                </Form.Group>
              </Col>
              <Col xs={12} md={6}>
                <Form.Group className="mb-3">
                  <Form.Label className='text-danger'>Precio de venta</Form.Label>
                  <Form.Control className='control' type="text" placeholder="000000" />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label className='text-danger'>Tags</Form.Label>
                  <Form.Control className='control' type="text" placeholder="Example" />
                </Form.Group>
              </Col>
            </Row>

            <Row className='mt-3'>
              <Col xs={12} md={6}></Col>
              <Col xs={12} md={6}>
                <p className='mint'>PRECIO POR MINTEAR</p>

                <p className='align-right price'>999.999</p>

                <Row className='justify-content-end'>
                  <Col xs={12} md={4}></Col>
                  <Col xs={12} md={4}>
                    <Button variant='outline' className='d-block w-100 create mt-2' type='button'>CANCELAR</Button>
                  </Col>
                  <Col xs={12} md={4}>
                    <Button variant='danger' className='d-block w-100 mt-2' type='button' onClick={()=> handleNFT()}>ACEPTAR</Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
          <div style={{ width: '100%', overflow: `${showNft ? 'visible' : 'hidden'}`, height: `${showNft ? 'auto' : '0px'}` }}>
          
            <div id='nft' className='position-relative justify-content-center d-flex image-nft' 
              style={{ width: `${nftSize}px`, height: `${nftSize}px`, overflow: 'hidden', alignItems: 'center', clipPath: `path("${nftClipPath}")`, backgroundColor: backgroundCanvasColor }}>
              {
                !image && <Col md={12}>
                  <Placeholder animation="glow">
                    <Placeholder id='nft-placeholder' xs={12} size="lg" />
                  </Placeholder>
                </Col>
              }

              {
                image && <>
                  {/* <div style={{ transform: `scale(${zoom})`, height: '100%' }}> */}
                  <div style={{ transform: `scale(${zoom * (nftSize / canvasSize.pixels)})` }} ref={divImageRef}>
                    <Draggable scale={zoom * (nftSize / canvasSize.pixels)} position={draggablePosition}>
                      <img id="img-nft" ref={imageRefNft} onLoad={handleImageNft} src={image} draggable="false" style={{ opacity: `${opacity}%`, filter: `brightness(${brightness}%)` }} className='pe-auto drag' alt="" />
                    </Draggable>
                  </div>

                  <div className='position-absolute d-flex justify-content-center align-items-center w-100 h-100 top-0 start-0 pe-none'>
                    <QRCode value={image} size={(nftSize * getQrScale())} fgColor={qrColor} bgColor='transparent' qrStyle={qrStyle.code} eyeRadius={qrStyle.radius} ecLevel='H'></QRCode>
                  </div>
                </>
              }
            </div>

          </div>
        </>
      }
    </Container >
  );
}

export default NftIntervention;
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Button, Image, Card, Input } from 'react-bootstrap';
import './index.css';

import { connector } from "../../config/web3";
import { useCallback, useEffect, useState } from "react";
import { UnsupportedChainIdError, useWeb3React } from "@web3-react/core";
import useTruncatedAddress from "../../hooks/useTruncatedAddress";

import useBordes from "../../hooks/useBordes";

import { useBordesNftsData } from "../../hooks/useBordesData";


const Test = () => {

    const { active, activate, deactivate, account, error, library } = useWeb3React();

    // (+) contrato
    const bordes = useBordes();
    const [name, setName] = useState("...");
    const [totalSupply, setTotalSupply] = useState(0);
    const [isMinting, setIsMinting] = useState(false);
    let { nfts, loading } = useBordesNftsData({
        owner: null,
    });

    const getBordesData = useCallback(async () => {
        if (bordes) {
          setName(await bordes.methods.name().call());
          setTotalSupply(await bordes.methods.totalSupply().call());
        }
    }, [bordes]);    

    useEffect(() => {
        getBordesData();
    }, [getBordesData]);

    const mint = () => {
        setIsMinting(true);

        const nftToMint = { 
            name: nft.name, 
            description: nft.description,
            image: "ipfs://" + nft.ipfs
        };

        alert(JSON.stringify(nftToMint));

        let base64data = btoa(JSON.stringify(nftToMint));
        
        // bordes.methods.safeMint(account,'data:application/json;base64,eyAibmFtZSI6ICJCb3JkZXMgIzEiLCAiZGVzY3JpcHRpb24iOiAiQm9yZGVzIiwgImltYWdlIjogImh0dHBzOi8vZGlwcm9hY2guY29tL2Fzc2V0cy9pbWdzL2xvZ29fd2hpdGVfc21hbGwucG5nIn0=').send({
        bordes.methods.safeMint(account,`data:application/json;base64,${base64data}`).send({            
            from: account
        })
        .on("transactionHash", (txHash) => {
            toast({
                title: "Transacción enviada",
                description: txHash,
                status: "info"
            });
        })
        .on("receipt", () => {
            setIsMinting(false);
            toast({
                title: "Transacción confirmada",
                description: "Haz minteado",
                status: "success"
            });
            getBordesData();
        })
        .on("error", (error) => {
            setIsMinting(false);
            toast({
            title: "Transacción fallida",
            description: error.message,
            status: "error"
            });
        });

    };

    // (-) contrato


    const [balance, setBalance] = useState(0);
    const [unsupportedChain, setUnsupportedChain] = useState(false);

    const connect = useCallback(() => {
        activate(connector);
        localStorage.setItem("previouslyConnected", "true");
    }, [activate]);
    
    const disconnect = () => {
        deactivate();
        localStorage.removeItem("previouslyConnected");
    };
    
    const getBalance = useCallback(async () => {
        const toSet = await library.eth.getBalance(account);
        setBalance((toSet / 1e18).toFixed(2));
    }, [library?.eth, account]);
    
    useEffect(() => {
        if (active) getBalance();
    }, [active, getBalance]);
    
    useEffect(() => {
        if (localStorage.getItem("previouslyConnected") === "true") connect();
    }, [connect]);

    useEffect(() => {
        console.log(`active: ${active}`);
        console.log(`error: ${error}`);
        if(active) setUnsupportedChain(false);
        else if(error) setUnsupportedChain(error instanceof UnsupportedChainIdError);
        else setUnsupportedChain(false);
        
    }, [active,error]);
    


    const truncatedAddress = useTruncatedAddress(account);
    
    const toast = (({ title, description, status }) => {
        alert( description );
        // return () => (
        //   <Toast>
        //     <Toast.Header>{ title } - { status }</Toast.Header>
        //     <Toast.Body>{ description }</Toast.Body>
        //   </Toast>
        // );
    });

    const [nft, setNft] = useState({});
    const [nftName, setNftName] = useState('');
    const handleNftName = ( (event) => {
		setNftName(event.target.value);
    });
    const [nftDescription, setNftDescription] = useState('');
    const handleNftDescription = ( (event) => {
		setNftDescription(event.target.value);
    });

    const [ipfsFile, setIpfsFile] = useState('');
    const [selectedFile, setSelectedFile] = useState();
	const saveFile = ( (event) => {
		setSelectedFile(event.target.files[0]);
    });

    const uploadFile = ( () => {
		const formData = new FormData();
        
		formData.append('file', selectedFile);

		fetch(
			'http://localhost:3151/api/v1/nfts/upload',
			{
				method: 'POST',
				body: formData,
			}
		)
		.then((response) => response.json())
		.then((result) => {
            console.log(result);

            nft.ipfs = result.data.ipfs;
            nft.file = result.data.file;

		    setNft(nft);

            setIpfsFile(`https://cloudflare-ipfs.com/ipfs/${result.data.ipfs}`);
            // ipfs.io/ipfs
            toast({
                title: "Archivo",
                description: "Archivo subido",
                status: "info"
            });
    
		})
		.catch((error) => {
		    console.error('Error:', error);
		});
    });


    const [ipfsFile2, setIpfsFile2] = useState('');
    const [selectedFile2, setSelectedFile2] = useState();
	const saveFile2 = ( (event) => {
		setSelectedFile2(event.target.files[0]);
    });

    const uploadFile2 = ( () => {
		const formData = new FormData();

		formData.append('file', selectedFile2);

		fetch(
			'http://localhost:3151/api/v1/nfts/upload',
			{
				method: 'POST',
				body: formData,
			}
		)
		.then((response) => response.json())
		.then((result) => {
            
            nft.ipfs2 = result.data.ipfs;
            nft.file2 = result.data.file;

		    setNft(nft);
            
            setIpfsFile(`https://cloudflare-ipfs.com/ipfs/${result.data.ipfs}`);
            toast({
                title: "Archivo 2",
                description: "Archivo 2 subido",
                status: "info"
            });

		})
		.catch((error) => {
		    console.error('Error:', error);
		});
    });

    const nftCreate = ( () => {

        nft.name = nftName;
        nft.description = nftDescription;

        console.log(nft);
          
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("x-access-token", "{{token}}");
        
        var raw = JSON.stringify(nft);
        
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: raw,
          redirect: 'follow'
        };

          fetch("http://localhost:3151/api/v1/nfts", requestOptions)
            .then(response => response.text())
            .then(result => {
                const data = JSON.parse(result).data;
                console.log(data);
                setNft(data);

                toast({
                    title: "NFT",
                    description: "Creado en api",
                    status: "info"
                });
    
            })
            .catch(error => console.log('error', error));


    });

    return (
        <Container>
            <Row className="justify-content-center align-items-center">
                <Col xs={12} md={12} className='mt-3'>

                    {active ? (
                        <>
                            <Button className='btn-home create' 
                            onClick={disconnect}
                            variant='outline'>DESCONECTAR</Button>
                            <p className='mt-4 link'>Name: {name}</p>
                            <p className='mt-4 link'>Balance: ~{balance} Ξ</p>
                            <p className='mt-4 link'>Total supply: {totalSupply}</p>
                            <p className='mt-4 link'>Address: {truncatedAddress}</p>
                            
                            <Card>
                                <h2>NFT SIMPLE</h2>

                                <div>
                                    <input type="file" onChange={saveFile} />
                                    <button onClick={uploadFile}>Subir archivo</button>
                                </div>
                                <br></br>
                                <Image src={ipfsFile}/>
                                <br></br>
                                <input onChange={handleNftName} value={nftName}/>
                                <br></br>
                                <input onChange={handleNftDescription} value={nftDescription}/>
                                <br></br>
                                <Button onClick={nftCreate} >CREAR NFT</Button>
                                <br></br>
                                <Button onClick={mint} >MINTEAR NFT</Button>
                            </Card>

                            <br></br>
                            <br></br>

                            <Card>
                                <h2>NFT QR</h2>

                                <div>
                                    <input type="file" onChange={saveFile} />
                                    <button onClick={uploadFile}>Subir archivo 1</button>
                                </div>
                                <br></br>
                                <Image src={ipfsFile}/>
                                <br></br>

                                <div>
                                    <input type="file" onChange={saveFile2} />
                                    <button onClick={uploadFile2}>Subir archivo 2</button>
                                </div>
                                <br></br>
                                <Image src={ipfsFile2}/>
                                <br></br>

                                <input onChange={handleNftName} value={nftName}/>
                                <br></br>
                                <input onChange={handleNftDescription} value={nftDescription}/>
                                <br></br>
                                <Button onClick={nftCreate} >CREAR NFT 2</Button>
                                <br></br>
                                <Button onClick={mint} >MINTEAR NFT 2</Button>
                            </Card>

                            <br></br>
                            <br></br>

                            <Card>
                            {nfts.map(({ name, image, tokenId }) => (
                                <>
                                    <p>{tokenId} - {name}</p>
                                    <Image src={image}/>
                                </>    
                            ))}
                            </Card>

                        </>
                    ) : (
                        <Button className='btn-home create' 
                        onClick={connect}
                        variant='outline'>
                            Conectar wallet
                        </Button>
                    )}

                </Col>
            </Row>


        </Container>
    );
}

export default Test;
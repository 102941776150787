import { createSlice } from '@reduxjs/toolkit'

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    value: null,
  },
  reducers: {
    removeUser: (state) => {
      state.value = null;
      console.log(`removeUser: ${state.value}`);
    },
    setUser: (state, action) => {
      state.value = action.payload;
      console.log(`setUser: ${state.value}`);
    },
  },
})

// Action creators are generated for each case reducer function
export const { setUser, removeUser } = userSlice.actions

export default userSlice.reducer
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import './HomeCollection.css';
import BaseComponent from '../../core/BaseComponent';
import { Navigate } from "react-router-dom";
import { withNavigation, withI18n, withAppGlobal } from '../../core/BaseComponent';

import qrInter from '../../assets/imgs/qr-inter.png';
import eth from '../../assets/imgs/eth.png';
import avatar from '../../assets/imgs/avatar.png';
import person from '../../assets/imgs/person.jpeg';
import desma from '../../assets/imgs/desma.png';
import qr from '../../assets/imgs/qr.png';
import qrBorder from '../../assets/imgs/qr-border.png';
import { propTypes } from 'react-bootstrap/esm/Image';
import NftItem from '../nft-item/NftItem';

import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCards } from "swiper";
import HomeCollectionItem from '../home-collection-item/HomeCollectionItem';
// (+) Common
import AppSettings from '../../AppSettings';
import React, { Component, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import PageService from '../../core/PageService';
import AppGlobal from '../../context/AppGlobal';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
// (-) Common


const HomeCollection = ({ collection }) => {
    const { t } = useTranslation();
    // (+) Common
    const { global, addGlobal } = useContext(AppGlobal);
    const user = useSelector((state) => state.user.value)
    const dispatch = useDispatch()
    const pageService = new PageService({
        appGlobal: {
            global: global,
            addGlobal: addGlobal
        }
    });
    const navigate = useNavigate();
    const { settings } = AppSettings();
    const [loading, setLoading] = useState(true);
    const [initialized, setInitialized] = useState(false);
    // (-) Common

    const goToIntervention = (collection) => {
        let id = 'new';
        let nftType = 'nft-double-intervention';

        navigate(`/${nftType}/${collection.id}/${id}`);
    }

    const goToCollection = (collection) => {
        navigate('/collection/' + collection.code);
    }
    return (
        <>

            {collection?.code == settings.collections.codes.interventions &&
                <Row className="align-items-center mt-5 p-4">
                    <Col xs={12} md={12}>
                        <Row className='align-items-center'>
                            <Col>
                                <h3 className='mt-2 custom-pt-32'>{collection.name}</h3>
                            </Col>
                            <Col xs={7} md={3} className='text-end'>
                                <Button className='btn-home collection mt-2 custom-m r-desk' onClick={() => { goToIntervention(collection) }}>{t('home.buttons.iIntervened')}</Button>
                            </Col>
                            <Col xs={5} md={3} className='text-end'>
                                <Button className='btn-home create mt-2 custom-m r-desk' variant='outline' onClick={() => { goToCollection(collection) }}>{t('home.buttons.seeAll')}</Button>
                            </Col>
                        </Row>
                        <p className='text mt-3'>{t('home.labels.intervention.title')}</p>
                    </Col>
                </Row>
            }

            {collection?.code != settings.collections.codes.interventions &&
                <Row className="align-items-center mt-5 p-4">
                    <Col xs={12} md={12}>
                        <Row className='align-items-center'>
                            <Col>
                                <h3 className='text-danger mt-2'>{collection.name}</h3>
                            </Col>
                            <Col xs={12} md={4} className='text-end r-desk'>
                                <Button variant='outline-danger custom-m' onClick={() => { goToCollection(collection) }}>{t('home.buttons.seeCollection')}</Button>
                            </Col>
                        </Row>
                        <p className='text text-danger mt-3'>{collection?.['description_es']}</p>

                    </Col>
                </Row>
            }


            <Row className="justify-content-center align-items-center">
                {collection.nfts?.map((nft, idx, nfts) => (
                    (idx < 3) ? (
                        <HomeCollectionItem nft={nft}></HomeCollectionItem>
                    ) : (
                        <></>
                    )
                ))}

                {collection?.nfts.length % 3 > 1 &&
                    <Col xs={12} md={4} className='mt-3'>
                    </Col>
                }
                {collection?.nfts.length % 3 > 0 &&
                    <>
                        <Col xs={12} md={4} className='mt-3'>
                        </Col>
                        <Col xs={12} md={4} className='mt-3'>
                        </Col>
                    </>
                }
            </Row>
            {collection?.code == settings.collections.codes.interventions &&
                <Row className="align-items-center r-mob">
                    <Col xs={12} md={12}>
                        <Row className='align-items-center'>
                            <Col xs={7} md={3} className='text-end'>
                                <Button className='btn-home collection mt-2 custom-m' onClick={() => { goToIntervention(collection) }}>{t('home.buttons.iIntervened')}</Button>
                            </Col>
                            <Col xs={5} md={3} className='text-end'>
                                <Button className='btn-home create mt-2 custom-m' variant='outline' onClick={() => { goToCollection(collection) }}>{t('home.buttons.seeAll')}</Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            }
            {collection?.code != settings.collections.codes.interventions &&
                <Row className="align-items-center r-mob">
                    <Col xs={12} md={12}>
                        <Row className='align-items-center'>

                            <Col xs={12} md={4} className='text-end'>
                                <Button variant='outline-danger custom-m' onClick={() => { goToCollection(collection) }}>{t('home.buttons.seeCollection')}</Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            }
        </>
    );



}

export default HomeCollection;
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import BaseComponent from '../../core/BaseComponent';
import { Navigate } from "react-router-dom";
import { withNavigation, withI18n, withAppGlobal } from '../../core/BaseComponent';

import qrInter from '../../assets/imgs/qr-inter.png';
import eth from '../../assets/imgs/eth.png';
import avatar from '../../assets/imgs/avatar.png';
import person from '../../assets/imgs/person.jpeg';
import desma from '../../assets/imgs/desma.png';
import qr from '../../assets/imgs/qr.png';
import qrBorder from '../../assets/imgs/qr-border.png';
import { propTypes } from 'react-bootstrap/esm/Image';
import NftItem from '../nft-item/NftItem';

import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCards } from "swiper";


// (+) Common
import AppSettings from '../../AppSettings';
import React, { Component, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import PageService from '../../core/PageService';
import AppGlobal from '../../context/AppGlobal';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
// (-) Common


const HomeCollectionItem = ({ nft }) => {

    // (+) Common
    const { global, addGlobal } = useContext(AppGlobal);
    const user = useSelector((state) => state.user.value)
    const dispatch = useDispatch()
    const pageService = new PageService({
        appGlobal: {
            global: global,
            addGlobal: addGlobal
        }
    });
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { settings } = AppSettings();
    const [loading, setLoading] = useState(true);
    const [initialized, setInitialized] = useState(false);
    // (-) Common

    const [nftOwnerPicture, setNftOwnerPicture] = useState(avatar);
    const [nftCreatorPicture, setNftCreatorPicture] = useState(avatar);
    const [nftOwnerName, setNftOwnerName] = useState();
    const [nftCreatorName, setNftCreatorName] = useState();



    const getUserPicture = (userKey) => {
        if (nft[userKey].picture)
            return pageService.settings.images.url + '/' + nft[userKey].picture;
        else
            return avatar;
    }

    const getUserName = (userKey) => {
        if (nft[userKey].firstName) {
            return nft[userKey].firstName + ' ' + nft[userKey].lastName;
        } else {
            if (nft[userKey].address) {
                const address = nft[userKey].address;
                return address.substr(0, 6) + '...' + address.substr(-4);
            } else {
                return "0x4A4C3b4...TEST";
            }
        }
    }

    const goToNftShop = (nft) => {
        navigate(`/nft-shop/${nft.id}` );
    }

    useEffect(() => {
        if (!getUserPicture || !getUserName || !nft) return;
        // setNftOwnerPicture(getUserPicture('owner'));
        // setNftOwnerName(getUserName('creator'));
        setNftCreatorPicture(getUserPicture('creator'));
        setNftCreatorName(getUserName('creator'));
    }, [nft, getUserName, getUserPicture]);


    return (

        <Col xs={12} md={4} className='mt-4 ps-4 pe-4 r-mobile-mt'>
            <Swiper
                effect={"cards"}
                grabCursor={true}
                modules={[EffectCards]}
                onClick={() => goToNftShop(nft)}
            >
                <SwiperSlide>
                    <img src={pageService.settings.images.url + '/sm/' + nft.file} className='w-100 shop-img' alt="" />
                </SwiperSlide>
                {nft.file2 &&
                <SwiperSlide>
                    <img src={pageService.settings.images.url + '/sm/' + nft.file2} className='w-100 shop-img' alt="" />
                </SwiperSlide>
                }
            </Swiper>
            <Row className='mt-3 ps-4 pe-4'>
                <h4 className='m-0'>{nft.name}</h4>
            </Row>
            <Row className='ps-4 pe-4'>
                {/* <Col xs={6} md={6}>
                    <p className='text-left m-1 subtitle'>CREADOR</p>
                    <p className='text-left m-1 subtitle'>PROPIETARIO</p>
                    <p className='m-1 mt-3 price-shop'>Precio</p>
                </Col> */}
                <Col xs={12} md={12} className='mt-2'>
                    <p className='text-right m-0'>
                        <img src={nftCreatorPicture} className='avatar' alt="" />
                        <span className='name'>{nftCreatorName}</span>
                    </p>
                    {/* <p className='text-right'>
                        <img src={nftOwnerPicture} className='avatar' alt="" />
                        <span className='name'>{nftOwnerName}</span>
                    </p> */}

                    {/* <p className='text-right mb-1'>
                        <img src={eth} width='10px' alt="" />
                        <span className='price-eth'>{nft.price / 1000}</span>
                    </p> */}

                </Col>
            </Row>
        </Col>

    );



}

export default HomeCollectionItem;
import React, { useContext, useEffect, useRef, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Button, Accordion, Form, Placeholder, Dropdown, Carousel } from 'react-bootstrap';
import { ChromePicker } from 'react-color';
import './NftDoubleIntervention.css';
import { MdUpload } from 'react-icons/md';
import Loader from './Loader';
import HttpService from '../../services/HttpService';
import InputRange from 'react-input-range';
import { QRCode } from 'react-qrcode-logo';
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import { useTranslation, Trans } from 'react-i18next';
import { useParams } from 'react-router-dom';
// import { useDrop } from 'react-dnd';
// import { NativeTypes } from 'react-dnd-html5-backend';

// + IMAGES +
import placeholder from '../../assets/imgs/nft_placeholder.png';
import btnColor from '../../assets/imgs/btn-color.png';
// - IMAGES -

import domtoimage from 'dom-to-image';
import { BsZoomIn, BsZoomOut } from 'react-icons/bs'
import Draggable from 'react-draggable';
import * as svgScale from 'scale-svg-path';
import * as svgParse from 'parse-svg-path';
import * as svgSerialize from 'serialize-svg-path';


import AppSettings from '../../AppSettings';
import PageService from '../../core/PageService';
import AppGlobal from '../../context/AppGlobal';
import { useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";
import useBordes from "../../hooks/useBordes";
import { useWeb3React } from '@web3-react/core';

const NftDoubleIntervention = () => {

    const canvasSize = window.innerWidth > 1200 ? {
        code: 'm',
        pixels: 500
    } : {
        code: 's',
        pixels: 250
    };

    const [draggablePosition, setDraggablePosition] = useState({ x: 0, y: 0 });
    const handleDrag = (event, { x, y }) => setDraggablePosition({ x, y });

    const { global, addGlobal } = useContext(AppGlobal);
    const pageService = new PageService({
        appGlobal: {
            global: global,
            addGlobal: addGlobal
        }
    });
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { collectionId } = useParams();
    const bordes = useBordes();
    const { active, account } = useWeb3React();

    const { settings } = AppSettings();


    const [item, setItem] = useState(false);
    setTimeout(() => setItem(true), 500);

    const [state, setState] = useState(false);
    const [stateForm, setStateForm] = useState(false);

    // const nftRef = useRef();
    const wrapperRef = useRef(null);
    const imageRef = useRef(null);
    const imageRefNft = useRef(null);
    const divImageRef = useRef(null);
    const [qrImage, setQrImage] = useState();
    const [image, setImage] = useState();
    const [fileOrigin, setFileOrigin] = useState();
    const [fileTarget, setFileTarget] = useState();
    const [qrStyle, setQrStyle] = useState(settings.qrStyle.dots);
    const [zoom, setZoom] = useState(1);
    const [opacity, setOpacity] = useState(100);
    const [brightness, setBrightness] = useState(100);
    const [backgroundCanvas, setBackgroundCanvas] = useState(settings.backgroundCanvas.default.code);
    const [backgroundCanvasColor, setBackgroundCanvasColor] = useState('#772727');
    const [qrColor, setQrColor] = useState('#772727');
    const [canCreate, setCanCreate] = useState(false);
    const [nftClipPath, setNftClipPath] = useState(settings.backgroundCanvas[backgroundCanvas].path);
    const[ClipPath, setClipPath] = useState(settings.backgroundCanvas[backgroundCanvas].path);
    const [nftSize, setNftSize] = useState(canvasSize.pixels);
    const[sizeImgPreviewCont, setSizeImgPreviewCont] = useState(canvasSize.pixels);
    const [showNft, setShowNft] = useState(false);

    function getScaledSvg(imgSize) {
        const path = svgParse(settings.backgroundCanvas[backgroundCanvas].path);
        const scale = imgSize / settings.backgroundCanvas[backgroundCanvas].pathSize;
        const xy = svgScale(path, scale, scale);
        const serialize = svgSerialize(xy);
        return serialize;
    }
    
    const applyCanvas = (imgSize) => {
        const svg = getScaledSvg(imgSize);
        setClipPath(svg);
    }

    const applyCanvasNft = (imgSize) => {
        const svg = getScaledSvg(imgSize);
        setNftClipPath(svg);
    }

    useEffect(() => {
        applyCanvas(sizeImgPreviewCont);
        applyCanvasNft(nftSize);
    }, [backgroundCanvas]);


    /////////////////////////////////////////////////////////////////////
    /////// (+) (+) (+) (+) (+) (+) (+) (+) (+) (+) (+) (+) (+)   ///////
    /////////////////////////////////////////////////////////////////////

    const { register, handleSubmit } = useForm({ shouldUseNativeValidation: true });
    const onSubmit = async data => {
        console.log(data);
    };

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    const handleBack = () => {
        navigate(-1);
    }

    useEffect(() => {
        if (bordes && bordes.bordes && active) {
            setCanCreate(true);
        } else {
            setCanCreate(false);
        }
    }, [bordes, active]);


    const handleNFT = async (params = {}) => {

        if (!params.elId) params.elId = 'nft';
        const element = document.querySelector('#' + params.elId);
        if (!element) return;

        const path = svgParse(settings.backgroundCanvas[backgroundCanvas].path);
        const scale = nftSize / settings.backgroundCanvas[backgroundCanvas].pathSize;
        const xy = svgScale(path, scale, scale);
        const serialize = svgSerialize(xy);
        setNftClipPath(serialize);

        if (!params.scale) params.scale = 1;
        const elWidth = element.offsetWidth, elHeight = element.offsetHeight;
        const style = params.scale > 1 ? {
            'transform': 'scale(' + params.scale + ')',
            'transform-origin': 'top left'
        } : {};
        const options = {
            style,
            width: elWidth * params.scale,
            height: elHeight * params.scale
        };

        const dataURL = await domtoimage.toPng(element, options);
        if (!dataURL) return;

        const A = document.createElement('a');
        A.href = dataURL;
        A.download = 'NFT.png';
        A.click();
    };


    const handleSaveSubmit = async data => {

        // TODO descomentar
        // if(!canCreate) {
        //     pageService.showError(t('nftIntervention.alerts.login'));
        //     return;
        // }

        if (!fileTarget) {
            pageService.showError(t('nftIntervention.alerts.image'));
            return;
        }


        ////////////////////////
        let params = {};

        if (!params.elId) params.elId = 'nft';
        const element = document.querySelector('#' + params.elId);
        if (!element) return;

        const path = svgParse(settings.backgroundCanvas[backgroundCanvas].path);
        const scale = nftSize / settings.backgroundCanvas[backgroundCanvas].pathSize;
        const xy = svgScale(path, scale, scale);
        const serialize = svgSerialize(xy);
        setNftClipPath(serialize);

        if (!params.scale) params.scale = 1;
        const elWidth = element.offsetWidth, elHeight = element.offsetHeight;
        const style = params.scale > 1 ? {
            'transform': 'scale(' + params.scale + ')',
            'transform-origin': 'top left'
        } : {};
        const options = {
            style,
            width: elWidth * params.scale,
            height: elHeight * params.scale
        };

        const file = await domtoimage.toBlob(element, options);
        if (!file) return;

        pageService.showLoading();
        pageService.httpPostFormData({
            endPoint: pageService.settings.api.endPoints.filesUpload,
            formData: {
                file: file
            }
        }).then(result => {

            console.log("------------");
            console.log(result);
            console.log("------------");

            setFileOrigin(result.data);

            let nft = data;
            nft.code = getImageCode(result.data.file);
            nft.code2 = getImageCode(fileTarget.file);
            nft.file = result.data.file;
            nft.file2 = fileTarget.file;
            nft.nftCollection = collectionId;
            if (global.user) {
                nft.creator = global.user.id;
                nft.owner = global.user.id;
            } else {
                nft.creator = '634b1a786f803515d416f3b5';
                nft.owner = '634b1a786f803515d416f3b5';
            }
            nft.type = pageService.settings.collectionTypes.doubleIntervention;

            // TODO quitar
            // quitar
            nft.status = pageService.settings.nfts.status.marketplace;

            console.log(nft);

            pageService.showLoading();
            pageService.httpPost({
                endPoint: pageService.settings.api.endPoints.nfts,
                values: nft
            }).then(result => {
                console.log(result.data);
                pageService.showSuccess(t('nftIntervention.alerts.mintProduction'));
                handleBack();

                // TODO descomentar
                //handleMintSubmit(result.data);
            })
                .catch(error => {
                    pageService.showError(error);
                })
                .finally(() => pageService.hideLoading());

        })
            .catch(error => {
                pageService.showError(error);
            })
            .finally(() => pageService.hideLoading());



    }

    const getImageCode = (imageName) => {
        //return imageName.split('.')[0].split('_')[1];
        return imageName;
    }

    const handleFileTargetDrop = (e) => {

        const file = e.target.files[0];
        if (!file) return;

        pageService.showLoading();
        pageService.httpPostFormData({
            endPoint: pageService.settings.api.endPoints.filesUpload,
            formData: {
                file: file
            }
        }).then(result => {
            if (result.data.file) {
                setImage(pageService.settings.images.url + '/' + result.data.file);
                setQrImage(pageService.settings.nfts.url + '/' + getImageCode(result.data.file));
                setFileTarget(result.data);
            }
        })
            .catch(error => {
                pageService.showError(error);
            })
            .finally(() => pageService.hideLoading());

    }


    const handleMintSubmit = (nft) => {

        if (!canCreate) {
            pageService.showError(t('nftIntervention.alerts.login'));
            return;
        }

        const attrDesc = 'description_' + global.user.language;

        const nftToMint = {
            name: nft.name,
            description: nft[attrDesc],
            image: "ipfs://" + nft.ipfs
        };

        let base64data = btoa(JSON.stringify(nftToMint));
        console.log("mint");
        bordes.bordes.methods.mint(account, `data:application/json;base64,${base64data}`, nft.number, Math.round(nft.fee * 100)).send({
            from: account
        })
            .on("transactionHash", (txHash) => {
                pageService.showSuccess(t('nftIntervention.alerts.mintProduction'));
            })
            .on("receipt", () => {
                pageService.showSuccess(t('nftIntervention.alerts.transactionConfirm'));

                pageService.showLoading();
                pageService.httpPut({
                    endPoint: pageService.settings.api.endPoints.nfts + '/' + nft.id,
                    values: {
                        status: pageService.settings.nfts.status.minted
                    }
                }).then(result => {
                    //this.setState({nft:result.data});
                    pageService.showSuccess(t('nftIntervention.alerts.mintProduction'));
                    handleBack();
                })
                    .catch(error => {
                        pageService.showError(error);
                    })
                    .finally(() => pageService.hideLoading());
            })
            .on("error", (error) => {
                pageService.showError(error);
            });
    }


    /////////////////////////////////////////////////////////////////////
    /////// (-) (-) (-) (-) (-) (-) (-) (-) (-) (-) (-) (-) (-)   ///////
    /////////////////////////////////////////////////////////////////////


    // const handleFileDrop = async e => {

    //     const file = e.target.files[0];
    //     if (!file) return;

    //     const response = await HttpService.postFormDataFile(file);

    //     if (response.data.file) setImage(process.env.REACT_APP_FILES_URL + '/' + response.data.file);

    // }


    // const exportAsImage = async (el, imageFileName) => {
    //     const canvas = await html2canvas(el);
    //     const image = canvas.toDataURL("image/png", 1.0);
    //     downloadImage(image, imageFileName);
    // };

    // const downloadImage = (blob, fileName) => {
    //     const fakeLink = window.document.createElement("a");
    //     fakeLink.style = "display:none;";
    //     fakeLink.download = fileName;

    //     fakeLink.href = blob;

    //     document.body.appendChild(fakeLink);
    //     fakeLink.click();
    //     document.body.removeChild(fakeLink);

    //     fakeLink.remove();
    // };


    // const handleFileTargetDrop = (e) => {
    //     const file = e.target.files[0];
    //     if (!file) return;
    //     handleFileDrop('fileTarget', file);
    // }

    const handleFileDrop = (key, file) => {
        pageService.showLoading();
        pageService.httpPostFormData({
            endPoint: pageService.settings.api.endPoints.nftsUpload,
            formData: {
                file: file
            }
        }).then(result => {
            console.log(result);
            if (result.data.file) {
                // let params = {};
                // params[key] = result.data;
                // params[key].src = process.env.REACT_APP_FILES_URL + '/' + result.data.file;
                // console.log(params);
                //this.setState(params);

                if (key == 'fileTarget') {
                    setImage(pageService.settings.images.url + '/' + result.data.file);
                    setFileTarget(result.data);
                } else {
                    setFileOrigin(result.data);
                    handleSaveSubmit();
                }
            }
        })
            .catch(error => {
                pageService.showError(error);
            })
            .finally(() => pageService.hideLoading());

    }

    const handleImage = () => {
        if (imageRef.current) {
          const imgCont = document.querySelector('#img-preview-cont');
          const width = imgCont.offsetWidth;
          const height = imgCont.offsetHeight;
          const minSize = Math.min(width, height);
          setSizeImgPreviewCont(minSize);
          applyCanvas(minSize);
        }
      }
    
      const handleImageNft = () => {
        if (imageRef.current) {
          const imgNaturalWidth = imageRef.current.naturalWidth;
          const imgNaturalHeight = imageRef.current.naturalHeight;
          const minSize = Math.min(imgNaturalWidth, imgNaturalHeight);
          setNftSize(minSize);
          applyCanvasNft(minSize);
        }
      }

    const handleImageOld = () => {
        if (imageRef.current) {
            const isHeightGreater = imageRef.current.height > imageRef.current.width;

            imageRef.current.classList.add(isHeightGreater ? 'w-100' : 'h-100');
            imageRef.current.classList.remove(isHeightGreater ? 'h-100' : 'w-100');

            if (divImageRef.current) divImageRef.current.classList.add(isHeightGreater ? 'w-100' : 'h-100');
            if (divImageRef.current) divImageRef.current.classList.remove(isHeightGreater ? 'h-100' : 'w-100');
        }
    }

    const onDragEnter = () => wrapperRef.current.classList.add('dragover');
    const onDragLeave = () => wrapperRef.current.classList.remove('dragover');
    const onDrop = () => wrapperRef.current.classList.remove('dragover');

    const handleNFTOld = async () => {
        const element = document.querySelector('#nft');
        if (!element) return;

        const dataURL = await domtoimage.toBlob(element, { height: 500, width: 500 });
        if (!dataURL) return;

        const A = document.createElement('a');
        A.href = dataURL;
        A.download = 'NFT.png';
        A.click();

        handleFileDrop('fileOrigin', dataURL);

    };

    const handleFormClick = () => {
        setStateForm({ stateForm: !stateForm })
    };

    const handleFormClose = () => {
        setStateForm(false)
    };

    const handleClick = () => {
        setState({ state: !state })
    };

    const handleClose = () => {
        setState(false)
    };

    const popover = {
        position: 'absolute',
        zIndex: '2',
    }
    const cover = {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
    }

    function getQrScale() {
        if (backgroundCanvas == 'default') return 0.8;
        else if (backgroundCanvas == 'round') return 0.75;
        else if (backgroundCanvas == 'rectangular') return 0.68;
        else return 0.8;
      }

    return (
        <Container className='pb-5'>
            {/* LOADER */}
            {!item &&
                <Loader />
            }
            {/* LOADER */}

            {item &&
                <>
                    <Row className='mt-5'>
                        <h1 className='title'>{t('nftIntervention.labels.title')}</h1>
                    </Row>

                    <Row className='overflow-hidden justify-content-center'>
                        <Col xs={12} md={6}>
                            <Accordion defaultActiveKey='0' className='accordion mt-3' flush>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>{t('nftIntervention.labels.customize')}</Accordion.Header>
                                    <Accordion.Body>
                                        <p>{t('nftIntervention.labels.canvas')}</p>
                                        <Row className='align-items-center'>
                                            <Col>
                                                <span>{t('nftIntervention.labels.available')}</span>
                                            </Col>
                                            <Col className='align-right'>
                                                <Button variant='clear' onClick={handleClick}>
                                                    <img src={btnColor} alt="" />
                                                </Button>
                                                {state ? <div style={popover} className="customResponsivePoper">
                                                    <div style={cover} onClick={handleClose} />
                                                    <ChromePicker color={backgroundCanvasColor} onChange={(color) => setBackgroundCanvasColor(color.hex)} />
                                                </div> : null}
                                            </Col>
                                        </Row>
                                        <Row className='align-items-center justify-content-center ps-3'>
                                            <Swiper
                                                slidesPerView={3.7}
                                                spaceBetween={30}
                                                pagination={{
                                                    clickable: true,
                                                }}
                                                modules={[Navigation]}>
                                                {
                                                    Object.values(settings.backgroundCanvas).map(canvas =>
                                                        <SwiperSlide key={canvas.code}>
                                                            <svg className='select-svg' onClick={() => setBackgroundCanvas(canvas.code)} id="Capa_1" width='200' height="200" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 586 586"><defs><style>.cls-1{'fill:#ae1917;'}</style></defs><path fill={backgroundCanvasColor} className="cls-1" d={canvas.path} /></svg>
                                                        </SwiperSlide>
                                                    )
                                                }
                                            </Swiper>
                                        </Row>

                                        <hr className='separetor' />

                                        <Row className='align-items-center'>
                                            <Col>
                                                <span>QR</span>
                                            </Col>
                                            <Col className='align-right'>
                                                <Button variant='clear' onClick={handleFormClick}>
                                                    <img src={btnColor} alt="" />
                                                </Button>
                                                {stateForm ? <div style={popover} className="customResponsivePoper">
                                                    <div style={cover} onClick={handleFormClose} />
                                                    <ChromePicker className='customChromePicker' color={qrColor} onChange={(color) => setQrColor(color.hex)} />
                                                </div> : null}
                                            </Col>
                                        </Row>

                                        <Form>
                                            <Row className='align-items-center mt-4'>
                                                <Col xs={3} md={2}>
                                                    <span>{t('nftIntervention.labels.shapes')}</span>
                                                </Col>
                                                <Col xs={2} md={1}>
                                                    <Form.Check
                                                        type='radio' id='circle' name='qrStyle'
                                                        checked={qrStyle.code === settings.qrStyle.dots.code}
                                                        aria-label='circle' onChange={() => setQrStyle(settings.qrStyle.dots)} />
                                                </Col>
                                                <Col xs={2} md={2} className='align-left p-0'>
                                                    <Form.Label htmlFor='circle'>
                                                        <svg width="50" height="50">
                                                            <circle cx="20" cy="20" r="20" stroke-width="6" fill={qrColor} />
                                                        </svg>
                                                    </Form.Label>
                                                </Col>

                                                <Col xs={2} md={1}>
                                                    <Form.Check
                                                        type='radio' id='square' name='qrStyle'
                                                        checked={qrStyle.code === settings.qrStyle.square.code}
                                                        aria-label='square' onChange={() => setQrStyle(settings.qrStyle.square)} />
                                                </Col>
                                                <Col xs={2} md={2} className='align-left p-0'>
                                                    <Form.Label htmlFor='square'>
                                                        <svg width="50" height="50">
                                                            <rect width="40" height="40" stroke-width="6" fill={qrColor} />
                                                        </svg>
                                                    </Form.Label>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>

                            <Accordion defaultActiveKey='0' className='accordion mt-3' flush>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>{t('nftIntervention.labels.photo')}</Accordion.Header>
                                    <Accordion.Body>
                                        <Row>
                                            <Col xs={6} md={6}>
                                                <div className='upload' ref={wrapperRef} onDragEnter={onDragEnter} onDragLeave={onDragLeave} onDrop={onDrop}>
                                                    <Row className='justify-content-center'>
                                                        <p className='text-center'>{t('nftIntervention.labels.upload')}</p>
                                                    </Row>
                                                    <Row className='justify-content-center'>
                                                        <MdUpload className='upload-icon' />
                                                    </Row>
                                                    <input type="file" className='fileInput' onChange={handleFileTargetDrop} />
                                                </div>
                                            </Col>
                                            <Col xs={6} md={6} className='text-center'>
                                                <div className='position-relative d-flex justify-content-center'>

                                                    {
                                                        !image && <Col md={12}>
                                                            <Placeholder animation="glow">
                                                                <Placeholder id='image-placeholder' md={12} xs={12} />
                                                            </Placeholder>
                                                        </Col>
                                                    }

                                                    {
                                                        image && <>
                                                            <img src={image} className="img-fluid" style={{ objectFit: 'contain' }} alt="" />
                                                        </>
                                                    }

                                                </div>
                                            </Col>
                                        </Row>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>

                            <Accordion defaultActiveKey='0' className='accordion mt-3 d-none d-md-block' flush>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>{t('nftIntervention.labels.zoom')}</Accordion.Header>
                                    <Accordion.Body>
                                        <Row className='ps-3 pe-3'>
                                            <Col></Col>
                                            <Col xs={12} md={12}>
                                                <p>Zoom</p>
                                                <InputRange maxValue={3.00} step={0.05} minValue={0.30} value={zoom} onChange={value => setZoom(parseFloat(value.toFixed(2)))} formatLabel={value => `${value.toFixed(2)}`} draggableTrack={false} />
                                            </Col>
                                        </Row>

                                        <Row className='mt-5 ps-3 pe-3'>
                                            <Col xs={12} md={12}>
                                                <p>{t('nftIntervention.labels.opacity')}</p>
                                                <InputRange maxValue={100} minValue={0} value={opacity} onChange={value => setOpacity(value)} formatLabel={value => `${value}%`} draggableTrack={false} />
                                            </Col>
                                        </Row>

                                        <Row className='mt-5 ps-3 pe-3'>
                                            <Col xs={12} md={12}>
                                                <p>{t('nftIntervention.labels.brightness')}</p>
                                                <InputRange maxValue={100} minValue={0} value={brightness} onChange={value => setBrightness(value)} formatLabel={value => `${value}%`} draggableTrack={false} />
                                            </Col>
                                        </Row>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>

                        </Col>
                        <Col xs={12} md={6}>
                            <h3 className='align-right prev mt-3 labels text-center'>{t('labels.preview')}</h3>

                            <div id="img-preview-cont" className='position-relative m-auto justify-content-center d-flex image-nft' style={{ width: canvasSize.pixels + 'px', height: canvasSize.pixels + 'px', overflow: 'hidden', alignItems: 'center', clipPath: `path("${ClipPath}")`, backgroundColor: backgroundCanvasColor }}>
                                {
                                    !image && <Col md={12}>
                                        <Placeholder animation="glow">
                                            <Placeholder id='nft-placeholder' xs={12} size="lg" />
                                        </Placeholder>
                                    </Col>
                                }

                                {
                                    image && <>
                                        {/* <div style={{ transform: `scale(${zoom})`, height: '100%' }}> */}
                                        <div style={{ transform: `scale(${zoom})` }} ref={divImageRef}>
                                            <Draggable scale={zoom} onDrag={handleDrag}>
                                                <img id="img-preview" ref={imageRef} onLoad={handleImage} src={image} draggable="false" style={{ opacity: `${opacity}%`, filter: `brightness(${brightness}%)` }} className='pe-auto drag' alt="" />
                                            </Draggable>
                                        </div>

                                        <div className='position-absolute d-flex justify-content-center align-items-center w-100 h-100 top-0 start-0 pe-none'>
                                            <QRCode value={image} size={canvasSize.pixels * getQrScale()} fgColor={qrColor} bgColor='transparent' qrStyle={qrStyle.code} eyeRadius={qrStyle.radius} ecLevel='H'></QRCode>
                                        </div>
                                    </>
                                }
                            </div>

                            {/* <div id='nft' className='position-relative ms-auto justify-content-center d-flex image-nft' style={{ width: '510px', height: '510px', overflow: 'hidden', alignItems: 'center', clipPath: `path("${settings.backgroundCanvas[backgroundCanvas].path}")`, backgroundColor: backgroundCanvasColor }}>
                                {
                                    !image && <Col md={12}>
                                        <Placeholder animation="glow">
                                            <Placeholder id='nft-placeholder' xs={12} size="lg" />
                                        </Placeholder>
                                    </Col>
                                }

                                {
                                    image && 
                                    <>
                                        <div style={{ transform: `scale(${zoom})` }} ref={divImageRef}>
                                            <Draggable scale={zoom}>
                                                <img ref={imageRef} onLoad={handleImage} src={image} draggable="false" style={{ opacity: `${opacity}%`, filter: `brightness(${brightness}%)` }} className='pe-auto drag' alt="" />
                                            </Draggable>
                                        </div>

                                        <div className='position-absolute d-flex justify-content-center align-items-center w-100 h-100 top-0 start-0 pe-none'>
                                            <QRCode value={qrImage} size={400} fgColor={qrColor} bgColor='transparent' qrStyle={qrStyle.code} eyeRadius={qrStyle.radius} ecLevel='H'></QRCode>
                                        </div>
                                    </>
                                }
                            </div> */}
                            {/* <Row className='justify-content-end mt-4'>
                            <Col xs={12} md={10}>
                            </Col>
                            </Row> */}

                            <div className='d-md-none ps-3 pe-4'>
                                <Row className='mt-5'>
                                    <Col xs={12} md={10}>
                                        <p>Zoom</p>
                                        <InputRange maxValue={3.00} step={0.05} minValue={0.30} value={zoom} onChange={value => setZoom(parseFloat(value.toFixed(2)))} formatLabel={value => `${value.toFixed(2)}`} draggableTrack={false} />
                                    </Col>
                                </Row>

                                <Row className='mt-5'>
                                    <Col xs={12} md={5}>
                                        <p>{t('nftIntervention.labels.opacity')}</p>
                                        <InputRange maxValue={100} minValue={0} value={opacity} onChange={value => setOpacity(value)} formatLabel={value => `${value}%`} draggableTrack={false} />
                                    </Col>
                                </Row>

                                <Row className='mt-5'>
                                    <Col xs={12} md={5}>
                                        <p>{t('nftIntervention.labels.brightness')}</p>
                                        <InputRange maxValue={100} minValue={0} value={brightness} onChange={value => setBrightness(value)} formatLabel={value => `${value}%`} draggableTrack={false} />
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>

                    <Form className='mt-5'>
                        <Row>
                            <Col xs={12} md={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label className='text-danger'>{t('nftIntervention.form.name')}</Form.Label>
                                    <Form.Control className='control' type="text" placeholder={t('nftIntervention.form.name')} {...register("name", { required: "Por favor ingrese el nombre." })} />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label className='text-danger'>{t('nftIntervention.form.descriptionEs')}</Form.Label>
                                    <Form.Control className='control' as="textarea" rows={3} placeholder={t('nftIntervention.form.writeHere')} {...register("description_es", { required: "Por favor ingrese la descripción en español." })} />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label className='text-danger'>Tags</Form.Label>
                                    <Form.Control className='control' type="text" placeholder="Example"  {...register("tags", { required: "Por favor ingrese los tags." })} />
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label className='text-danger'>{t('nftIntervention.form.descriptionEn')}</Form.Label>
                                    <Form.Control className='control' as="textarea" rows={3} placeholder={t('nftIntervention.form.writeHere')}  {...register("description_en", { required: "Por favor ingrese la descripción en ingles." })} />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label className='text-danger'>{t('nftIntervention.form.price')}</Form.Label>
                                    <Form.Control className='control' type="number" placeholder="000000"  {...register("price", { required: "Por favor ingrese el precio." })} />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label className='text-danger'>{t('nftIntervention.form.fee')}</Form.Label>
                                    <Form.Control className='control' type="number" placeholder="1.5"  {...register("fee", { required: "Por favor ingrese el fee." })} />
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row className='mt-3'>
                            <Col xs={12} md={6}></Col>
                            <Col xs={12} md={6}>
                                <Row className='justify-content-end'>
                                    <Col xs={12} md={6}>
                                        <Button variant='outline' className='d-block w-100 create mt-3' type='button' onClick={handleBack}>{t('buttons.back')}</Button>
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <Button variant='danger' className='d-block w-100 mt-3' type='button' onClick={handleSubmit(handleSaveSubmit)}>{t('buttons.create')}</Button>
                                        {/* <Button variant='danger' className='d-block w-100 mt-2' type='button' onClick={handleNFT}>{t('buttons.create')}</Button> */}
                                    </Col>
                                    {/* <Col xs={12} md={4}>
                                        <Button variant='danger' className='d-block w-100 mt-2' type='button'  onClick={handleNFT}>ACEPTAR</Button>
                                    </Col> */}
                                </Row>
                            </Col>
                        </Row>
                    </Form>


                    <div style={{ width: '100%', overflow: `${showNft ? 'visible' : 'hidden'}`, height: `${showNft ? 'auto' : '0px'}` }}>

                        <div id='nft' className='position-relative justify-content-center d-flex image-nft'
                            style={{ width: `${nftSize}px`, height: `${nftSize}px`, overflow: 'hidden', alignItems: 'center', clipPath: `path("${nftClipPath}")`, backgroundColor: backgroundCanvasColor }}>
                            {
                                !image && <Col md={12}>
                                    <Placeholder animation="glow">
                                        <Placeholder id='nft-placeholder' xs={12} size="lg" />
                                    </Placeholder>
                                </Col>
                            }

                            {
                                image && <>
                                    {/* <div style={{ transform: `scale(${zoom})`, height: '100%' }}> */}
                                    <div style={{ transform: `scale(${zoom * (nftSize / canvasSize.pixels)})` }} ref={divImageRef}>
                                        <Draggable scale={zoom * (nftSize / canvasSize.pixels)} position={draggablePosition}>
                                            <img id="img-nft" ref={imageRefNft} onLoad={handleImageNft} src={image} draggable="false" style={{ opacity: `${opacity}%`, filter: `brightness(${brightness}%)` }} className='pe-auto drag' alt="" />
                                        </Draggable>
                                    </div>

                                    <div className='position-absolute d-flex justify-content-center align-items-center w-100 h-100 top-0 start-0 pe-none'>
                                        <QRCode value={image} size={(nftSize * getQrScale())} fgColor={qrColor} bgColor='transparent' qrStyle={qrStyle.code} eyeRadius={qrStyle.radius} ecLevel='H'></QRCode>
                                    </div>
                                </>
                            }
                        </div>

                    </div>

                </>
            }
        </Container >
    );
}

export default NftDoubleIntervention;
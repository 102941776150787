import 'bootstrap/dist/css/bootstrap.min.css';
import './NftShop.css';
import { Row, Col, Placeholder } from 'react-bootstrap';

const Loader = () => {
    return (
        <>
            <Row className='mt-5'>
                <Col xs={12} md={4}>
                    <Placeholder animation="glow">
                        <Placeholder className='img-placeholder' xs={12} size="lg" />
                    </Placeholder>

                    <Placeholder as="p" animation="glow">
                        <Placeholder xs={6} size='md' />
                    </Placeholder>

                    <Placeholder as="p" animation="glow" className='header-placeholder'>
                        <Placeholder xs={2} size='md' />
                        <Placeholder xs={3} size='md' />
                    </Placeholder>

                    <Placeholder animation="glow">
                        <Placeholder className='table-placeholder mt-3' xs={12} size="lg" />
                    </Placeholder>

                    <Placeholder animation="glow">
                        <Placeholder className='table-placeholder mt-3' xs={12} size="lg" />
                    </Placeholder>
                </Col>
                <Col xs={12} md={1}></Col>
                <Col xs={12} md={7}>
                    <Placeholder as="p" animation="glow" className='header-placeholder'>
                        <Placeholder xs={2} size='md' />
                        <Placeholder xs={2} size='md' />
                    </Placeholder>

                    <Placeholder as="p" animation="glow">
                        <Placeholder xs={6} size='lg' />
                    </Placeholder>

                    <Placeholder as="p" animation="glow">
                        <Placeholder xs={12} size='sm' />
                    </Placeholder>

                    <Placeholder as="p" className='mt-5' animation="glow">
                        <Placeholder xs={3} size='sm' />
                    </Placeholder>

                    <Placeholder as="p" animation="glow">
                        <Placeholder xs={4} size='lg' />
                    </Placeholder>

                    <Placeholder as="p" animation="glow" className='header-placeholder'>
                        <Placeholder.Button variant='medium' className='mt-2' xs={5} />
                        <Placeholder.Button variant='medium' className='mt-2' xs={5} />
                    </Placeholder>

                    <Placeholder animation="glow">
                        <Placeholder className='table-placeholder mt-4' xs={12} size="lg" />
                    </Placeholder>

                    <Placeholder animation="glow">
                        <Placeholder className='chart-placeholder mt-4' xs={12} size="lg" />
                    </Placeholder>
                </Col>
            </Row>

            <Placeholder animation="glow">
                <Placeholder className='table-placeholder mt-4' xs={12} size="lg" />
            </Placeholder>
        </>
    );
}

export default Loader;
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Nav, NavDropdown, Badge, Form, Button, Table, Modal, Image } from 'react-bootstrap';
import Loader from './Loader';
import './User.css';
import { Navigate } from "react-router-dom";
import { withNavigation, withI18n, withAppGlobal } from '../../core/BaseComponent';

// + IMAGES +
import qrInter from '../../assets/imgs/qr-inter.png';
import eth from '../../assets/imgs/eth.png';
import avatar from '../../assets/imgs/avatar.png';
import person from '../../assets/imgs/person.jpeg';
import desma from '../../assets/imgs/desma.png';
import qrBorder from '../../assets/imgs/qr-border.png';
// - IMAGES -

import { GoPencil, GoDeviceCamera } from 'react-icons/go';
import { FiTrash } from 'react-icons/fi';
import { FaRegEye, FaCheck, FaCloudMeatball } from 'react-icons/fa';
import { IoMdAdd } from 'react-icons/io';
import { RiCloseFill } from 'react-icons/ri';


import AppSettings from '../../AppSettings';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import PageService from '../../core/PageService';
import AppGlobal from '../../context/AppGlobal';
import { useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { useWeb3React } from '@web3-react/core';
import useNameOrAddress from '../../hooks/useNameOrAddress';
import { useSelector, useDispatch } from 'react-redux'
import { setUser } from '../../features/user/userSlice';

import NftItem from '../nft-item/NftItem';

function AcceptOfferModal(props) {
    const { t } = useTranslation();

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter" className='text-center'>
                    {t('user.labels.accept')}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <h4 className='title'>Item</h4>
                </Row>

                <Row className='align-items-center'>
                    <Col xs={3} md={2}>
                        <Image src={qrInter} thumbnail alt="" />
                    </Col>
                    <Col xs={7} md={8}>
                        <p className='text-right m-1'>
                            <img src={person} className='avatar' alt="" />
                            <span className='name'>Cecilia</span>
                        </p>
                        <span className='subtitle'>Name Item</span>
                    </Col>
                    <Col xs={2} md={2}>
                        <div className="d-flex align-items-center">
                            <img src={eth} width='10px' alt="" />
                            <span>0.023</span>
                        </div>
                        <div className='cont-dolar'>
                            <span className='dolar'>$9.23</span>
                        </div>
                    </Col>
                </Row>

                <Row className='mt-4'>
                    <h4 className='title'>{t('user.labels.provider')}</h4>
                </Row>

                <Row className='align-items-center'>
                    <Col xs={3} md={2}>
                        <Image src={avatar} width='100%' roundedCircle alt="" />
                    </Col>
                    <Col xs={9} md={10}>
                        <span className='subtitle'>Name/Address</span>
                    </Col>
                </Row>

                <Row className='mt-4'>
                    <h4 className='title'>{t('user.labels.rate')}</h4>
                </Row>

                <Row className='mt-3'>
                    <Col>
                        <p className='subtitle'>BORDES tarifa</p>
                    </Col>
                    <Col className='align-right'>
                        <p>15%</p>
                    </Col>
                </Row>

                <hr className='line mt-0' />

                <Row className='align-items-center'>
                    <Col xs={9} md={10}>
                        <span className='subtitle'>{t('user.labels.earnings')}</span>
                    </Col>
                    <Col xs={3} md={2} className='align-right'>
                        <div className="d-flex align-items-center">
                            <img src={eth} width='10px' alt="" />
                            <span>0.023</span>
                        </div>
                        <div className='cont-dolar'>
                            <span className='dolar'>$9.23</span>
                        </div>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Row>
                    <Col xs={6} md={6}>
                        <Button onClick={props.onHide} variant='outline-danger' className='mt-3'>{t('user.buttons.reject')}</Button>
                    </Col>
                    <Col xs={6} md={6}>
                        <Button onClick={props.onHide} variant='outline-dark' className='mt-3'>{t('buttons.accept')}</Button>
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    );
}

const UserNameModal = (props) => {

    const { settings } = AppSettings();
    const [loading, setLoading] = useState(true);
    const { global, addGlobal, addGlobals } = useContext(AppGlobal);
    const user = useSelector((state) => state.user.value)
    const dispatch = useDispatch()
    const { t } = useTranslation();
    const pageService = new PageService({
        appGlobal: {
            global: global,
            addGlobal: addGlobal
        }
    });

    const { register, handleSubmit } = useForm({ shouldUseNativeValidation: true });

    const handleSaveSubmit = async data => {

        pageService.showLoading();
        pageService.httpPut({
            endPoint: settings.api.endPoints.users + '/' + user.id,
            values: data
        }).then(resu => {
            // setUserPicture(settings.images.url + '/' + result.data.file);
            let params = {};
            params[settings.storage.user] = resu.data;
            addGlobals(params);
            dispatch(setUser(resu.data));

            pageService.showSuccess(t('user.labels.dataUpdate'));
            props.onHide()
        })
            .catch(error => {
                pageService.showError(error);
            })
            .finally(() => {
                pageService.hideLoading();
            });

    }


    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter" className='text-center'>
                    {t('user.labels.name')}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Form className='mt-5'>
                        <Row>
                            <Col xs={12} md={6}>
                                <Form.Group className="mb-12">
                                    <Form.Label className='text-danger'>{t('user.labels.names')}</Form.Label>
                                    <Form.Control className='control' type="text" placeholder={t('user.labels.namesPlace')} {...register("firstName", { required: "Por favor ingrese sus nombres." })} />
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={6}>
                                <Form.Group className="mb-12">
                                    <Form.Label className='text-danger'>{t('user.labels.lastNames')}</Form.Label>
                                    <Form.Control className='control' type="text" placeholder={t('user.labels.lastNamesPlace')} {...register("lastName", { required: "Por favor ingrese sus apellidos." })} />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>

                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Row>
                    <Col xs={6} md={6}>
                        <Button onClick={props.onHide} variant='outline-danger' className='mt-3'>{t('user.buttons.close')}</Button>
                    </Col>
                    <Col xs={6} md={6}>
                        <Button variant='outline-dark' className='mt-3' onClick={handleSubmit(handleSaveSubmit)}>{t('buttons.save')}</Button>
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    );
}

const User = () => {

    const { global, addGlobal } = useContext(AppGlobal);
    const user = useSelector((state) => state.user.value)
    const dispatch = useDispatch()
    const pageService = new PageService({
        appGlobal: {
            global: global,
            addGlobal: addGlobal
        }
    });
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { settings } = AppSettings();
    const [loading, setLoading] = useState(true);
    const { active, account } = useWeb3React();

    // page's variables
    const [summary, setSummary] = useState();
    const [collection, setCollection] = useState();
    const [userPicture, setUserPicture] = useState(avatar);
    const [userName, setUserName] = useState('');
    const [modalShow, setModalShow] = useState(false);
    const [userNameModalShow, setUserNameModalShow] = useState(false);

    // const userName = useNameOrAddress(global.user);

    const handleUserName = () => {
        setUserNameModalShow(true);
    }

    const handleUserPicture = (e) => {

        const file = e.target.files[0];
        if (!file) return;

        pageService.showLoading();
        pageService.httpPostFormData({
            endPoint: pageService.settings.api.endPoints.filesUpload,
            formData: {
                file: file
            }
        }).then(result => {
            if (result.data.file) {

                pageService.httpPut({
                    endPoint: settings.api.endPoints.users + '/' + user.id,
                    values: {
                        picture: result.data.file
                    }
                }).then(resu => {
                    // setUserPicture(settings.images.url + '/' + result.data.file);
                    addGlobal(settings.storage.user, resu.data);
                    dispatch(setUser(resu.data));
                    pageService.showSuccess(t('user.labels.updateImage'));
                })
                    .catch(error => {
                        pageService.showError(error);
                    })
                    .finally(() => pageService.hideLoading());

            } else {
                pageService.hideLoading();
            }

        })
            .catch(error => {
                pageService.hideLoading();
                pageService.showError(error);
            });

    }

    useEffect(() => {
        if (!active) navigate('/');
    }, [active]);

    // useEffect(() => {
    //     console.log("global.user:");
    //     console.log(global.user);
    //     setLoading(true);
    //     if(global.user) {
    //         initialize();
    //     }
    // }, [global.user]);

    useEffect(() => {
        console.log("user:");
        console.log(user);
        setLoading(true);
        if (user) {
            initialize();
        }
    }, [user]);

    const initialize = () => {

        if (user.picture)
            setUserPicture(settings.images.url + '/' + user.picture);

        if (user.firstName) {
            setUserName(user.firstName + ' ' + user.lastName);
        } else {
            if (user.address) {
                const address = user.address;
                setUserName(address.substr(0, 6) + '...' + address.substr(-4));
            } else {
                setUserName("0x...TEST");
            }
        }


        pageService.httpGet({
            endPoint: settings.api.endPoints.usersSummary,
            queryParams: {
                id: user.id
            }
        }).then(result => {
            setSummary(result.data);

            if (result.data.collections && result.data.collections.length > 0) {
                loadCollection(result.data.collections[0]);
            } else {
                setLoading(false);
            }
        })
            .catch(error => {
                pageService.showError(error);
            });

    }

    // const initialize = () => {

    //     if(global.user.picture)
    //         setUserPicture(settings.images.url + '/' + global.user.picture);

    //     pageService.httpGet({
    //         endPoint: settings.api.endPoints.usersSummary,
    //         queryParams: {
    //             id: global.user.id
    //         }
    //     }).then( result => {
    //         setSummary(result.data);

    //         if(result.data.collections && result.data.collections.length > 0) {
    //             loadCollection(result.data.collections[0]);
    //         } else {
    //             setLoading(false);
    //         }
    //     })
    //     .catch( error => {
    //         pageService.showError(error);
    //     });

    // }

    const loadCollection = (collection) => {

        const endPoint = collection ?
                         settings.api.endPoints.collectionsWithNfts :
                         settings.api.endPoints.nftsByRole;

        const queryPamars = collection ?
                            { id: collection.id } :
                            { role: 'owner' };

        // const collectionMyNFTS = { name: 'myNFTs', nfts : [] };

        pageService.httpGet({
            endPoint,
            queryParams: queryPamars
        })
        .then(result => {
            setCollection( collection ? result.data : { name: 'myNFTs', nfts : result.data } );
        })
        .catch(error => {
            pageService.showError(error);
        })
        .finally(() => setLoading(false));

    }

    const goToCollectionItem = () => {
        navigate('/collection-item');
    }

    const goToNft = (collection, nft) => {
      console.log(collection)
        let id = 'new';
        if (nft) id = nft.id;

        let nftType = 'nft-double';
        if (collection.type == settings.collectionTypes.doubleIntervention) nftType = 'nft-double-intervention';
        if (collection.type == settings.collectionTypes.classic) nftType = 'nft-classic';

        if (id != 'new' && nftType == 'nft-double-intervention') {
            pageService.showSuccess(t('user.labels.edited'));
            return;
        }

        navigate(`/${nftType}/${collection.id}/${id}`);
    }



    return (
        <Container className='pb-5'>
            {/* LOADER */}
            {loading &&
                <Loader />
            }
            {/* LOADER */}

            {!loading &&
                <>
                    <div className='toolbar'>
                        <div className='img-profile'>
                            <img src={userPicture} className='img-avatar' alt="" />
                            <input type="file" className='fileInput' onChange={handleUserPicture} />
                        </div>
                        <div className='btn-image-profile'>
                            <Button variant='clear'>
                                <input type="file" className='fileInput' onChange={handleUserPicture} />
                                <GoDeviceCamera className='camera-profile' color='gray' />
                            </Button>
                        </div>
                    </div>

                    <Row className='justify-content-center align-items-center mt-5'>
                        <h1 className='text-center title mt-3'>{userName}
                            <Button variant='clear' onClick={handleUserName}>
                                <GoPencil className='size-pen' color='gray' />
                            </Button>
                        </h1>

                    </Row>

                    <Row className='align-items-center mt-4'>
                        <Col xs={12} md={6}>
                            <div className='text-end'>
                                <h3 className='m-0 text text-grey'>0</h3>
                            </div>
                        </Col>
                        <Col xs={12} md={6}>
                            <div className='text-start'>
                                <Button className='collection custom-m  btn btn-primary'>{t('user.labels.withdrawals')}</Button>
                            </div>
                        </Col>
                    </Row>

                    <Nav fill variant="tabs" className='mt-5'>

                        <NavDropdown title={t('user.labels.collections')} id="nav-dropdown">
                            {summary?.collections.map((collection, idx) => (
                                <NavDropdown.Item onClick={() => loadCollection(collection)}>{collection.name}</NavDropdown.Item>
                            ))}
                            <NavDropdown.Item className='text-danger' onClick={goToCollectionItem}>{t('user.labels.createCollection')}</NavDropdown.Item>
                        </NavDropdown>

                        <Nav.Item>
                            <Nav.Link className='sells'  onClick={() => loadCollection()}>
                                {t('user.labels.myNft')}
                            </Nav.Link>
                        </Nav.Item>

                        <Nav.Item>
                            <Nav.Link eventKey="1" className='sells'>
                                {t('user.labels.sales')}
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>

                    <Row className='mt-4 align-items-center'>
                        <Col xs={6} md={8}>
                            <h4 className='subtitle'>{collection?.name}</h4>
                        </Col>
                        <Col xs={6} md={4}>
                            {summary?.collections.length > 0 &&
                                <Button variant='outline-danger' className='w-100 mt-2' type='submit' onClick={() => goToNft(collection)}>
                                    {t('buttons.create')}
                                    <IoMdAdd />
                                </Button>
                            }
                        </Col>
                    </Row>

                    <Row className="justify-content-center align-items-center mt-5">

                        {collection?.nfts.map((nft, idx, nfts) => (
                            <Col xs={12} md={4}>
                                <NftItem nft={nft}></NftItem>
                            </Col>
                        ))}
                        {collection?.nfts.length % 3 > 1 &&
                            <Col xs={12} md={4}>
                            </Col>
                        }
                        {collection?.nfts.length % 3 > 0 &&
                            <>
                                <Col xs={12} md={4}>
                                </Col>
                                <Col xs={12} md={4}>
                                </Col>
                            </>
                        }

                    </Row>

                    {/* {collection?.nfts.map((nft, idx, nfts) => (
                        (idx % 2 == 0) ? (
                            (idx + 1 == collection?.nfts.length) ? (
                                <>
                                    <Row className="justify-content-center align-items-center mt-5">
                                        <Col xs={12} md={4}>
                                            <NftItem nft={nfts[idx]} onPress={() => goToNft(collection, nfts[idx])}></NftItem>
                                        </Col>
                                        <Col xs={12} md={2}></Col>
                                        <Col xs={12} md={4}>
                                        </Col>
                                    </Row>
                                </>
                            ) : (
                                <>
                                    <Row className="justify-content-center align-items-center mt-5">
                                        <Col xs={12} md={4}>
                                            <NftItem nft={nfts[idx]} onPress={() => goToNft(collection, nfts[idx])}></NftItem>
                                        </Col>
                                        <Col xs={12} md={2}></Col>
                                        <Col xs={12} md={4}>
                                            <NftItem nft={nfts[idx + 1]} onPress={() => goToNft(collection, nfts[idx + 1])}></NftItem>
                                        </Col>
                                    </Row>
                                </>
                            )

                        ) : (
                            <>
                            </>
                        )
                    ))} */}

                    <UserNameModal
                        show={userNameModalShow}
                        onHide={() => setUserNameModalShow(false)}
                    />



                </>
            }

        </Container>
    );



}

export default User;
import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import Loader from './Loader';
import './CollectionItem.css';
import BaseComponent from '../../core/BaseComponent';
import { Navigate } from "react-router-dom";
import { withNavigation, withI18n, withAppGlobal } from '../../core/BaseComponent';
import { useTranslation } from 'react-i18next';

class CollectionItem extends BaseComponent {

    constructor(props) {
        super(props);
        this.form = React.createRef(); 
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleBack = this.handleBack.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0)
    
    }

    handleBack() {
        this.goBack();
    }

    handleSubmit(event) {
		event.preventDefault();
		const formData = new FormData(this.form.current);
		const data = {
			code: formData.get('code'),
            description_en: formData.get('description_en'),
            description_es: formData.get('description_es'),
			name: formData.get('name'),
			type: formData.get('type'),
            user: this.context.global.user.id
		}

        this.pageService.showLoading();
        this.pageService.httpPost({
            endPoint: this.settings.api.endPoints.collections,
            values: data
        }).then( result => {
            // TODO: descomentar
            //this.form.current.reset();
            this.pageService.showSuccess("Coleccion creada exitosamente");
        })
        .catch( error => {
            this.pageService.showError(error);
        })
        .finally(() => this.pageService.hideLoading());
    }

    render() {

        return (
            <>

                {!this.context.global.user && (
                    <Navigate to="/" replace={true} />
                )}
                <Container className='pb-5'>
                    {/* LOADER */}
                    {this.state?.loading &&
                        <Loader />}
                    {/* LOADER */}

                    {!this.state?.loading &&
                        <>
                            <Row className='mt-5'>
                                <h1 className='title'>{this.t('collection_item.labels.title')}</h1>
                            </Row>

                            <Form className='mt-4' ref={this.form}>
                                <Form.Group className="mb-3">
                                    <Form.Label className='text-danger'>{this.t('collection_item.labels.optionTitle')}</Form.Label>
                                    <Form.Select className='control' name="type">
                                        <option value="none">{this.t('collection_item.labels.optionTitle')}</option>
                                        <option value="classic">{this.t('collection_item.options.one')}</option>
                                        <option value="double">{this.t('collection_item.options.two')}</option>
                                        <option value="classicIntervention">{this.t('collection_item.options.three')}</option>
                                        <option value="doubleIntervention">{this.t('collection_item.options.four')}</option>
                                    </Form.Select>
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label className='text-danger'>{this.t('collection_item.labels.nameCollection')}</Form.Label>
                                    <Form.Control className='control' type="text" placeholder={this.t('collection_item.labels.nameCollection')} name="name" />
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label className='text-danger'>{this.t('collection_name.labels.code')}</Form.Label>
                                    <Form.Control className='control' type="text" placeholder={this.t('collection_item.labels.code')} name="code" />
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label className='text-danger'>{this.t('collection_item.labels.descriptionEs')}</Form.Label>
                                    <Form.Control className='control' as="textarea" rows={3} placeholder={this.t('collection_item.labels.writeHere')} name="description_es" />
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label className='text-danger'>{this.t('collection_item.labels.descriptionEn')}</Form.Label>
                                    <Form.Control className='control' as="textarea" rows={3} placeholder={this.t('collection_item.labels.writeHere')} name="description_en" />
                                </Form.Group>

                                <Row className='justify-content-end mt-5'>
                                    <Col xs={12} md={6}></Col>
                                    <Col xs={12} md={3}>
                                        <Button onClick={this.handleBack} variant='outline' className='d-block w-100 create mt-2 buttons' type='button'>{this.t('buttons.back')}</Button>
                                    </Col>
                                    <Col xs={12} md={3}>
                                        <Button onClick={this.handleSubmit} variant='danger' className='d-block w-100 mt-2 buttons' type='submit'>{this.t('buttons.save')}</Button>
                                    </Col>
                                </Row>
                            </Form>
                        </>}
                </Container>
            </>
        );


    }


}

export default withAppGlobal(withI18n(withNavigation(CollectionItem)));
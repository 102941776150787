import 'bootstrap/dist/css/bootstrap.min.css';
import './Dematerializations';
import { Row, Col, Placeholder } from 'react-bootstrap';

const Loader = () => {
    return (
        <>
            <Row className='mt-5'>
                <Placeholder as="p" animation="glow">
                    <Placeholder xs={6} size='lg' />
                </Placeholder>

                <Placeholder as="p" animation="glow">
                    <Placeholder xs={12} size='md' />
                </Placeholder>
            </Row>
            
            <Row className='mt-5'>
                <Col xs={12} md={6}>
                    <Placeholder animation="glow">
                        <Placeholder className='img-placeholder' xs={8} size="lg" />
                    </Placeholder>

                    <Placeholder as="p" animation="glow" className='header-placeholder info-shop mt-2'>
                        <Placeholder xs={3} size='md' />
                        <Placeholder xs={3} size='md' />
                    </Placeholder>

                    <Placeholder as="p" animation="glow" className='header-placeholder info-shop'>
                        <Placeholder xs={3} size='md' />
                        <Placeholder xs={3} size='md' />
                    </Placeholder>

                    <Placeholder as="p" animation="glow">
                        <Placeholder xs={2} size="xs" />
                    </Placeholder>
                </Col>
                <Col xs={12} md={6}>
                    <Placeholder animation="glow">
                        <Placeholder className='img-placeholder' xs={8} size="lg" />
                    </Placeholder>

                    <Placeholder as="p" animation="glow" className='header-placeholder info-shop mt-2'>
                        <Placeholder xs={3} size='md' />
                        <Placeholder xs={3} size='md' />
                    </Placeholder>

                    <Placeholder as="p" animation="glow" className='header-placeholder info-shop'>
                        <Placeholder xs={3} size='md' />
                        <Placeholder xs={3} size='md' />
                    </Placeholder>

                    <Placeholder as="p" animation="glow">
                        <Placeholder xs={2} size="xs" />
                    </Placeholder>
                </Col>
            </Row>

            <Row className='mt-5'>
                <Col xs={12} md={6}>
                    <Placeholder animation="glow">
                        <Placeholder className='img-placeholder' xs={8} size="lg" />
                    </Placeholder>

                    <Placeholder as="p" animation="glow" className='header-placeholder info-shop mt-2'>
                        <Placeholder xs={3} size='md' />
                        <Placeholder xs={3} size='md' />
                    </Placeholder>

                    <Placeholder as="p" animation="glow" className='header-placeholder info-shop'>
                        <Placeholder xs={3} size='md' />
                        <Placeholder xs={3} size='md' />
                    </Placeholder>

                    <Placeholder as="p" animation="glow">
                        <Placeholder xs={2} size="xs" />
                    </Placeholder>
                </Col>
                <Col xs={12} md={6}>
                    <Placeholder animation="glow">
                        <Placeholder className='img-placeholder' xs={8} size="lg" />
                    </Placeholder>

                    <Placeholder as="p" animation="glow" className='header-placeholder info-shop mt-2'>
                        <Placeholder xs={3} size='md' />
                        <Placeholder xs={3} size='md' />
                    </Placeholder>

                    <Placeholder as="p" animation="glow" className='header-placeholder info-shop'>
                        <Placeholder xs={3} size='md' />
                        <Placeholder xs={3} size='md' />
                    </Placeholder>

                    <Placeholder as="p" animation="glow">
                        <Placeholder xs={2} size="xs" />
                    </Placeholder>
                </Col>
            </Row>
        </>
    );
}

export default Loader;
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Button, Carousel } from 'react-bootstrap';
import { FiChevronRight } from 'react-icons/fi';
import './Home.css';
import Loader from './Loader';
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCards } from "swiper";
import { HashLink as Link } from 'react-router-hash-link';

// + IMAGES +
import desma from '../../assets/imgs/desma.png';
import ceci1 from '../../assets/imgs/ceci1.png';
import ceci2 from '../../assets/imgs/ceci2.jpg';
import ceci3 from '../../assets/imgs/ceci3.jpg';
import adriana1 from '../../assets/imgs/adriana1.jpg';
import adriana2 from '../../assets/imgs/adriana2.jpg';
import adriana3 from '../../assets/imgs/adriana3.jpg';


// import home2 from '../../assets/imgs/home2.jpg';
// import home3 from '../../assets/imgs/home3.png';
// import home33 from '../../assets/imgs/home33.png';
// import home4 from '../../assets/imgs/home4.jpg';
// import home5 from '../../assets/imgs/home5.jpg';



import qr from '../../assets/imgs/qr.png';
import qrInter from '../../assets/imgs/qr-inter.png';
import qrInter2 from '../../assets/imgs/qr-inter2.png';
import eth from '../../assets/imgs/eth.png';
import avatar from '../../assets/imgs/avatar.png';
import person from '../../assets/imgs/person.jpeg';
import toolbar from '../../assets/imgs/qr-mb.png';
import imgMb from '../../assets/imgs/img-scan.png';
// - IMAGES -


// (+) Common
import AppSettings from '../../AppSettings';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import PageService from '../../core/PageService';
import AppGlobal from '../../context/AppGlobal';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
// (-) Common

import HomeCollection from '../home-collection/HomeCollection';

const Home = () => {

    // (+) Common
    const { global, addGlobal } = useContext(AppGlobal);
    const user = useSelector((state) => state.user.value)
    const dispatch = useDispatch()
    const pageService = new PageService({
        appGlobal: {
            global: global,
            addGlobal: addGlobal
        }
    });
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { settings } = AppSettings();
    const [loading, setLoading] = useState(true);
    const [initialized, setInitialized] = useState(false);
    // (-) Common

    const [collections, setCollections] = useState();

    // useEffect(() => {
    //     window.scrollTo(0, 0)
    // }, []);

    const [item, setItem] = useState(true);


    const initialize = () => {

        pageService.httpGet({
            endPoint: settings.api.endPoints.usersHome
        }).then(result => {
            setCollections(result.data.collections);
        })
        .catch(error => pageService.showError(error))
        .finally(() => pageService.hideLoading());

    }

    // setTimeout(() => {
    //     setItem(true);
    // }, 100);

    useEffect(() => {
        if(!initialized) {
            initialize();
            setInitialized(true);
        }
    });



    const goToCollections = () => {
        navigate('#collections');
    }

    const goToDematerialization = () => {
        navigate('/dematerializations');
    }

    const goToInterventions = () => {
        navigate('/collection/interventions');
    }

    const goToNftShop = () => {
        navigate('/nft-shop/62d47928207aea289fe6cc76');
    }

    const goToNftIntervention = () => {
        navigate('/nft-intervention');
    }

    return (
        <>
            {/* LOADER */}
            {!item &&
                <Loader />
            }
            {/* LOADER */}


            {/* INFO */}
            {item &&
                <>
                    <Container className='home-cont'>
                        <Row className="justify-content-center align-items-center mt-5">
                            <Col xs={12} md={6} className='mt-3'>
                                <h1 className='title'>BORDES</h1>
                                <p className='mt-3 text'>{t('home.labels.bordesShort')}</p>
                                <Row className="align-items-center">
                                    <Col xs={12} md={6} className='mt-4'>
                                        <Button className='btn-home collection custom-m' onClick={goToInterventions}>{t('home.buttons.intervene')}</Button>
                                    </Col>
                                    <Col xs={12} md={6} className='mt-4'>
                                        <Link to="#collections" className='nav-link-no-decoration' smooth><Button className='btn-home create custom-m' variant='outline'>{t('home.buttons.viewCollections')}</Button></Link>
                                    </Col>
                                </Row>

                                <p className='mt-4 link'>{t('home.labels.findOut')} <FiChevronRight /></p>
                            </Col>
                            <Col xs={12} md={2}></Col>
                            <Col xs={12} md={4} className='mt-3'>
                                <Carousel fade controls={false} interval='1500'>
                                    <Carousel.Item>
                                        <img src={ceci1} className="d-block w-100 slider-img-home" alt="" />
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <img src={adriana3} className="d-block w-100 slider-img-home" alt="" />
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <img src={ceci2} className="d-block w-100 slider-img-home" alt="" />
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <img src={adriana2} className="d-block w-100 slider-img-home" alt="" />
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <img src={ceci3} className="d-block w-100 slider-img-home" alt="" />
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <img src={adriana1} className="d-block w-100 slider-img-home" alt="" />
                                    </Carousel.Item>
                                </Carousel>
                            </Col>
                        </Row>
                    </Container>

                    <div class='toolbar-inter mt-4 p-4'>
                        <Container>
                            <Row className='align-items-center justify-content-center'>
                                <Col xs={12} md={3} className='text-center'>
                                    <Carousel fade controls={false}>
                                        <Carousel.Item>
                                            <img src={toolbar} className="text-center" alt="" />
                                        </Carousel.Item>
                                        <Carousel.Item>
                                            <img src={imgMb} className="text-center" alt="" />
                                        </Carousel.Item>
                                    </Carousel>
                                </Col>
                                <Col xs={12} md={9}>
                                    <h3 class='title text-light mt-3'>{t('home.labels.dematerialization.title')}</h3>
                                    {/* <p className='text text-light'>{t('home.labels.dematerialization.titleExplained')}</p> */}
                                    <br></br>
                                    <h4 class='title text-light mt-3'>{t('home.labels.dematerialization.subtitle')}</h4>
                                    <br></br>
                                    <br></br>
                                    <h5 className='text text-light'>{t('home.labels.dematerialization.subtitleExplained')}</h5>
                                </Col>
                            </Row>
                        </Container>
                    </div>


                    <Container className='home-cont pb-5' id="collections">
                        {collections?.map((collection, idx, collections) => (
                            (idx + 1 == collections.length) ? (
                                <HomeCollection collection={collection} ></HomeCollection>
                            ):(
                                <>
                                    <HomeCollection collection={collection}></HomeCollection>
                                    <hr className='separetor mt-5' />
                                </>
                            )
                        ))}
                    </Container>

                </>
            }
        </>
    );
}

export default Home;
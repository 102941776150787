import 'bootstrap/dist/css/bootstrap.min.css';
import './Spinner.css';

import Spinner from 'react-bootstrap/Spinner';
import AppGlobal from '../../context/AppGlobal';
import { useContext } from 'react';

// + IMAGES +
import spinner from '../../assets/imgs/spinner.gif';
// + IMAGES +

const SpinnerLoader = () => {

    const { global, addGlobal, removeGlobal, addGlobals } = useContext(AppGlobal);
    const show = false;

    return (
        <>
        {global.loading &&
            <div className='loader-container'>
            <div className='loader'>
                <Spinner animation="border" variant="danger" />
            </div>
        </div>
        }
        </>
    );
}

export default SpinnerLoader;
import axios from 'axios';
import AppGlobal from "../context/AppGlobal";
import AppSettings from "../AppSettings";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import React from 'react';

export default class PageService {

  static contextType = AppGlobal;

  constructor(props) {
    this.appSettings = AppSettings();
    this.settings = this.appSettings.settings;
    if (props) {
      this.props = props;
      this.global = this.props.appGlobal.global;
      this.addGlobal = this.props.appGlobal.addGlobal;
    }
  }

  // (+) Loadings

  showLoading() {
    console.log("TODO show loading");
    this.addGlobal(this.settings.storage.loading, true);
  }

  hideLoading() {
    console.log("TODO hide loading");
    this.addGlobal(this.settings.storage.loading, false);
  }

  // (-) Loadings


  // (+) Show messages

  showSuccess(message) {
    this.showMessage(message, "error");
  }

  showError(error) {
    this.showMessage(error.message || error, "success");
  }

  showWarning(message) {
    this.showMessage(message, "warning");
  }

  showMessage(message, type) {
    let options = window.innerWidth > 1200 ? {
      position: "top-right",
      autoClose: 5000,
      icon: false,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined
    } : {
      position: "bottom-center",
      autoClose: 5000,
      icon: false,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined
    };
    toast[type](message, options);
  }

  // (-) Show messages


  // (+) Http request

  handleError(error, resolve, reject) {
    let message = error.message;
    if (error.response && error.response.status == 401)
      message = "No autorizado";
    else if (error.response && error.response.data && error.response.data.message)
      message = error.response.data.message;
    reject({ message, error });
  }

  httpGet(params = {}) {
    return new Promise((resolve, reject) => {

      const endPoint = this.appSettings.getEndPointWithParams(params.endPoint, params.queryParams);

      axios.get(
        this.appSettings.settings.api.url + endPoint,
        this.getHeaders()
      )
        .then(result => {
          resolve(result.data);
        })
        .catch(error => {
          this.handleError(error, resolve, reject);
        });
    });
  }

  httpPut(params = {}) {
    return new Promise((resolve, reject) => {

      const endPoint = this.appSettings.getEndPointWithParams(params.endPoint, params.queryParams);

      axios.put(
        this.appSettings.settings.api.url + endPoint,
        params.values,
        this.getHeaders()
      )
        .then(result => {
          resolve(result.data);
        })
        .catch(error => {
          this.handleError(error, resolve, reject);
        });
    });
  }

  httpPost(params = {}) {
    return new Promise((resolve, reject) => {

      const endPoint = this.appSettings.getEndPointWithParams(params.endPoint, params.queryParams);

      axios.post(
        this.appSettings.settings.api.url + endPoint,
        params.values,
        this.getHeaders()
      )
        .then(result => {
          resolve(result.data);
        })
        .catch(error => {
          this.handleError(error, resolve, reject);
        });
    });
  }

  httpPostFormData(params = {}) {
    return new Promise((resolve, reject) => {

      const endPoint = this.appSettings.getEndPointWithParams(params.endPoint, params.queryParams);

      const formData = new FormData();

      if (params.formData) {
        for (let key in params.formData) {
          formData.append(key, params.formData[key]);
        }
      }


      axios.post(
        this.appSettings.settings.api.url + endPoint,
        formData,
        this.getHeaders()
      )
        .then(result => {
          resolve(result.data);
        })
        .catch(error => {
          this.handleError(error, resolve, reject);
        });

    });
  }

  getHeaders() {
    let headers = {
      'Content-Type': 'application/json'
    };
    if (this.global.user) headers['x-access-token'] = this.global.user.token;
    return { headers };
  }

  // (-) Http request


}
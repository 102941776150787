import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import './NftItem.css';
import BaseComponent from '../../core/BaseComponent';
import { Navigate } from "react-router-dom";
import { withNavigation, withI18n, withAppGlobal } from '../../core/BaseComponent';

import qrInter from '../../assets/imgs/qr-inter.png';
import eth from '../../assets/imgs/eth.png';
import avatar from '../../assets/imgs/avatar.png';
import person from '../../assets/imgs/person.jpeg';
import desma from '../../assets/imgs/desma.png';
import qrBorder from '../../assets/imgs/qr-border.png';
import { IoMdAdd } from 'react-icons/io';

class NftItem extends BaseComponent {

    constructor(props) {
        super(props);
        this.getUserName = this.getUserName.bind(this);
        this.getUserPicture = this.getUserPicture.bind(this);
        this.getPrice = this.getPrice.bind(this);
        this.checkCanSell = this.checkCanSell.bind(this);
        this.sell = this.sell.bind(this);
    }

    getUserName(userKey) {
        if (this.props.nft[userKey].firstName) {
            return this.props.nft[userKey].firstName + ' ' + this.props.nft[userKey].lastName;
        } else {
            if (this.props.nft[userKey].address) {
                const address = this.props.nft[userKey].address;
                return address.substr(0, 6) + '...' + address.substr(-4);
            } else {
                return "0x4A4C3b4...TEST";
            }
        }
    }

    getUserPicture(userKey) {
        if (this.props.nft[userKey].picture)
            return this.settings.images.url + '/' + this.props.nft[userKey].picture;
        else
            return avatar;
    }

    getPrice() {
        return this.props.nft.price / 1000;
    }

    checkCanSell() {
        let canSell = false;
        if(
            this.props.nft && 
            this.props.nft.status == this.settings.nfts.status.minted && 
            this.props.nft.owner.id ==  this.context.global.user.id
        ) canSell = true;
        this.setState({
            sell: canSell
        });
    }

    componentDidMount() {
        this.checkCanSell();
    }

    sell() {
        this.pageService.httpPut({
            endPoint: this.settings.api.endPoints.nfts + '/' + this.props.nft.id,
            values: {
                status: this.settings.nfts.status.marketplace
            }
        }).then( result => {
            this.setState({
                sell: false
            });
            this.pageService.showSuccess("NFT en venta");
        })
        .catch( error => {
            this.pageService.showError(error);
        });
    }

    render() {

        const { onPress } = this.props;

        return (
            <div className='card mt-3'>
                <img src={this.settings.images.url + '/sm/' + this.props.nft.file} className='d-block w-100 shop-img m-auto' height='300px' alt="" onClick={onPress} />
                <Row className='mt-3'>
                    <Col xs={12} md={12}>
                        <p className='m-1 bordes'>{this.props.nft.name}</p>
                    </Col>
                </Row>
                <Row className='mt-3'>
                    <Col xs={4} md={4}>
                        <p className='text-left m-1 price-shop'>{this.t('nftItem.labels.creator')}</p>
                    </Col>
                    <Col xs={8} md={8} className='align-right'>
                        <p className='text-right'>
                            <img src={this.getUserPicture('creator')} className='avatar' alt="" />
                            <span className='name'>{this.getUserName('creator')}</span>
                        </p>
                    </Col>
                </Row>
                <Row className='mt-3'>
                    <Col xs={4} md={4}>
                        <p className='text-left m-1 price-shop'>{this.t('nftItem.labels.owner')}</p>
                    </Col>
                    <Col xs={8} md={8} className='align-right'>
                        <p className='text-right'>
                            <img src={this.getUserPicture('owner')} className='avatar' alt="" />
                            <span className='name'>{this.getUserName('owner')}</span>
                        </p>
                    </Col>
                </Row>
                <Row className='mt-3'>
                    <Col xs={6} md={6}>
                        <p className='m-1 price-shop'>{this.t('nftItem.labels.price')}</p>
                    </Col>
                    <Col xs={6} md={6} className='align-right'>
                        <p className='text-right'>
                            <img src={eth} width='10px' alt="" />
                            <span className='price-eth'>{this.getPrice()}</span>
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col xs={6} md={6}></Col>
                    <Col xs={6} md={6} className="text-end">
                        {   this.state?.sell
                            &&
                            <Button variant='outline-danger' onClick={this.sell} className='btn btn-outline-danger'>{this.t('nftItem.buttons.sell')} <IoMdAdd /></Button>
                        }
                        
                    </Col>

                </Row>
            </div>
        );


    }


}

export default withAppGlobal(withI18n(withNavigation(NftItem)));